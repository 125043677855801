import { Card, Col } from "react-bootstrap";

import arrowUp from "../../../assets/images/arrow_drop_down_green.svg";
import arrowDown from "../../../assets/images/arrow_drop_down_red.svg";

import NoBoxSelect from "../../../components/inputFields/NoBoxSelect";
import { posSizeTokens, TradeChartTokens } from "../../../components/static/SupportedTokens";

const TokenPairActivity = ({ tradeConditions, setTradeConditions, tradingDetails }) => {

  return (
    <>
      <Col>
        <Card className="shadowed-box trade_head_detail">
          <Card.Body className="py-3 px-0">
            <div className="d-flex justify-content-start align-items-center gap-xxl-4 gap-xl-3">
              <div className="trade-main-wrapper">
                <NoBoxSelect
                  value={TradeChartTokens.find((token) => token.value === tradeConditions.selectedTo.value)}
                  onSelectChange={(val) =>
                    setTradeConditions({
                      ...tradeConditions,
                      selectedTo: posSizeTokens.find((token) => token.value === val.value),
                    })
                  }
                  optionValue={TradeChartTokens}
                />
              </div>
              <div
                className={`trade-card-value ${Object.keys(tradingDetails).length > 0 ?
                  tradingDetails.change > 0 ? "trade-change-value" : tradingDetails.change < 0 ? "trade-change-minus-value" : "" : ""
                } `}
              >{
                Object.keys(tradingDetails).length > 0
                  ? `$${parseFloat(Number(tradingDetails.price).toFixed(3)).toLocaleString()}`
                  : "-"
              }

              </div>
              <div className="trade-card-content">
                <p>24h Change</p>
                <span className={`${Object.keys(tradingDetails).length > 0 ? tradingDetails.change > 0
                  ? "trade-change-value"
                  : tradingDetails.change < 0
                    ? "trade-change-minus-value"
                    : "" : ""}`}>
                  {Object.keys(tradingDetails).length > 0
                    ? <>
                      ${(Math.abs(tradingDetails?.change) < 0.01 && Math.abs(tradingDetails?.change) > 0) ? "<0.01"
                      : parseFloat(Math.abs(tradingDetails?.change)?.toFixed(2))}
                      <span className="ms-2">
                   {tradingDetails.change !== 0 && <img src={tradingDetails.change > 0 ? arrowUp : arrowDown} />}
                  </span>
                      <span className="ms-1">
                      {(Math.abs(tradingDetails?.changePercentage) < 0.01 && Math.abs(tradingDetails?.changePercentage) > 0) ? "<0.01"
                        : parseFloat(Math.abs(tradingDetails?.changePercentage)?.toFixed(2))}%
                  </span>
                    </>
                    : "-"
                  }
                </span>
              </div>
              <div className="trade-card-content">
                <p>24h High</p>
                <span>
                  {Object.keys(tradingDetails).length > 0
                    ? `$${(Number(tradingDetails.high) < 0.001 && Number(tradingDetails.high) > 0) ? "<0.001"
                      : parseFloat(Number(tradingDetails.high).toFixed(3)).toLocaleString()}`
                    : "-"
                  }
                </span>
              </div>
              <div className="trade-card-content">
                <p>24h Low</p>
                <span>
                    {Object.keys(tradingDetails).length > 0
                      ? `$${(Number(tradingDetails.low) < 0.001 && Number(tradingDetails.low) > 0) ? "<0.001"
                        : parseFloat(Number(tradingDetails.low).toFixed(3)).toLocaleString()}`
                      : "-"
                    }
                </span>
              </div>
              <div className="trade-card-content">
                <p>24h Volume ({tradingDetails.token})</p>
                <span>
                    {Object.keys(tradingDetails).length > 0
                      ? `${(Number(tradingDetails.volume) < 0.001 && Number(tradingDetails.volume) > 0) ? "<0.001"
                        : parseFloat(Number(tradingDetails.volume).toFixed(3)).toLocaleString()}`
                      : "-"
                    }
                </span>
              </div>
              <div className="trade-card-content">
                <p>24h Volume (USD)</p>
                <span>
                   {Object.keys(tradingDetails).length > 0
                     ? `$${(tradingDetails.usdVolume < 1 && tradingDetails.usdVolume > 0) ? "<1" : Math.round(tradingDetails.usdVolume).toLocaleString()}`
                     : "-"
                   }
                 </span>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default TokenPairActivity;
