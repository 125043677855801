import twitter from "../assets/images/twitter.svg";
import discord from "../assets/images/discord.svg";
import telegram from "../assets/images/telegram.svg";

const Footer = () => {
  return (
    <>
      <div className="footer d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-start justify-content-between w-100">
          <div>
            <p className="footer_title">PERPETUAL LAMP</p>
            <p className="footer_desc">All Rights Reserved</p>
          </div>
          <div className="d-flex gap-4">
            <img src={twitter} alt="" className="footer_img" />
            <img src={discord} alt="" className="footer_img" />
            <img src={telegram} alt="" className="footer_img" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
