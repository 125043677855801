// TradingViewWidget.jsx

import React, { useEffect, useRef } from "react";

let tvScriptLoadingPromise;

const TradingViewChart = ({ tradeConditions }) => {
  const onLoadScriptRef = useRef();

  const data = {
    BTC: { symbol: "BTCUSD" },
    KAVA: { symbol: "KAVAUSD" },
    ETH: { symbol: "ETHUSD" },
  };

  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.style = { borderRadius: "20" };
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

    return () => (onLoadScriptRef.current = null);

    function createWidget() {
      if (document.getElementById("chart") && "TradingView" in window) {
        new window.TradingView.widget({
          autosize: true,
          symbol: `BINANCE:${data[tradeConditions.selectedTo.value].symbol}`,
          interval: "1",
          timezone: "Etc/UTC",
          theme: "dark",
          style: "0",
          locale: "en",
          withdateranges: false,
          enable_publishing: false,
          hide_side_toolbar: false,
          allow_symbol_change: true,
          save_image: false,
          container_id: "chart",
        });
      }
    }
  }, [tradeConditions.selectedTo]);

  return (
    <div className="shadowed-box trading-chart-wrappper">
      <div className="tradingview-widget-container" style={{ height: "100%" }}>
        <div id="chart" style={{ height: "100%" }} />
        <div className="tradingview-widget-copyright">
          <a
            href={`https://www.tradingview.com/symbols/${
              data[tradeConditions.selectedTo.value].symbol
            }/?exchange=BINANCE`}
            rel="noopener"
            target="_blank"
          />
        </div>
      </div>
    </div>
  );
};

export default TradingViewChart;