import Select, { components } from "react-select";
import DropdownArrowBlack from "../../assets/images/arrow-dropdown-black.svg";

const customStyles = {
  control: (base) => ({
    ...base,
    height: 24,
    minHeight: 24,
  }),
};

const WithTagSelect = ({
                         label,
                         isInput = true,
                         placeholder,
                         optionValue,
                         disabled,
                         onChange,
                         onSelectChange,
                         inputValue,
                         value,
                         className,
                         usdValue,
                         selectClassName,
                         readOnly,
                         hideSelectedOptions
                       }) => {
  return (
    <>
      {label && <label className="input_label">{label}</label>}
      <div className={`tag-select ${isInput ? "input-wrapper" : undefined} ${selectClassName} ${disabled ? "disabled" : undefined}`}>
        {isInput && (
          <div className="USD_wrapper">
            <input
              onKeyPress={(event) => {
                if (event.charCode < 48 && event.charCode !== 46) {
                  event.preventDefault();
                }
              }}
              type="number"
              placeholder={placeholder}
              onChange={onChange}
              value={(Number(inputValue) === 0 && readOnly) ? "" : inputValue}
              disabled={disabled}
              className={className}
              readOnly={readOnly}
              min="0"
            />
            {(usdValue > 0 || typeof usdValue === "string") && <p className="mb-0">~ ${usdValue}</p>}
          </div>

        )}
        <Select
          styles={customStyles}
          defaultValue={optionValue[0]}
          value={value}
          onChange={onSelectChange}
          options={optionValue}
          isClearable={false}
          hideSelectedOptions={hideSelectedOptions}
          isDisabled={disabled}
          classNamePrefix="select select-custom"
          components={{
            IndicatorSeparator: null,
            DropdownIndicator: (props) => (
              <components.DropdownIndicator {...props}>
                <img src={DropdownArrowBlack} alt="icon" className="icon" />
              </components.DropdownIndicator>
            ),
          }}
        />
      </div>
    </>
  );
};

export default WithTagSelect;
