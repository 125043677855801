// Will mostly have only UI component imports and placement in this file.

import { Col, Row } from "react-bootstrap";

const AllocationHistory = () => {
  return (
    <>
      <div className="trading-section">
        <p className="title">Allocation History</p>
        <div className="shadowed-box max_box  mt-4">
          <div className="trading-section">
            <Row xs={1} md={2}>
              <Col>
                <div className="d-flex allocation_details">
                  <div className="referrer_div">
                    <h6>Referrer</h6>
                    <p>
                      {" "}
                      <span>TIER 0</span>
                    </p>
                    <p>0 RP</p>
                    <p className="mb-0">0% Rebate ($0 per RP)</p>
                  </div>
                  <div className="trader_div">
                    <h6>Trader</h6>
                    <p>
                      Your Referral : <span>TIER 0</span>
                    </p>
                    <p>0 TP</p>
                    <p className="mb-0">0% Discount ($0 per TP)</p>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="allocation_details">
                  <div className="rewards_div">
                    <p className="d-flex align-items-center justify-content-between">
                      Your Reward <span>0 LAMP</span>
                    </p>
                    <p className="d-flex align-items-center justify-content-between">
                      Claimed Rewards <span>0 LAMP</span>
                    </p>
                    <p className="d-flex align-items-center justify-content-between">
                      Claimable Rewards <span>0 LAMP</span>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="shadowed-box max_box  mt-4">
          <div className="trading-section">
            <Row xs={1} md={2}>
              <Col>
                <div className="d-flex allocation_details">
                  <div className="referrer_div">
                    <h6>Referrer</h6>
                    <p>
                      {" "}
                      <span>TIER 0</span>
                    </p>
                    <p>0 RP</p>
                    <p className="mb-0">0% Rebate ($0 per RP)</p>
                  </div>
                  <div className="trader_div">
                    <h6>Trader</h6>
                    <p>
                      Your Referral : <span>TIER 0</span>
                    </p>
                    <p>0 TP</p>
                    <p className="mb-0">0% Discount ($0 per TP)</p>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="allocation_details">
                  <div className="rewards_div">
                    <p className="d-flex align-items-center justify-content-between">
                      Your Reward <span>0 LAMP</span>
                    </p>
                    <p className="d-flex align-items-center justify-content-between">
                      Claimed Rewards <span>0 LAMP</span>
                    </p>
                    <p className="d-flex align-items-center justify-content-between">
                      Claimable Rewards <span>0 LAMP</span>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="shadowed-box max_box  mt-4">
          <div className="trading-section">
            <Row xs={1} md={2}>
              <Col>
                <div className="d-flex allocation_details">
                  <div className="referrer_div">
                    <h6>Referrer</h6>
                    <p>
                      {" "}
                      <span>TIER 0</span>
                    </p>
                    <p>0 RP</p>
                    <p className="mb-0">0% Rebate ($0 per RP)</p>
                  </div>
                  <div className="trader_div">
                    <h6>Trader</h6>
                    <p>
                      Your Referral : <span>TIER 0</span>
                    </p>
                    <p>0 TP</p>
                    <p className="mb-0">0% Discount ($0 per TP)</p>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="allocation_details">
                  <div className="rewards_div">
                    <p className="d-flex align-items-center justify-content-between">
                      Your Reward <span>0 LAMP</span>
                    </p>
                    <p className="d-flex align-items-center justify-content-between">
                      Claimed Rewards <span>0 LAMP</span>
                    </p>
                    <p className="d-flex align-items-center justify-content-between">
                      Claimable Rewards <span>0 LAMP</span>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllocationHistory;
