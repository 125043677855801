import { gql } from "@apollo/client";
import axios from "axios";

export const GET_HISTORIES = gql`
  query histories($owner: Bytes!, $skip: Int!, $updateType: String!) {
    histories(skip: $skip,first:1000, where: { owner: $owner, updateType: $updateType }) {
      id
      createdAtTimestamp
      tx
      type
      status
      triggerPrice
      executionPrice
      amountIn
      amountOut
      tokenIn
      tokenOut
      minAmountOut
    }
    orders(skip: $skip,first:1000, where: { owner: $owner, updateType: $updateType }) {
      id
      tx
      type
      status
      price
      executionPrice
      amountIn
      amountOut
      tokenIn
      tokenOut
      minAmountOut
    }
  }
`;

export const GET_CHART_PRICE = gql`
  query takeQuery($start: Int!, $end: Int!, $period: Period!, $from: Bytes!, $to: Bytes!) {
    tokenFrom: priceStats(
      where: { token: $from, timestamp_gte: $start, timestamp_lt: $end, period: $period }
      orderBy: timestamp
      orderDirection: asc
      first: 1000
    ) {
      token
      value
      timestamp
    }
    tokenTo: priceStats(
      where: { token: $to, timestamp_gte: $start, timestamp_lt: $end, period: $period }
      orderBy: timestamp
      orderDirection: asc
      first: 1000
    ) {
      token
      value
      timestamp
    }
  }
`;

export const GET_TRADE_HISTORIES = gql`
  query histories($owner: Bytes!, $skip: Int!,$first:Int!) {
    histories(skip: $skip,first:$first, where: { owner: $owner }, orderBy: createdAtTimestamp, orderDirection: asc) {
      id
      createdAtTimestamp
      tx
      type
      status
      triggerPrice
      executionPrice
      collateralToken
      collateralValue
      side
      size
      updateType
      market {
        indexToken {
          id
        }
      }
    }
    orders(skip: $skip,first:$first, where: { owner: $owner }, orderBy: submissionTimestamp, orderDirection: desc) {
      id
      tx
      type
      status
      price
      executionPrice
      collateralToken
      collateralValue
      payToken
      side
      sizeChange
      submissionBlock
      submissionTimestamp
      updateType
      triggerAboveThreshold
      market {
        indexToken {
          id
        }
      }
    }
    positions(skip: $skip,first:$first, where: { owner: $owner }, orderBy: createdAtTimestamp, orderDirection: desc) {
      collateralToken
      collateralValue
      createdAtTimestamp
      entryInterestRate
      id
      entryPrice
      leverage
      owner
      realizedPnl
      reserveAmount
      side
      status
      size
      market {
        indexToken {
          id
        }
      }
    }
  }
`;

export const TRAILING_PROFIT = gql`
  query sevenDayTrailingProfit($tranche : Bytes!) {
    trancheStats(
      where: {tranche: $tranche}
      orderBy: timestamp
      orderDirection: desc
      first: 8
    ) {
      totalPnL
      totalFeeValue
    }
  }
`;

export const ACCRUED_FEE = gql`
  query accruedFees {
    tranches {
      totalFeeValue
    }
  }
`;

export const TRANCHE_PRICE = gql`
  query tranchePrice($id: Bytes!, $start: Int!, $end: Int!) {
    trancheStats(
      where: { tranche: $id, timestamp_gte: $start, timestamp_lte: $end, period: daily }
      orderBy: timestamp
      orderDirection: asc
    ) {
      llpPrice
      llpSupply
      timestamp
      trancheValue
    }
    tokenDistributionStats(
      where: { tranche: $id, timestamp_gte: $start, timestamp_lte: $end, period: daily }
      orderBy: timestamp
      orderDirection: asc
      first: 1000
    ) {
      timestamp
      token
      value
    }
  }
`;

export const GET_BLOCK = gql`
  query blocks {
    _meta {
      block {
        number
      }
    }
  }
`;

export const TRADE_VOLUME = gql`
  query stats($b24: Int!,$eth: Bytes!,$btc: Bytes!,$kava: Bytes!) {
    tradeBTC: tradingPairStats(
      where: { indexToken: $btc, period: total }
    ) {
      indexToken
      volume
      volumeUsd
      timestamp
    }
    tradeBTCyesterday: tradingPairStats(
      where: { indexToken: $btc, period: total }
      block: { number: $b24 }
    ) {
      indexToken
      volume
      volumeUsd
      timestamp
    }
    tradeKAVA: tradingPairStats(
      where: { indexToken:$kava, period: total }
    ) {
      indexToken
      volume
      volumeUsd
      timestamp
    }
    tradeKAVAyesterday: tradingPairStats(
      where: { indexToken: $kava, period: total }
      block: { number: $b24 }
    ) {
      indexToken
      volume
      volumeUsd
      timestamp
    }
    tradeETH: tradingPairStats(
      where: { indexToken: $eth, period: total }
    ) {
      indexToken
      volume
      volumeUsd
      timestamp
    }
    tradeETHyesterday: tradingPairStats(
      where: { indexToken: $eth, period: total }
      block: { number: $b24 }
    ) {
      indexToken
      volume
      volumeUsd
      timestamp
    }
  }
`;

export const TRADE_SWAP_VOLUME = gql`
  query tradeSwapVolume($period: Period!){
    volumeStats(where:{period: $period}){
      trading
      swap
      timestamp
      total
      period
    }
    tradingStats(where: {period: $period}) {
      id
      longOpenInterest
      shortOpenInterest
      period
      timestamp
    }
  }
`;

export const TRADE_SWAP_FEE_VOLUME = gql`
  query tradeSwapFeeVolume($start: Int!, $end: Int!){
    volumeStats(where:{timestamp_gte: $start, timestamp_lte: $end,  period: daily}){
      trading
      swap
      timestamp
      total
      period
      cumulative
    }
    feeStats(where:{timestamp_gte: $start, timestamp_lte: $end,  period: daily}) {
      total
      swap
      trading
      timestamp
      period
      cumulative
    }
  }
`;

export const leaderBoardData = async (sortBy, sortOrder, page) => {
  try {
    const { data } = await axios.get(
      `https://terminal-api.level.finance/v7/traders?sortBy=${sortBy}&sortType=${sortOrder}&size=10&page=${page}`,
    );
    return data.data;
  } catch (err) {
    // console.error("Backend-error: " + err);
    return false;
  }
};
