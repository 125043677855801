import Modal from "react-bootstrap/Modal";

import closeIcon from "../../../assets/images/modal-close-icon.svg";

import PlButton from "../../../components/buttons/Button";
import WithTag from "../../../components/inputFields/WithTag";
import { PercentageButtons } from "../../../components/buttons/Percentage";
import configs from "../../../config.json";
import { useMetaMask } from "metamask-react";
import { compareBigNums } from "../../../components/HandleDecimals";

const DepositWithdraw = ({
                           show,
                           onHide,
                           modalDetails,
                           modalAmount,
                           setModalAmount,
                           handleDeposit,
                           handleWithdraw,
                           handleApprove,
                         }) => {

  const { account, chainId } = useMetaMask();
  console.log("modalAmount-", modalAmount);
  return (
    <Modal size="lg" centered dialogClassName="claim-modal" show={show}>
      <Modal.Body>
        <div className="close-icon" onClick={onHide}>
          <img src={closeIcon} />
        </div>

        <div className="modal-title mb-3">
          <h3>{modalDetails.title} {modalDetails.label.toUpperCase()}</h3>
        </div>
        <div className="mt-4">
          <WithTag
            inputClass={`${Number(modalAmount) > Number(modalDetails.balance) && "loss"}`}
            label={<span>Amount</span>}
            placeholder={"0.0"}
            inputValue={modalAmount}
            onChange={(e) => setModalAmount(e.target.value)}
            tag={
              <div className="d-flex align-items-center justify-content-center gap-1">
                <span className="lamp_tag"></span> {modalDetails.label}
              </div>
            }
          />
          <div className="d-flex justify-content-between align-items-center mt-2">
            <span className="balance">
              Balance: {modalDetails.balance ? parseFloat(Number(modalDetails.balance).toFixed(5)) : "-"} {modalDetails.label}
            </span>
            <PercentageButtons setAmountIn={setModalAmount} balance={modalDetails.balance} />
          </div>
        </div>

        <div className="modal-confirm-btn mt-4">
          <PlButton
            disabled={account === null || chainId !== configs.CHAIN_ID || Number(modalAmount) === 0 || compareBigNums(modalAmount, modalDetails.balance)}
            label={(account === null || chainId !== configs.CHAIN_ID)
              ? modalDetails.title
              : Number(modalAmount) === 0
                ? "enter an amount"
                : compareBigNums(modalAmount, modalDetails.balance)
                  ? "insufficient funds"
                  : modalDetails.title === "Deposit" && modalDetails.approvedBalance < Number(modalAmount)
                    ? "approve"
                    : modalDetails.title
            }
            onClick={() => {
              (modalDetails.approvedBalance >= Number(modalAmount) || modalDetails.title === "Withdraw") && onHide();
              modalDetails.title === "Deposit"
                ? modalDetails.approvedBalance < Number(modalAmount)
                ? handleApprove()
                : handleDeposit()
                : handleWithdraw();
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DepositWithdraw;
