// Will mostly have only UI component imports and placement in this file.

import { Col, Row } from "react-bootstrap";

import icon from "./assets/analyticsIcon.svg";

import Chart from "./cards/Chart";
import GlobalMetrics from "./cards/GlobalMetrics";

const Page = ({  metricsData, AUMDetails, tokenChartDetails, volumeDetails, feeVolumeDetails }) => {
  return (
    <>
      {/*<h1 className="page_title dashboard_title d-flex align-items-center justify-content-start">*/}
      {/*  <img src={icon} alt="" /> ANALYTICS*/}
      {/*</h1>*/}
      <GlobalMetrics metricsData={metricsData} />
      <Row md={2}>
        <Chart AUMDetails={AUMDetails}
               feeVolumeDetails={feeVolumeDetails}
               volumeDetails={volumeDetails}
               tokenChartDetails={tokenChartDetails} />
      </Row>
    </>
  );
};

export default Page;
