import Modal from "react-bootstrap/Modal";

import closeIcon from "../../assets/images/modal-close-icon.svg";
import arrow from "../../assets/images/launch_redirect.svg";
import confirm from "../../assets/images/confirm_tickmark.svg";
import { useCommonStateContext } from "../../hooks/commonStateContext";

const CompleteTransaction = ({
                               show,
                               onHide,
                               selectedOrder,
                               tradeConditions,
                               successMessage,
                               orderMessage,
                               positionSize
                             }) => {
  const { explorerURL } = useCommonStateContext();
  // console.log("tradeConditions-", tradeConditions);

  return (
    <Modal size="lg" centered dialogClassName="lamp-modal success-modal" show={show}>
      <Modal.Body>
        <div className="close-icon" onClick={onHide}>
          <img src={closeIcon} height={12} width={12} />
        </div>

        <div className="d-flex mb-4 align-items-center justify-content-center">
          <img src={confirm} />
        </div>
        <div className="confirm_modal mt-3 d-flex align-items-center justify-content-center flex-column gap-2">
          {selectedOrder ? (
            <p className="modal_text">
              Canceled order to swap{" "}
              <span>
                {Number(selectedOrder.priceIn) < 0.00001 ? "<0.00001" : parseFloat(Number(selectedOrder.priceIn).toFixed(5))} {selectedOrder.assetIn}{" "}
              </span>{" "}
              for{" "}
              <span>
                {Number(selectedOrder.priceOut) < 0.00001 ? "<0.00001" : parseFloat(Number(selectedOrder.priceOut).toFixed(5))} {selectedOrder?.assetOut}
              </span>
            </p>
          ): orderMessage?.length > 0 ? (
            <p className="modal_text" dangerouslySetInnerHTML={{ __html: orderMessage }}></p>
          ) : tradeConditions ? (
            <p className="modal_text">
              Requested to
              Increase {tradeConditions.selectedTo.value} {tradeConditions.selectedTradeTab.toUpperCase()}{" "}
              by <span>${parseFloat(positionSize.toFixed(3))}</span>
            </p>
          ) : successMessage?.length > 0 ? (
            <h3 className="modal_text" dangerouslySetInnerHTML={{ __html: successMessage }}></h3>
          ) : null}
          <a className="pointer-cursor modal_link" href={explorerURL} target="_blank">
            View Txn Hash
          </a>
        </div>

        {/*<div className="confirm_modal mt-3">*/}
        {/*  <p className="modal_text d-flex align-items-center justify-content-center gap-2">*/}
        {/*    Transaction Completed!{" "}*/}
        {/*    <img className="pointer-cursor" onClick={() => window.open(explorerURL, "_blank")} src={arrow} />*/}
        {/*  </p>*/}
        {/*</div>*/}
      </Modal.Body>
    </Modal>
  );
};

export default CompleteTransaction;
