// all the business logic will go here in container
// the page will be passed with all the props needed inside
import Page from "./Page";

import { ReactComponent as TotalTrade } from "../../assets/images/totaltrading.svg";
import { ReactComponent as TotalPercentag } from "../../assets/images/totalPercentag.svg";
import { ReactComponent as TotalDolarIcon } from "../../assets/images/totaldolarIcon.svg";
import { useEffect, useState } from "react";
import { useWeb3ConnectContext } from "../../hooks/web3ConnectContext";

const metricsData = [
  { title: "Total Deposited", value: "$28,018.42", img: <TotalDolarIcon /> },
  { title: "APR", value: "210.54%", img: <TotalPercentag /> },
  { title: "24h Volume", value: "$23,846.57", img: <TotalTrade /> },
  { title: "Your Deposit", value: "$0.0", img: <TotalDolarIcon /> },
];

const Container = () => {
  const { accountBalance } = useWeb3ConnectContext();
  const [balances, setBalances] = useState({ deposit: 0, withdraw: 0, claim: 0 });
  const [metricsDetails, setMetricsDetails] = useState();
  const [depositDetails, setDepositDetails] = useState(); // need to show all data in deposit card includes and slippage
  const [depositAmountIn, setDepositAmountIn] = useState();
  const [depositAmountOut, setDepositAmountOut] = useState();
  const [withdrawAmount, setWithdrawAmount] = useState();
  const [claimAmount, setClaimAmount] = useState();

  useEffect(() => {
    if(accountBalance !== undefined){
      fetchLampConditions();
    }
  }, [accountBalance]);

  //count amountOut in deposit according to amountIn
  useEffect(() => {
    countDepositAmountOut();
  }, [depositAmountIn]);

  const fetchLampConditions =async () => {
    setBalances({ deposit: Number(accountBalance), withdraw: 0, claim: 0 })
  };

  const countDepositAmountOut = () => {};

  const handleDeposit = () => {};
  const handleClaim = () => {};
  const handleWithdraw = () => {};
  const handleRemoveLiquidity = () => {};

  return (
    <div className="swap liquidity-details-main container">
      <Page metricsData={metricsData} />
    </div>
  );
};

export default Container;
