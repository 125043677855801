// all the business logic will go here in container
// the page will be passed with all the props needed inside

import Page from "./Page";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";

const Container = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate("/trade");
  };

  return (
    <>
      <div className="container">
        <Page handleGetStarted={handleGetStarted} />
      </div>
      <Footer />
    </>
  );
};
export default Container;
