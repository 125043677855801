import { Slide } from "react-toastify";

export const toastOptions = {
  type: "success",
  autoClose: 3000,
  position: "bottom-right",
  closeButton: false,
  hideProgressBar: true,
  closeOnClick: false,
  icon: false,
  transition: Slide,
  style: { color: "#38D900", backgroundColor: "#272320" },
};