// all the business logic will go here in container
// the page will be passed with all the props needed inside

import Page from "./Page";

import { ReactComponent as TotalValue } from "../../assets/images/totallocked.svg";
import { ReactComponent as TotalTrade } from "../../assets/images/totaltrading.svg";
import { ReactComponent as AccruedFee } from "../../assets/images/accruedfee.svg";
import { ReactComponent as Interest } from "./assets/interestIcon.svg";
import { ReactComponent as Wallet } from "../../assets/images/walletIcon.svg";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { ACCRUED_FEE, TRADE_SWAP_FEE_VOLUME, TRADE_SWAP_VOLUME, TRANCHE_PRICE } from "../../io/subgraph";
import configs from "../../config.json";
import Loader from "../../components/loader/Loader";
import { useCommonStateContext } from "../../hooks/commonStateContext";
import { getCalcOutData, trancheSupply } from "../../io/kava";
import { useMetaMask } from "metamask-react";

const Container = () => {
  const [tranchePrice] = useLazyQuery(TRANCHE_PRICE, {
    context: { clientName: "analytics" },
  });
  const [accruedFees] = useLazyQuery(ACCRUED_FEE, { context: { clientName: "analytics" } });
  const [tradeSwapVolume] = useLazyQuery(TRADE_SWAP_VOLUME, { context: { clientName: "analytics" } });
  const [tradeSwapFeeVolume] = useLazyQuery(TRADE_SWAP_FEE_VOLUME, { context: { clientName: "analytics" } });

  const { setLoading, loading } = useCommonStateContext();
  const { account, chainId } = useMetaMask();

  const [metricsDetails, setMetricsDetails] = useState();
  const [AUMDetails, setAUMDetails] = useState();
  const [tokenChartDetails, setTokenChartDetails] = useState();
  const [volumeDetails, setVolumeDetails] = useState();
  const [feeVolumeDetails, setFeeVolumeDetails] = useState();

  const metricsData = [
    {
      title: "Total Value Locked",
      value: metricsDetails?.totalValueLocked === undefined ? "-" : metricsDetails.totalValueLocked,
      profit: "$2,354 (24h)",
      img: <TotalValue />,
    },
    {
      title: "Total Trading Volume",
      value: metricsDetails?.totalTradingVolume === undefined ? "-" : metricsDetails.totalTradingVolume,
      profit: "$72,018.42 (24h)",
      img: <TotalTrade />,
    },
    {
      title: "Current Open Interest",
      value: metricsDetails?.openInterest === undefined ? "-" : metricsDetails.openInterest,
      profit: "$72,018.42 (24h)",
      img: <Interest />,
    },
    {
      title: "Accrued Fees",
      value: metricsDetails?.accruedFee === undefined ? "-" : metricsDetails.accruedFee,
      profit: "$718.42 (24h)",
      img: <AccruedFee />,
    },
    // { title: "Number of Wallets", value: "3,927", profit: "946 (24h)", img: <Wallet /> },
  ];

  useEffect(() => {
    fetchApiData();
  }, [account, chainId]);

  const fetchApiData = async () => {
    setLoading(true);

    try {
      const { data: feeData } = await accruedFees();
      let totalFee = 0;
      // console.log("feeData--", feeData);
      feeData.tranches.map((tranche) => {
        totalFee += Number(tranche.totalFeeValue);
      });

      const { data: totalVolumeData } = await tradeSwapVolume({ variables: { period: "total" } });
      // console.log("totalVolumeData--", totalVolumeData);
      let totalTradingVolume = totalVolumeData.volumeStats[0].trading;
      let openInterest = Number(totalVolumeData.tradingStats[0].longOpenInterest) + Number(totalVolumeData.tradingStats[0].shortOpenInterest);

      const getXAxis = (time) => {
        var u = new Date(time * 1000);
        let day = u.getDate().toString().padStart(2, "0");
        const month = u.toLocaleString("default", { month: "short" });
        let year = u.getFullYear();
        return { monthOnly: month + ` ${day}`, withYear: month + ` ${day}` + ` ${year}` };
      };

      const currentTime = Date.now() / 1000;

      const volumeData = [];
      const tradeSwapFeeData = [];

      const { data: volumeChartData } = await tradeSwapFeeVolume({
        variables: {
          start: Math.round(currentTime) - 691200,
          // start: Math.round(currentTime) - 3888000,
          end: Math.round(currentTime),
        },
      });

      volumeChartData.volumeStats.map((elem) => {
        const newElem = {
          ...elem,
          dayMonth: getXAxis(elem.timestamp).monthOnly,
          withYear: getXAxis(elem.timestamp).withYear,
          "Leveraged Trading": Math.round(Number(elem.trading)),
          Swap: Math.round(Number(elem.swap)),
          Cumulative: Math.round(Number(elem.cumulative)),
        };
        volumeData.push(newElem);
      });

      volumeChartData.feeStats.map((elem) => {
        const newElem = {
          ...elem,
          dayMonth: getXAxis(elem.timestamp).monthOnly,
          withYear: getXAxis(elem.timestamp).withYear,
          "Leveraged Trading": Number(elem.trading),
          Swap: Number(elem.swap),
          Cumulative: Number(elem.cumulative),
        };
        tradeSwapFeeData.push(newElem);
      });

      // console.log("tradeSwapFeeData-", tradeSwapFeeData);
      setVolumeDetails(volumeData);
      setFeeVolumeDetails(tradeSwapFeeData);

      const trancheData = [];
      let tokenAumData = [];

      for (let i = 0; i < configs.lpTokens.length; i++) {
        const { data } = await tranchePrice({
          variables: {
            id: configs.tranches[configs.lpTokens[i]],
            start: Math.round(currentTime) - 691200,
            // start: Math.round(currentTime) - 3888000,
            end: Math.round(currentTime),
          },
        });
        data.trancheStats?.map((item) => {
          const newItem = {
            ...item,
            trancheType: configs.lpTokens[i],
            AUM: Math.round(Number(item.trancheValue)),
          };
          trancheData.push(newItem);
        });

        tokenAumData = tokenAumData.concat(data.tokenDistributionStats);

      }
      // console.log("tokenAumData--", tokenAumData);
      const trancheAumData = trancheData.reduce((acc, { timestamp, trancheType, AUM }) => {
        acc[timestamp] ??= { timestamp: timestamp, Junior: 0, Mezzanine: 0, Senior: 0 };
        acc[timestamp].dayMonth = getXAxis(timestamp).monthOnly;
        acc[timestamp].withYear = getXAxis(timestamp).withYear;
        if (trancheType === configs.lpTokens[0]) acc[timestamp].Senior = AUM;
        else if (trancheType === configs.lpTokens[1]) acc[timestamp].Mezzanine = AUM;
        else if (trancheType === configs.lpTokens[2]) acc[timestamp].Junior = AUM;
        return acc;
      }, {});

      const tokenData = tokenAumData.reduce((acc, { timestamp, token, value }) => {
        acc[timestamp] ??= { timestamp: timestamp, KAVA: 0, BTC: 0, ETH: 0, USDT: 0 };

        acc[timestamp].dayMonth = getXAxis(timestamp).monthOnly;
        acc[timestamp].withYear = getXAxis(timestamp).withYear;
        if (token === configs.tokens.USDT.toLowerCase()) acc[timestamp].USDT += Number(value);
        else if (token === configs.tokens.KAVA.toLowerCase()) acc[timestamp].KAVA += Number(value);
        else if (token === configs.tokens.BTC.toLowerCase()) acc[timestamp].BTC += Number(value);
        else if (token === configs.tokens.ETH.toLowerCase()) acc[timestamp].ETH += Number(value);

        return acc;
      }, {});
      // console.log("Object.values(tokenData)--", Object.values(tokenData));
      setTokenChartDetails(Object.values(tokenData));

      setAUMDetails(Object.values(trancheAumData));

      if (account !== null && chainId === configs.CHAIN_ID) {

        const totalValueLocked = await trancheSupply();
        setMetricsDetails({ totalValueLocked, totalTradingVolume, openInterest, accruedFee: totalFee / 1e30 });
        // console.log("totalValueLocked--", totalValueLocked);
      }
    } catch (err) {
      console.log("analytics err--", err);
    }

    setLoading(false);
  };

  return (
    <div className="trade container">
      <Loader loading={loading} />
      <Page metricsData={metricsData}
            volumeDetails={volumeDetails}
            feeVolumeDetails={feeVolumeDetails}
            tokenChartDetails={tokenChartDetails}
            AUMDetails={AUMDetails} />
    </div>
  );
};

export default Container;
