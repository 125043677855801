import Modal from "react-bootstrap/Modal";

import closeIcon from "../../../assets/images/modal-close-icon.svg";
import copyIcon from "../../../assets/images/copyIcon.svg";

const Invite = (props) => {
  const { show, onHide } = props;

  return (
    <Modal {...props} size="lg" centered dialogClassName="claim-modal" show={show}>
      <Modal.Body>
        <div className="close-icon" onClick={onHide}>
          <img src={closeIcon} />
        </div>

        <div className="modal-title mb-3">
          <h3>INVITE </h3>
        </div>
        <div className="mt-4">
          <div className="input-wrapper referral_link_input">
            <label>Referral Link</label>
            <input readOnly={true} value="https://lorem.ipsum/bKsF" />
            <img src={copyIcon} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Invite;
