// Will mostly have only UI component imports and placement in this file.

import SwapDetail from "../../../components/SwapDetail";

const Swap = () => {
  return (
    <>
      {/*<div className="trading-section">*/}
      <p className="title">Swap</p>
      <SwapDetail />
      {/*</div>*/}
    </>
  );
};

export default Swap;
