import Select, { components } from "react-select";
import DropdownArrowBold from "../../assets/images/arrow-dropdown-bold.svg";

const NoBoxSelect = ({ disabled, value, onSelectChange, optionValue }) => {
  return (
    <>
      <div className="w-fit-content">
        <Select
          defaultValue={optionValue[0]}
          onChange={onSelectChange}
          value={value}
          options={optionValue}
          isClearable={false}
          hideSelectedOptions
          isDisabled={disabled}
          classNamePrefix="select no-box select-custom"
          components={{
            IndicatorSeparator: null,
            DropdownIndicator: (props) => (
              <components.DropdownIndicator {...props}>
                <img src={DropdownArrowBold} alt="icon" height={8} width={12} className="icon" />
              </components.DropdownIndicator>
            ),
          }}
        />
      </div>
    </>
  );
};

export default NoBoxSelect;
