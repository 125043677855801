import PlButton from "../../components/buttons/Button";
import img1 from "./assets/01.svg";
import img2 from "./assets/02.svg";
import img3 from "./assets/03.svg";
import user from "./assets/userPlus.svg";
import download from "./assets/download.svg";
import graph from "./assets/graph.svg";
import infinite from "./assets/infinite.svg";
import secure from "./assets/secure.svg";
import noLimits from "./assets/noLimits.svg";
import lowFees from "./assets/lowFees.svg";
import easyUse from "./assets/easyUse.svg";
import lamp from "./assets/homeLamp.svg";
import inShadow1 from "./assets/InShadow1.png";
import inShadow2 from "./assets/InShadow2.png";
import inShadow3 from "./assets/InShadow3.png";
import inShadow4 from "./assets/InShadow4.png";

const Page = ({ handleGetStarted }) => {
  const cardDetails = [
    {
      title: "Trade 24/7",
      img: infinite,
      desc: "With Perpetual Lamp, you can trade your favorite cryptocurrencies around the clock, 24/7, without any interruptions. Our platform never sleeps, so you can make trades whenever you want, wherever you are.",
    },
    {
      title: "Decentralized & Secure",
      img: secure,
      desc: "Our platform is fully decentralized, meaning you have complete control over your assets. You don't have to worry about the security of your funds as we use advanced security measures to protect your assets and information.",
    },
    {
      title: "Low Fees",
      img: lowFees,
      desc: "We know that trading fees can be a major concern for traders, which is why we've kept our fees low. We don't charge any hidden fees or commissions, so you can keep more of your profits. ",
    },
    {
      title: "No Limits",
      img: noLimits,
      desc: "There are no limits on how much you can trade on Perpetual Lamp. Whether you're a beginner or a seasoned trader, our platform can accommodate your trading needs.",
    },
    {
      title: "Easy to Use",
      img: easyUse,
      desc: "Our user-friendly interface is designed to make trading easy for everyone. Whether you're a beginner or a pro, you'll find our platform intuitive and easy to navigate.",
    },
  ];

  return (
    <>
      <img src={lamp} alt="" className="lamp_image" />
      <img src={inShadow1} alt="" className="in_shadow_1" />
      <img src={inShadow2} alt="" className="in_shadow_2" />
      <img src={inShadow3} alt="" className="in_shadow_3" />
      <img src={inShadow4} alt="" className="in_shadow_4" />
      <div className="d-flex flex-column align-items-start justify-content-center w-50 w_div">
        <h1 className="welcome_heading">Welcome to Perpetual Lamp </h1>
        <p className="welcome_desc">
          The decentralized perpetual exchange that empowers traders to exchange any digital asset perpetually
        </p>
        <PlButton label={"Get Started"} onClick={handleGetStarted} className={"w_get_started"} />
      </div>

      <div className="position-relative d-flex flex-column align-items-center justify-content-start">
        <div className="start_details_div">
          <h1 className="welcome_heading how_it_works">How it works</h1>
          <p className="welcome_desc text-center mb-0">
            Perpetual Lamp works by using smart contracts on the the blockchain. Our platform allows traders to deposit
            their assets into a smart contract, which then allows them to trade without the need for intermediaries.
            Traders can leverage their positions up to 20x, enabling them to make larger trades with smaller amounts of
            capital.
          </p>
          <div className="start_steps_div d-flex justify-content-between">
            <div className="d-flex flex-column align-items-start justify-content-center">
              <h1 className="welcome_heading easy_start ">Getting started is easy</h1>
              <p className="welcome_desc sign_in_details">
                Simply sign up on our platform and deposit your digital assets into your account.
                <br />
                Once your assets are deposited, you can start trading perpetually on our platform in no time.
              </p>
              <PlButton label={"Get Started"} onClick={handleGetStarted} className={"w_get_started"} />
            </div>
            <div className=" d-flex flex-column align-items-center justify-content-center ">
              <div className="all_steps">
                <img src={img1} alt="img" />
                <div className="about_step">
                  <img src={user} alt="img" />
                  <p className="welcome_desc mb-0 fw-600">Sign up on our platform</p>
                </div>
              </div>
              <div className="all_steps">
                <img src={img2} alt="img" />
                <div className="about_step second_step">
                  <img src={download} alt="img" />
                  <p className="welcome_desc mb-0 fw-600">Deposit your assets</p>
                </div>
              </div>
              <div className="all_steps">
                <img src={img3} alt="img" />
                <div className="about_step">
                  <img src={graph} alt="img" />
                  <p className="welcome_desc mb-0 fw-600">Start trading in no time</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="join_pp_lamp">
        <p className="welcome_desc mb-0">
          Join Perpetual Lamp today and start trading with complete confidence and security.
        </p>
        <PlButton label={"Get Started"} onClick={handleGetStarted} className={"absolute_get_started"} />
      </div>
      <div className="cards_div">
        {cardDetails.map((item, i) => (
          <div key={i} className="detail_card d-flex flex-column">
            <p className="card_title">{item.title}</p>
            <img src={item.img} alt="" />
            <p className="card_desc">{item.desc}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default Page;
