import React, { useEffect, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import arrowSvg from "../assets/images/arrow-forward-white.svg";
import loaderSvg from "../assets/images/icons8-refresh.svg";
import { useCommonStateContext } from "../hooks/commonStateContext";

import { cancelOrder } from "../io/kava";

import Confirm from "./modals/Confirm";
import CompleteTransaction from "./modals/CompleteTransaction";
import { tokenImages } from "./static/SupportedTokens";
import configs from "../config.json";
import { ethers } from "ethers";
import { useLazyQuery } from "@apollo/client";
import { GET_HISTORIES } from "../io/subgraph";
import { useMetaMask } from "metamask-react";
import { waitingToast } from "./toasts/Waiting";
import { successToast } from "./toasts/Success";
import { handleError } from "./toasts/Error";
import arrow from "../assets/images/launch_redirect.svg";
import { useWeb3ConnectContext } from "../hooks/web3ConnectContext";
import CustomPagination from "./CustomPagination";
import config from "../config.json";

const orderColumnTitles = ["From", "To", "Trigger Price", "Market Price", "Action"];
const historyColumnTitles = ["Action", "Condition", "Type", "Status", ""];

const SwapDetail = () => {
  const { chainId, account } = useMetaMask();
  const { accountBalance } = useWeb3ConnectContext();
  const [dataFetching, setDataFetching] = useState(false);
  const [isReFetching, setIsReFetching] = useState(false);

  const [showModal, setShowModal] = useState(false); //to show and hide confirmation modal
  const [selectedOrder, setSelectedOrder] = useState(); //to select order which is user wants to cancel
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [orderDetails, setOrderDetails] = useState({}); //to set all orders from subgraph

  const { calcSwapOutDetails, setUsdValue, setLoading, setExplorerURL } = useCommonStateContext();
  const [orderHistory, { refetch }] = useLazyQuery(GET_HISTORIES, {
    variables: { owner: account, skip: 0, updateType: "SWAP" },
  });

  const [currentPage, setCurrentPage] = useState(0);
  const [currentOrderPage, setCurrentOrderPage] = useState(0);

  useEffect(() => {
    if (accountBalance !== undefined && calcSwapOutDetails !== undefined && account !== null) {
      getOrderHistoryData();
    }
  }, [account, chainId, accountBalance]);

  //console.log("calcSwapOutDetails--", calcSwapOutDetails);
  //for get data from subgraph to show history and active orders
  const getOrderHistoryData = async () => {

    // console.log("test---orderDetails---", orderDetails);
    // console.log("test---condition---", Object.entries(orderDetails).length === 0);
    setDataFetching(Object.entries(orderDetails).length === 0);
    setDataFetching(true);

    const { data } = await orderHistory();

    const unixTime = (time) => {
      var u = new Date(time * 1000);
      return u.toLocaleString();
    };

    let swapOrders = [];
    for (let i = 0; i < data.histories.length; i++) {
      const newOrder = {
        ...data.histories[i],
        assetIn: data.histories[i].tokenIn === configs.subgraphNativeAddress ? configs.nativeToken : configs.tokensType[data.histories[i].tokenIn.substring(1)],
        assetOut: data.histories[i].tokenOut === configs.subgraphNativeAddress ? configs.nativeToken : configs.tokensType[data.histories[i].tokenOut.substring(1)],
        dateTime: unixTime(data.histories[i].createdAtTimestamp),
      };
      newOrder.priceIn = ethers.utils.formatUnits(data.histories[i].amountIn, configs.decimals[newOrder.assetIn]);
      newOrder.priceOut = ethers.utils.formatUnits(
        data.histories[i].amountOut ? data.histories[i].amountOut : data.histories[i].minAmountOut,
        configs.decimals[newOrder.assetOut]);

      if (newOrder.type === "LIMIT") {
        if (newOrder.status === "FILLED") {
          newOrder.priceTriggered = newOrder.executionPrice !== null ? ethers.utils.formatUnits(newOrder.executionPrice, 30 - configs.decimals[newOrder.assetIn]) : 0;
        } else {
          newOrder.priceTriggered = ethers.utils.formatUnits(newOrder.triggerPrice, 12);
        }
      }

      swapOrders.push(newOrder);
    }

    let limitSwapOrders = [];
    for (let i = 0; i < data.orders.length; i++) {
      if (data.orders[i].status === "OPEN") {
        const ID = data.orders[i].id.split("-")[0];
        let newOrder = {
          ...data.orders[i],
          assetIn: data.orders[i].tokenIn === configs.subgraphNativeAddress ? configs.nativeToken : configs.tokensType[data.orders[i].tokenIn.substring(1)],
          assetOut: data.orders[i].tokenOut === configs.subgraphNativeAddress ? configs.nativeToken : configs.tokensType[data.orders[i].tokenOut.substring(1)],
          orderId: Number(ID),
          priceTriggered: ethers.utils.formatUnits(data.orders[i].price, 12),
        };

        newOrder.priceIn = ethers.utils.formatUnits(data.orders[i].amountIn, configs.decimals[newOrder.assetIn]);
        newOrder.priceOut = ethers.utils.formatUnits(data.orders[i].minAmountOut, configs.decimals[newOrder.assetOut]);

        const priceIn = calcSwapOutDetails.prices[newOrder.assetIn];
        const priceOut = calcSwapOutDetails.prices[newOrder.assetOut];
        const marketPrice = Number(priceIn) / Number(priceOut);

        newOrder = {
          ...newOrder, marketPrice,
        };
        limitSwapOrders.push(newOrder);
      }
    }

    limitSwapOrders = limitSwapOrders.sort((a, b) => b.orderId - a.orderId);
    swapOrders = swapOrders.sort((a, b) => b.createdAtTimestamp - a.createdAtTimestamp);

    setOrderDetails({
      active: limitSwapOrders,
      history: swapOrders,
    });
    setIsReFetching(false);
    setDataFetching(false);
  };

  //for cancel active orders
  const handleCancelOrder = async (order) => {
    setLoading(true);
    try {
      waitingToast(
        `Cancelling order to swap ${Number(order.priceIn) < 0.00001 ? "<0.00001"
          : parseFloat(Number(order.priceIn).toFixed(5))} ${order.assetIn} for ${
          Number(order.priceOut) < 0.00001 ? "<0.00001"
            : parseFloat(Number(order.priceOut).toFixed(5))} ${order.assetOut}.`,
      );

      const res = await cancelOrder(order);

      successToast(
        `Cancelled order to swap ${Number(order.priceIn) < 0.00001 ? "<0.00001"
          : parseFloat(Number(order.priceIn).toFixed(5))} ${order.assetIn} for ${
          Number(order.priceOut) < 0.00001 ? "<0.00001"
            : parseFloat(Number(order.priceOut).toFixed(5))} ${order.assetOut}.`,
      );

      //This URL needs to be updated according to the Kava Explorer.
      setExplorerURL(`https://goerli.etherscan.io/tx/${res.hash}`);
      setShowCancelModal(true);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };
  // console.log("dataFetching-", dataFetching);
  const handleShowModal = async (order) => {
    setSelectedOrder(order);
    setShowModal(true);

    const fromPrice = calcSwapOutDetails.prices[order.assetIn];
    const toPrice = calcSwapOutDetails.prices[order.assetOut];
    const getInValue = Number(fromPrice) * Number(order.priceIn);
    const getOutValue = Number(toPrice) * Number(order.priceOut);

    setUsdValue({
      fromUsdValue: parseFloat(Number(getInValue).toFixed(2)),
      toUsdValue: parseFloat(Number(getOutValue).toFixed(2)),
    });
  };

  const handleCloseModal = () => {
    setSelectedOrder();
    setShowModal(false);
  };

  useEffect(() => {
    if (calcSwapOutDetails !== undefined) {
      handleRefresh();
    }
  }, [calcSwapOutDetails]);

  const handleRefresh = async () => {
    if (account !== null) {
      setIsReFetching(true);
      await refetch();
      await getOrderHistoryData();
    }
  };

  const handleCloseSuccessModal = () => {
    setShowCancelModal(false);
    setSelectedOrder();
    handleRefresh();
  };

  let itemsPerPage = 10;
  const pageCount = Math.ceil(orderDetails.history?.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const currentItems = orderDetails.history?.slice(startIndex, startIndex + itemsPerPage);

  const orderPageCount = Math.ceil(orderDetails.active?.length / itemsPerPage);
  const orderStartIndex = currentOrderPage * itemsPerPage;
  const currentOrderItems = orderDetails.active?.slice(orderStartIndex, orderStartIndex + itemsPerPage);

  const handlePageClick = (selectedPage) => {
    if (selectedPage >= 0 && selectedPage < pageCount) {
      setCurrentPage(selectedPage);
    }
  };

  const handleOrdersPageClick = (selectedPage) => {
    if (selectedPage >= 0 && selectedPage < orderPageCount) {
      setCurrentOrderPage(selectedPage);
    }
  };

  return (
    <div className="shadowed-box max_box  mt-4 position-relative">
        <span
          className="d-flex align-items-center justify-content-center gap-1 refresh_btn position-absolute pointer-cursor"
          onClick={handleRefresh}><img className={`${isReFetching ? "rotate" : undefined}`} src={loaderSvg} height={16}
                                       width={16} /></span>
      <Tabs defaultActiveKey="orders" id="uncontrolled-tab-example" className="swap-details-tabs head_margin">
        <Tab eventKey="orders" title="Active Orders">
          {dataFetching ? (
            <div className="trade_history_loader w-100">
              {/*<img className="loading_img" src={loader} />*/}
              <div className="loading">Loading</div>
            </div>
          ) : (
            <div className="trading-section history_table_section">
              {orderDetails.active?.length === 0 ? (
                <div className="d-flex align-items-center justify-content-center empty_div w-100">
                  <span>You haven't placed any orders yet.</span>
                </div>
              ) : (
                <table className="w-100">
                  <thead>
                  <tr className="heading swap-content-wrapper mt-4">
                    {orderColumnTitles.map((column, i) => (
                      <th key={i}>{column}</th>
                    ))}
                  </tr>
                  </thead>
                  <tbody className="swap-order-wrapper gap-3 align-content-start">
                  {currentOrderItems?.map((order, i) => (
                    <tr className="swap-data data mb-0" key={i}>
                      <td>
                        <div className="d-flex align-items-center gap-1">
                          <img src={tokenImages[order.assetIn]?.image} height={18} width={18} />
                          <span>{(Number(order.priceIn) > 0 && Number(order.priceIn) < 0.0001)
                            ? "<0.00001" : parseFloat(Number(order.priceIn).toFixed(5))}</span>
                          <span>{order?.assetIn}</span>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center gap-1">
                          <img src={tokenImages[order.assetOut]?.image} height={18} width={18} />
                          <span>{(Number(order.priceOut) > 0 && Number(order.priceOut) < 0.0001)
                            ? "<0.00001" : parseFloat(Number(order.priceOut).toFixed(5))}</span>
                          <span>{order?.assetOut}</span>
                        </div>
                      </td>
                      <td>
                        <span>
                          {`1 ${order?.assetIn} ≥ ${(Number(order.priceTriggered) > 0 && Number(order.priceTriggered) < 0.00001)
                            ? "<0.00001" : parseFloat(Number(order.priceTriggered).toFixed(5))} ${order?.assetOut}`}
                        </span>
                      </td>
                      <td>
                         <span>
                          {`1 ${order?.assetIn} = ${(Number(order.marketPrice) > 0 && Number(order.marketPrice) < 0.00001)
                            ? "<0.00001" : parseFloat(Number(order.marketPrice).toFixed(5))} ${order?.assetOut}`}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`cancel_text ${(account === null || chainId !== config.CHAIN_ID) ? "disabled" : ""}`}
                          onClick={() => handleShowModal(order)}>Cancel</span>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              )}
              {orderDetails.active?.length > itemsPerPage &&
              <div className="pagination">
                <CustomPagination pageCount={orderPageCount} onPageChange={handleOrdersPageClick}
                                  currentPage={currentOrderPage} />
              </div>
              }
            </div>
          )}
        </Tab>
        <Tab eventKey="history" title="History">
          {dataFetching ? (
            <div className="trade_history_loader w-100">
              {/*<img className="loading_img" src={loader} />*/}
              <div className="loading_swap_chart">Loading</div>
            </div>
          ) : (
            <div className="trading-section history_table_section">
              {orderDetails.history?.length === 0 ? (
                <div className="d-flex align-items-center justify-content-center empty_div w-100">
                  <span>No records found.</span>
                </div>
              ) : (
                <table className="w-100">
                  <thead>
                  <tr className="heading swap-content-wrapper mt-4">
                    {historyColumnTitles.map((column, i) => (
                      <th key={i}>{column}</th>
                    ))}
                  </tr>
                  </thead>
                  <tbody className="swap-order-wrapper">
                  {currentItems?.map((order, i) => (
                    <tr className="swap-data data mb-0 history-data" key={i}>
                      <td>
                        <div className="d-flex align-items-center swap_data_div">
                          <span>{(Number(order.priceIn) > 0 && Number(order.priceIn) < 0.0001)
                            ? "<0.00001" : parseFloat(Number(order.priceIn).toFixed(5))}</span>
                          <span className="bold ms-2">{order?.assetIn} </span>
                          {/*<img src={tokenImages[order?.assetIn]?.image} height={16} width={16} />*/}
                          <img className="mx-2" src={arrowSvg} height={10} width={12} />
                          <span>{(Number(order.priceOut) > 0 && Number(order.priceOut) < 0.0001)
                            ? "<0.00001" : parseFloat(Number(order.priceOut).toFixed(5))}</span>
                          <span className="bold mx-2">{order?.assetOut} </span>
                          {/*<img src={tokenImages[order?.assetOut]?.image} height={16} width={16} />*/}
                        </div>
                        <span className="timeStamp">{order?.dateTime}</span>
                      </td>
                      <td>
                        <span className="condition_span">
                          {order?.type === "LIMIT"
                            ? `1 ${order?.assetIn} ${order.status === "FILLED" ? "=" : "≥"} ${
                              (Number(order.priceTriggered) > 0 && Number(order.priceTriggered) < 0.00001)
                                ? "<0.00001" : parseFloat(Number(order.priceTriggered).toFixed(5))
                            } ${order?.assetOut}`
                            : "-"}
                        </span>
                      </td>
                      <td className="col-2">
                        <span>{order?.type}</span>
                      </td>
                      <td className="col-2 pe-2">
                        <span
                          className={`${
                            order.status === "FILLED"
                              ? "trade-change-value"
                              : order.status === "CANCELLED"
                              ? "loss"
                              : ""
                          }`}
                        >
                          {order.status === "FILLED"
                            ? "Executed"
                            : order.status === "CANCELLED"
                              ? "Cancelled"
                              : "Created"}
                        </span>
                      </td>
                      <td className="pe-2">
                        <a target="_blank" href={`https://goerli.etherscan.io/tx/${order.tx}`}>
                          <img src={arrow} height={12} width={12} className="pointer-cursor" />
                        </a>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              )}
              {orderDetails.history?.length > itemsPerPage &&
              <div className="pagination">
                <CustomPagination pageCount={pageCount} onPageChange={handlePageClick} currentPage={currentPage} />
              </div>
              }
            </div>
          )}
        </Tab>
      </Tabs>

      {showModal && (
        <Confirm
          setShowModal={setShowModal}
          title={`CANCEL SWAP ${selectedOrder?.assetIn}/${selectedOrder?.assetOut}`}
          selectedOrder={selectedOrder}
          show={showModal}
          onHide={handleCloseModal}
          handleCancelOrder={handleCancelOrder}
        />
      )}
      {showCancelModal && (
        <CompleteTransaction
          selectedOrder={selectedOrder}
          show={showCancelModal}
          onHide={handleCloseSuccessModal}
        />
      )}
    </div>
  );
};

export default SwapDetail;
