// Will mostly have only UI component imports and placement in this file.

import { Col, Row } from "react-bootstrap";
import ReactTooltip from "react-tooltip";

const Token = ({ tokenData, tokensDetails }) => {
  // console.log('tokensDetails-',tokensDetails);
  return (
    <>
      <Row>
        <div className="trading-section">
          <p className="title">Token Distribution</p>
          <Row className="heading mx-0 ps-3 token-dist-head">
            {tokenData?.columnTitle?.map((item, i) => (
              <Col key={i} className={item.className}>
                <p>{item.title}</p>
                {/*<ReactTooltip*/}
                {/*  backgroundColor="#252927"*/}
                {/*  className="react_tooltip"*/}
                {/*  arrowColor="#252927"*/}
                {/*  place="top"*/}
                {/*  type="dark"*/}
                {/*  effect="solid"*/}
                {/*/>*/}
              </Col>
            ))}
          </Row>
          {tokensDetails.map((item, i) => (
            <Row className="data shadowed-box ps-3 py-0 pe-0 mx-0 pl-4" key={i}>
              <Col>
                <img src={item.image} height={20} width={20} />
                <span className="bold ms-2">{item.token}</span>
              </Col>
              <Col>
                <span
                  className="token-price">{item.price !== undefined ? `$${parseFloat(item.price.toFixed(3))}` : "-"}</span>
              </Col>
              <Col>
                <span>{item.amount !== undefined ? (item.amount < 0.001 && item.amount > 0) ? "<0.001" : parseFloat(item.amount.toFixed(3)).toLocaleString() : "-"}</span>
              </Col>
              <Col>
                <span>{item.value ? `$${(item.value < 0.01 && item.value > 0) ? "<0.01" : item.value > 1 ? Math.round(item.value).toLocaleString() : parseFloat(item.value.toFixed(2))}` : "-"}</span>
              </Col>
              <Col>
                <span>{item.weight !== undefined ? `${(item.weight < 0.01 && item.weight > 0) ? "<0.01" : item.weight.toFixed(2)}%` : "-"} / {
                  item.target !== undefined ? `${(item.target < 0.01 && item.target > 0) ? "<0.01" : item.target}%` : "-"}</span>
              </Col>
              <Col className="justify-content-center">
                <span>{item?.utilization !== undefined ? `${(item.utilization < 0.01 && item.utilization > 0) ? "<0.01" : item.utilization.toFixed(2)}%` : "-"}</span>
              </Col>
            </Row>
          ))}
        </div>
      </Row>
    </>
  );
};

export default Token;
