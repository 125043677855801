// Will mostly have only UI component imports and placement in this file.

import { Card, Col, Row, Tab, Tabs } from "react-bootstrap";

import icon from "./assets/daoIcon.svg";
import { ReactComponent as RedirectArrow } from "../../assets/images/launch_redirect.svg";
import lampSymbol from "./assets/lampSymbol.svg";
import tether from "../../assets/images/usdt-coin.png";
import tickMarkIcon from "./assets/proposeTickMark.svg";

import DaoCardDetail from "./DaoCardDetail";
import RedeemCardsDetail from "./RedeemCardDetail";
import WithTagSelect from "../../components/inputFields/WithTagSelect";

const Page = ({ metricsData, stakeData, balances, approvedBalance, account, handleLvlApprove, handleLgoApprove }) => {
  return (
    <>
      <Row>
        <h1 className="page_title d-flex align-items-center justify-content-start">
          <img src={icon} alt="" /> DAO
        </h1>
        <Tabs
          defaultActiveKey="overview"
          id="uncontrolled-tab-example"
          className="mb-3 mt-2 swap-details-tabs auction_page_tabs"
        >
          <Tab eventKey="overview" title="OVERVIEW">
            <div className="trading-section">
              <p className="title">Treasury</p>
              <Row xs={1} md={3} className="cards-deatils g-5">
                {metricsData.map((item, id) => (
                  <Col key={id}>
                    <Card className="shadowed-box p-0">
                      <Card.Body>
                        <div>
                          <p className="mb-2">{item.title}</p>
                          <span>{item.value}</span>
                          <span className="treasury_details_span">{item.details}</span>
                        </div>
                        {item.img}
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
            <div className="trading-section">
              <p className="title">Stake</p>
              <Row xs={1} md={3} className="cards-deatils g-5">
                {stakeData.map((item, id) => (
                  <Col key={id}>
                    <Card className="shadowed-box p-0">
                      <Card.Body>
                        <div>
                          <p className="mb-2">{item.title}</p>
                          <span>{item.value}</span>
                          <span className="treasury_details_span">{item.details}</span>
                        </div>
                        {item.img}
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
            <Row xs={1} md={3} className="g-5 mt-4">
              <DaoCardDetail
                balances={balances}
                approvedBalance={approvedBalance}
                account={account}
                handleLvlApprove={handleLvlApprove}
              />
            </Row>
          </Tab>
          <Tab eventKey="redemption" title="REDEMPTION">
            <div className="trading-section mt-4">
              <Row xs={1} md={2} className="cards-deatils g-5">
                <Col md={4}>
                  <Card className="shadowed-box p-0">
                    <Card.Body>
                      <div className="d-flex flex-column">
                        <p className="mb-2">Estimated LGO Supply</p>
                        <span>7,524,397</span>
                        <span className="treasury_details_span redeem_span">$37,621,985</span>
                      </div>
                      <img src={lampSymbol} />
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={8}>
                  <Card className="shadowed-box p-0">
                    <Card.Body>
                      <div className="d-flex flex-column">
                        <p className="mb-2">Estimated Liquid Assets</p>
                        <div className="d-flex align-items-center justify-content-start gap-3">
                          <div className="d-flex flex-column">
                            <span className="d-flex align-items-center">
                              105,654
                              <p className="earn_tag ms-2"></p>
                              <img className="trade_head_img me-1" height={20} width={20} src={tether} />
                              LAMP/USDT LP<span className="ms-3">+</span>
                            </span>
                            <span className="treasury_details_span redeem_span ml-0">$531,370</span>
                          </div>
                          <div className="d-flex flex-column">
                            <span>
                              3,005,764 Senior LLP<span className="ms-3">=</span>
                            </span>
                            <span className="treasury_details_span redeem_span ml-0">$4,000,619</span>
                          </div>
                          <div className="d-flex flex-column">
                            <span>Total</span>
                            <span className="treasury_details_span redeem_span ml-0">$4,531,989</span>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
            <Row xs={1} md={3} className="g-5 mt-4">
              <RedeemCardsDetail
                balances={balances}
                account={account}
                approvedBalance={approvedBalance}
                handleLgoApprove={handleLgoApprove}
              />
            </Row>
          </Tab>
          <Tab eventKey="proposals" title="PROPOSALS">
            <div className="trading-section mt-4">
              <Row xs={1} md={3} className="cards-deatils g-5">
                <Col>
                  <Card className="shadowed-box p-0">
                    <Card.Body>
                      <div>
                        <p className="mb-2">Your Voting Power</p>
                        <span>7,524,397 LAMP</span>
                      </div>
                      <img src={lampSymbol} />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
            <div className="d-flex justify-content-between mt-4">
              <WithTagSelect
                isInput={false}
                optionValue={[
                  { label: "All Proposals", value: "All Proposals" },
                  { label: "Active Proposals", value: "Active Proposals" },
                  { label: "Pending Proposals", value: "Pending Proposals" },
                  { label: "Closed Proposals", value: "Closed Proposals" },
                ]}
              />
              <span className="d-flex align-items-center gap-2 snapshot_link">
                Go to Snapshot <RedirectArrow />
              </span>
            </div>
            <Row className="proposal_details_box shadowed-box w-100 mx-0 mt-4">
              <div className="wallet_tag_div d-flex align-items-center justify-content-between p-0">
                <p>0x123...ab02</p>
                <span>CLOSED</span>
              </div>
              <h2 className="p-0 mb-0">Lorem ipsum dolor sit amet.</h2>
              <p className="p-0 my-3">
                Aliquam imperdiet vel mi eu tristique. Vivamus vestibulum augue nisl, ac luctus orci elementum sed.
                Nullam pretium ipsum pretium leo hendrerit, at gravid nisi hendrerit. Cras a scelerisque est. Aenean
                massa nisl, cursus ut mauris a, efficitur venenatis nunc. Nullam nec mauris at leo pulvinar sagittis.
                Aenean...
              </p>
              <div className="d-flex align-items-center justify-content-between p-0">
                <div className="propose_details">
                  <p className="p-0 m-0">
                    <img src={tickMarkIcon} /> Mauris a, efficitur
                  </p>
                  <p className="p-0 m-0">
                    <span>90,573 LAMP</span>90.5%
                  </p>
                </div>
                <div className="propose_details">
                  <p className="p-0 m-0">Efficitur venenatis nunc</p>
                  <p className="p-0 m-0">
                    <span>9,427 LAMP</span>9.5%
                  </p>
                </div>
              </div>
            </Row>
          </Tab>
        </Tabs>
      </Row>
    </>
  );
};

export default Page;
