// all the business logic will go here in container
// the page will be passed with all the props needed inside

import Page from "./Page";

import { useEffect, useState } from "react";
import { getAuctions } from "../../io/kava";

import { useCommonStateContext } from "../../hooks/commonStateContext";
import Loader from "../../components/loader/Loader";
import { useWeb3ConnectContext } from "../../hooks/web3ConnectContext";

const Container = () => {
  const { setLoading, loading } = useCommonStateContext();
  const { accountBalance } = useWeb3ConnectContext();
  const [auctions, setAuctions] = useState(null);

  useEffect(() => {
    if (accountBalance !== undefined) {
      getAuctionData();
    }
  }, [accountBalance]);

  const getAuctionData = async () => {
    setLoading(true);

    const data = await getAuctions();
    setAuctions(data);
    console.log("AUCTION DATA: ", data);
    setLoading(false);
  };

  return (
    <div className="swap container">
      <Loader loading={loading} />
      <Page auctions={auctions} />
    </div>
  );
};

export default Container;
