
export const orderTypes = [
  {
    label: (
      <div className="d-flex align-items-center gap-1 fw-normal order_options">
        Market Order
      </div>
    ),
    value: "marketOrder",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-1 fw-normal order_options">
        Limit Order
      </div>
    ),
    value: "limitOrder",
  },
];
