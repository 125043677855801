import ReactTooltip from "react-tooltip";

const { Button: Button } = require("react-bootstrap");

const PlButton = ({ type, label, className, onClick, height, width,tag, disabled = false, src }) => {
  switch (type) {
    case "percentageButton":
      return (
        <Button onClick={onClick} className={`percentage-btn ${className}`}>
          {label}
        </Button>
      );

    default:
      return (
        <>
          <ReactTooltip backgroundColor='#16181c' className='react_btn_tooltip' textColor='#c0c0c0' arrowColor='#16181c' place="top" type="dark" effect="solid" />
          {tag ?
            <Button variant="secondary" data-tip={tag} className={`pl-btn ${className}`} disabled={disabled} onClick={onClick}>
              {label}
            </Button>
            :
            <Button variant="secondary" className={`pl-btn ${className}`} disabled={disabled} onClick={onClick}>
              {src ? <img height={height} width={width} src={src}/> : label}
            </Button>
          }
        </>
      );
  }
};

export default PlButton;
