// Will mostly have only UI component imports and placement in this file.

import { Col, Row } from "react-bootstrap";

import earn from "../../assets/images/earnIcon.svg";

import TokenInfo from "./cards/TokenInfo";

const Page = ({ earnData,getData}) => {
  return (
    <>
      <h1 className="page_title dashboard_title d-flex align-items-center justify-content-start">
        <img src={earn} alt="" /> EARN
      </h1>
      {earnData && (
        <Row md={3} className="gx-5 justify-content-center">
          <Col className="mt-0 mb-5">
            <TokenInfo type={"governance"}
                       getData={getData}
                       data={earnData?.governance} />
          </Col>
          <Col className="mt-0 mb-5">
            <TokenInfo type={"platform"}
                       getData={getData}
                       data={earnData?.platform} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default Page;
