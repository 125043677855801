// Will mostly have only UI component imports and placement in this file.

import { Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import flash from "../../../assets/images/flash.svg";
import arrowSvg from "../../../assets/images/arrow-forward.svg";

import PlButton from "../../../components/buttons/Button";

const Liquidity = ({ liquidityDetails }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="trading-section">
        <p className="title">Liquidity</p>
        <Row xs={1} md={3} className="cards-deatils g-5">
          {liquidityDetails.map((item, id) => (
            <Col key={id}>
              <Card className="shadowed-box p-0 border-0 pointer-cursor"
                    onClick={() => navigate(`/liquidity/${item.title === "Senior Pool" ? "senior" : item.title === "Mezzanine Pool" ? "mezzanine" : "junior"}`)}
              >
                <Card.Body>
                  <div>
                    <p className="liquidity_details bold">{item.title}</p>
                    <span className={item.classname}>
                      <img src={flash} height={12} width={8} /> {item.risk}
                    </span>
                  </div>
                  <div className="d-flex gap-3 align-items-center justify-content-center">
                    <div>
                      <p className="mb-2">7-day Trailing Profit</p>
                      <span className={`month_value ${item.profit > 0 ? "profit" : item.profit < 0 ? "loss" : ""}`}>
                        {item.profit < 0 && '-'}${item.profit > 0 && item.profit < 0.01
                        ? "<0.01" : parseFloat(Math.abs(Number(item.profit)).toFixed(2))}
                      </span>
                    </div>
                    <PlButton
                      src={arrowSvg}
                      className="sqaure"
                      height={14}
                      width={14}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default Liquidity;
