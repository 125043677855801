import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useCommonStateContext } from "../../../hooks/commonStateContext";

import SwapIcon from "../assets/swap-icon.svg";
import SwapLeftRightIcon from "../assets/swap-leftright-icon.svg";

import PlButton from "../../../components/buttons/Button";
import Confirm from "../../../components/modals/Confirm";
import CompleteTransaction from "../../../components/modals/CompleteTransaction";
import { supportedTokens } from "../../../components/static/SupportedTokens";
import { PercentageButtons } from "../../../components/buttons/Percentage";
import { SlippageRow } from "../../../components/SlippageRow";
import WithTagSelect from "../../../components/inputFields/WithTagSelect";
import { useWeb3ConnectContext } from "../../../hooks/web3ConnectContext";
import { compareBigNums } from "../../../components/HandleDecimals";
import { useMetaMask } from "metamask-react";
import config from "../../../config.json";

const PlaceOrder = ({
                      swapConditions,
                      setSwapConditions,
                      amountIn,
                      setAmountIn,
                      amountOut,
                      minimumReceived,
                      handleSwapValue,
                      handleApprove,
                      handleSwap,
                      swapFee,
                      swapLimitPrice,
                      setSwapLimitPrice,
                      handleCloseSuccessModal,
                      successModalMessage
                    }) => {
  const [swapConfirmModal, setSwapConfirmModal] = useState(false); //to hide and show swap confirmation modal
  const { showConfirmModal, setUsdValue, calcSwapOutDetails, userData } = useCommonStateContext();
  const [isTokenAlternate, setIsTokenAlternate] = useState(false);
  const [alterPrice, setAlterPrice] = useState(swapLimitPrice);
  const { account, chainId } = useMetaMask();

  const handleShowModal = async () => {
    setSwapConfirmModal(true);

    let getFrom = calcSwapOutDetails.prices[swapConditions.selectedFrom.value] * Number(amountIn);
    let getTo = calcSwapOutDetails.prices[swapConditions.selectedTo.value] * Number(amountOut);
    setUsdValue({
      fromUsdValue: Number(getFrom) >= 0.01 ? parseFloat(Number(getFrom).toFixed(2)) : "<0.01",
      toUsdValue: Number(getTo) >= 0.01 ? parseFloat(Number(getTo).toFixed(2)) : "<0.01",
    });
  };
  const SwapButton = () => {
    return (
      <div className="mt-4">
        <PlButton
          disabled={account === null || chainId !== config.CHAIN_ID ||
          swapConditions.selectedFrom?.value === swapConditions.selectedTo?.value ||
          Number(amountIn) <= 0 ||
          Number(amountOut) === 0 ||
          compareBigNums(amountOut, swapConditions.availableTokens) ||
          compareBigNums(amountIn, swapConditions.fromBalance)
          }
          label={(account === null || chainId !== config.CHAIN_ID)
            ? "swap"
            : swapConditions.selectedFrom?.value === swapConditions.selectedTo.value
              ? "no route for swap"
              : Number(amountIn) === 0
                ? "enter an amount"
                : (Number(amountOut) === 0 || compareBigNums(amountOut, swapConditions.availableTokens))
                  ? "insufficient liquidity"
                  : compareBigNums(amountIn, swapConditions.fromBalance)
                    ? "insufficient funds"
                    : swapConditions.approvedBalance < Number(amountIn)
                      ? "approve"
                      : "swap"
          }
          onClick={() => (swapConditions.approvedBalance < Number(amountIn) ? handleApprove() : handleShowModal(true))}
        />
      </div>
    );
  };

  const handleSwapPrices = () => {
    setIsTokenAlternate(!isTokenAlternate);

    if (isTokenAlternate) {
      setAlterPrice(calcSwapOutDetails?.prices[swapConditions.selectedFrom.value] / calcSwapOutDetails?.prices[swapConditions.selectedTo.value]);
    } else {
      setAlterPrice(calcSwapOutDetails?.prices[swapConditions.selectedTo.value] / calcSwapOutDetails?.prices[swapConditions.selectedFrom.value]);
    }
  };

  useEffect(() => {
    setMarketPrice();
  }, [swapConditions.selectedTo.value, swapConditions.selectedFrom.value]);

  useEffect(() => {
    setSwapLimitPrice(isTokenAlternate ? 1 / alterPrice : alterPrice);
  }, [alterPrice]);

  const setMarketPrice = () => {
    setSwapLimitPrice(Number(swapConditions?.price));

    if (!isTokenAlternate) {
      setAlterPrice(calcSwapOutDetails?.prices[swapConditions.selectedFrom.value] / calcSwapOutDetails?.prices[swapConditions.selectedTo.value]);
    } else {
      setAlterPrice(calcSwapOutDetails?.prices[swapConditions.selectedTo.value] / calcSwapOutDetails?.prices[swapConditions.selectedFrom.value]);
    }
  };
  console.log('amountOut input--',amountOut);
  return (
    <>
      <div className="shadowed-box">
        <Tabs
          defaultActiveKey="market"
          activeKey={swapConditions.selectedSwapTab}
          onSelect={(k) => setSwapConditions({ ...swapConditions, selectedSwapTab: k })}
          id="uncontrolled-tab-example"
          className="mb-3 swap_card_tabs"
        >
          <Tab eventKey="market" title="MARKET">
            <div className="mt-4">
              <FromInput
                swapConditions={swapConditions}
                setSwapConditions={setSwapConditions}
                amountIn={amountIn}
                setAmountIn={setAmountIn}
                calcSwapOutDetails={calcSwapOutDetails}
                userData={userData}
              />
              <div className="d-flex justify-content-between align-items-center mt-2">
                <span className="balance">
                  BAL: {swapConditions?.fromBalance ? parseFloat(Number(swapConditions.fromBalance).toFixed(5)) : "-"}
                </span>
                <PercentageButtons balance={swapConditions.fromBalance} setAmountIn={setAmountIn} />
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <img src={SwapIcon} className="rotate-animation pointer-cursor" onClick={handleSwapValue} />
            </div>
            <ReceiveInput
              userData={userData}
              calcSwapOutDetails={calcSwapOutDetails}
              amountOut={amountOut}
              swapConditions={swapConditions}
              setSwapConditions={setSwapConditions} />

            <div className="mt-4">
              <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                Price{" "}
                <span className="fw-600">{
                  swapConditions?.price ?
                    `1 ${swapConditions.selectedFrom?.value} = ${Number(swapConditions.price).toFixed(7).replace(/\.?0+$/, "")}
                ${swapConditions.selectedTo?.value}` : "-"}
                </span>
              </p>
              <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                Available Liquidity{" "}
                <span className="fw-600">
                  {swapConditions.availableTokens
                    ? `${parseFloat(Number(swapConditions.availableTokens).toFixed(5))} ${swapConditions.selectedTo?.value}`
                    : "-"}
                </span>
              </p>
              <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                Swap Fee <span className="fw-600">
                {(Number(amountIn) > 0 && swapFee > 0) ? `${(swapFee < 0.01 && swapFee > 0) ? "<0.01" : swapFee.toFixed(2)}%` : "-"}
              </span>
              </p>
              <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                Minimum Received{" "}
                <span className="fw-600">
                  {Number(minimumReceived) > 0
                    ? `${Number(minimumReceived) > 0.00001
                      ? parseFloat(Number(minimumReceived).toFixed(5))
                      : "<0.00001"} ${swapConditions.selectedTo?.value}`
                    : "-"}
                </span>
              </p>
              <SlippageRow setAllDetails={setSwapConditions} allDetails={swapConditions} />
            </div>
            <SwapButton />
          </Tab>
          <Tab eventKey="limit" title="LIMIT">
            <div className="mt-4">
              <FromInput
                swapConditions={swapConditions}
                setSwapConditions={setSwapConditions}
                amountIn={amountIn}
                setAmountIn={setAmountIn}
                calcSwapOutDetails={calcSwapOutDetails}
                userData={userData}
              />
              <div className="d-flex justify-content-between align-items-center mt-2">
                <span className="balance">
                  BAL: {swapConditions?.fromBalance ? parseFloat(Number(swapConditions?.fromBalance)?.toFixed(5)) : "-"}
                </span>
                <PercentageButtons balance={swapConditions.fromBalance} setAmountIn={setAmountIn} />
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <img src={SwapIcon} className="rotate-animation pointer-cursor" onClick={handleSwapValue} />
            </div>
            <ReceiveInput
              userData={userData}
              calcSwapOutDetails={calcSwapOutDetails}
              amountOut={amountOut}
              swapConditions={swapConditions}
              setSwapConditions={setSwapConditions} />
            <div className="d-flex justify-content-between align-items-center mt-4">
              <div className="w-50">
                <div className="input-wrapper referral_link_input">
                  <div className="USD_wrapper">
                    <input value="1" readOnly={true} className="cursor_not_allowed" />
                  </div>
                  <span
                    className="me-2">{isTokenAlternate ? swapConditions.selectedTo.value : swapConditions.selectedFrom.value}</span>
                </div>
              </div>
              <div className="mx-3">
                <img src={SwapLeftRightIcon} onClick={handleSwapPrices}
                     className="rotate-animation pointer-cursor mt-0" />
              </div>
              <div>
                <div className="input-wrapper referral_link_input">
                  <div className="USD_wrapper">
                    <input
                      type="number"
                      value={swapConditions.price === undefined ? "" :
                        Number(alterPrice ? alterPrice : swapConditions.price).toFixed(12).replace(/\.?0+$/, "")}
                      onChange={(e) =>
                        setAlterPrice(e.target.value.length > 0 ? e.target.value : isTokenAlternate ? 1 / swapConditions.price : swapConditions.price)
                      }
                    />
                  </div>
                  <span
                    className="me-2">{isTokenAlternate ? swapConditions.selectedFrom.value : swapConditions.selectedTo.value}</span>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end align-items-center mt-2">
              <div className="d-flex gap-2">
                <PlButton
                  onClick={setMarketPrice}
                  type={"percentageButton"}
                  label={"Set To Market Price"}
                />
              </div>
            </div>

            <div className="mt-4">
              <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                Price{" "}
                <span className="fw-600">
                  {swapConditions?.price ? `1 ${swapConditions.selectedFrom.value} = ${Number(swapLimitPrice).toFixed(7).replace(/\.?0+$/, "")}
                  ${swapConditions.selectedTo.value}` : "-"}
                </span>
              </p>
              <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                Available Liquidity{" "}
                <span className="fw-600">
                  {swapConditions.availableTokens ?
                    `${parseFloat(Number(swapConditions.availableTokens).toFixed(5))} ${swapConditions.selectedTo?.value}`
                    : "-"}
                </span>
              </p>
              <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                Swap Fee <span className="fw-600">
                {(Number(amountIn) > 0 && swapFee > 0) ? `${(swapFee < 0.01 && swapFee > 0) ? "<0.01" : swapFee.toFixed(2)}%` : "-"}
              </span>
              </p>
              <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                Minimum Received
                <span className="fw-600">
                     {Number(minimumReceived) > 0
                       ? `${Number(minimumReceived) > 0.00001
                         ? parseFloat(Number(minimumReceived).toFixed(5))
                         : "<0.00001"} ${swapConditions.selectedTo?.value}`
                       : "-"}
                </span>
              </p>
            </div>
            <SwapButton />
          </Tab>
        </Tabs>
      </div>

      {swapConfirmModal && (
        <Confirm
          show={swapConfirmModal}
          onHide={() => setSwapConfirmModal(false)}
          modalDetails={{ ...swapConditions, swapLimitPrice, swapFee: swapFee.toFixed(2) }}
          amountIn={amountIn}
          amountOut={amountOut}
          minimumReceived={minimumReceived}
          handleSwap={handleSwap}
        />
      )}

      {showConfirmModal && (
        <CompleteTransaction
          show={showConfirmModal}
          onHide={handleCloseSuccessModal}
          orderMessage={successModalMessage}
          // swapConditions={swapConditions}
          // amountIn={amountIn}
          // amountOut={amountOut}
        />
      )}
    </>
  );
};

const FromInput = ({ swapConditions, setSwapConditions, calcSwapOutDetails, amountIn, userData, setAmountIn }) => {
  return (
    <WithTagSelect
      label={<span>From</span>}
      placeholder={"0.0"}
      className={Number(amountIn) > swapConditions.fromBalance ? "loss" : undefined}
      value={swapConditions.selectedFrom}
      inputValue={amountIn}
      onChange={(e) => setAmountIn(e.target.value)}
      onSelectChange={(val) => setSwapConditions({ ...swapConditions, selectedFrom: val })}
      optionValue={supportedTokens(userData?.balances)}
      usdValue={(0 < (calcSwapOutDetails?.prices[swapConditions.selectedFrom.value] * Number(amountIn))
        && (calcSwapOutDetails?.prices[swapConditions.selectedFrom.value] * Number(amountIn)) < 0.01)
        ? "<0.01"
        : parseFloat((calcSwapOutDetails?.prices[swapConditions.selectedFrom.value] * Number(amountIn)).toFixed(2))
      }
      selectClassName={"token-select"}
    />
  );
};

const ReceiveInput = ({ swapConditions, setSwapConditions, calcSwapOutDetails, amountOut, userData }) => {
  return (
    <div>
      <WithTagSelect
        label={<span>To</span>}
        placeholder={"0.0"}
        value={swapConditions.selectedTo}
        inputValue={Number(amountOut).toFixed(12).replace(/\.?0+$/, "")}
        readOnly={true}
        onSelectChange={(val) => setSwapConditions({ ...swapConditions, selectedTo: val })}
        optionValue={supportedTokens(userData?.balances)}
        className={`cursor_not_allowed ${Number(amountOut) > Number(swapConditions.availableTokens) ? "loss" : "profit"}`}
        usdValue={(0 < (calcSwapOutDetails?.prices[swapConditions.selectedTo.value] * Number(amountOut)) &&
          (calcSwapOutDetails?.prices[swapConditions.selectedTo.value] * Number(amountOut)) < 0.01)
          ? "<0.01"
          : parseFloat((calcSwapOutDetails?.prices[swapConditions.selectedTo.value] * Number(amountOut)).toFixed(2))
        }
        selectClassName={"token-select"}
      />
    </div>
  );
};

export default PlaceOrder;
