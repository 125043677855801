import { ReactComponent as InfoTag } from "../../../assets/images/info_outline.svg";

import { posSizeTokens, supportedTokens, tokenImages } from "../../../components/static/SupportedTokens";
import WithTagSelect from "../../../components/inputFields/WithTagSelect";
import { toolTipData } from "../../../components/static/ToolTipData";
import { orderTypes } from "../../../components/static/OrderTypes";
import Select, { components } from "react-select";
import DropdownArrowBlack from "../../../assets/images/arrow-dropdown-black.svg";
import arrowIcon from "../../../assets/images/arrow-forward-white.svg";
import RangeSlider from "../../../components/RangeSlider";
import config from "../../../config.json";

export const AllDataRows = ({
                              tradeConditions,
                              liquidationPrice,
                              swapFee,
                              leverage,
                              orderType,
                              collateralValue,
                              ownerPositions,
                              amountIn,
                              sizeChange,
                              newEntryPrice,
                              currentLiqPrice,
                            }) => {
  // console.log("allDataRow:swapFee", swapFee);
  // console.log('allDataRow:collateralValue', collateralValue);
  // console.log('allDataRow:condition', ownerPositions?.collateralValue > 0 && Number(amountIn) > 0);
  return (
    <div className="mt-4">
      <p className="swap_card_details_p d-flex justify-content-between align-items-center">
        Collateral Asset{" "}
        <span className="fw-600 d-flex align-items-center gap-1 justify-content-center">
          <img
            src={
              tokenImages[tradeConditions.selectedTradeTab === "long" ? tradeConditions.selectedTo.value : "USDT"].image
            }
            height={14}
            width={14}
          />
          {tradeConditions.selectedTradeTab === "long" ? tradeConditions.selectedTo.value : "USDT"}
        </span>
      </p>
      <p className="swap_card_details_p d-flex justify-content-between align-items-center">
        Collateral Value{" "}
        <span className="fw-600 d-flex">
          {ownerPositions?.collateralValue > 0 && Number(amountIn) > 0 && (
            <span className="me-12 opacity-50 d-flex align-items-center gap-12">
              ${parseFloat(Number(ownerPositions.collateralValue).toFixed(2))}{" "}
              <img src={arrowIcon} height={8} width={12} />
            </span>
          )}
          {(Number(amountIn) > 0 && collateralValue > 0) ? `$${collateralValue < 0.01 ? "<0.01" : parseFloat(collateralValue?.toFixed(2))} ` : "-"}
        </span>
      </p>
      <p className="swap_card_details_p d-flex justify-content-between align-items-center">
        Leverage
        <span className="fw-600 d-flex">
          {ownerPositions?.collateralValue > 0 && Number(amountIn) > 0 && (
            <span className="me-12 opacity-50 d-flex align-items-center gap-12">
              {parseFloat(Number(ownerPositions.size / ownerPositions.collateralValue).toFixed(2))}x{" "}
              <img src={arrowIcon} height={8} width={12} />
            </span>
          )}
          {ownerPositions?.collateralValue > 0 && Number(amountIn) > 0
            ? parseFloat(Number((ownerPositions.size + sizeChange) / collateralValue).toFixed(2))
            : leverage}
          x
        </span>
      </p>
      <p className="swap_card_details_p d-flex justify-content-between align-items-center">
        Entry Price{" "}
        <span className="fw-600">
          {tradeConditions.entryPrice
            ? `$${
              (orderType.value === "marketOrder" || Number(newEntryPrice) === 0)
                ? parseFloat(Number(tradeConditions.entryPrice)?.toFixed(5))
                : Number(newEntryPrice) < 0.00001 ? "<0.00001" : parseFloat(Number(newEntryPrice)?.toFixed(5))
            }`
            : "-"}
        </span>
      </p>
      <p className="swap_card_details_p d-flex justify-content-between align-items-center">
        Liquidation Price{" "}
        <span className="fw-600 d-flex">
          {Number(currentLiqPrice) > 0 && Number(amountIn) > 0 && (
            <span className="me-12 opacity-50 d-flex align-items-center gap-12">
              ${parseFloat(Number(currentLiqPrice).toFixed(2))}{" "}
              <img src={arrowIcon} height={8} width={12} />
            </span>
          )}
          {(Number(liquidationPrice) > 0 && Number(amountIn) > 0) ? `$${liquidationPrice < 0.01 ? "<0.01" : parseFloat(liquidationPrice?.toFixed(2))} ` : "-"}
        </span>
      </p>
      {tradeConditions.selectedTradeTab === "long"
        ? tradeConditions.selectedFrom.value !== tradeConditions.selectedTo.value && (
        <p className="swap_card_details_p d-flex justify-content-between align-items-center">
          Swap Fee <span className="fw-600">
          {(Number(amountIn) > 0 && swapFee > 0) ? `${swapFee < 0.01 ? "<0.01" : swapFee.toFixed(2)}%` : "-"}
        </span>
        </p>
      )
        : tradeConditions.selectedFrom.value !== "USDT" && (
        <p className="swap_card_details_p d-flex justify-content-between align-items-center">
          Swap Fee <span className="fw-600">
          {(Number(amountIn) > 0 && swapFee > 0) ? `${swapFee < 0.01 ? "<0.01" : swapFee.toFixed(2)}%` : "-"}
        </span>
        </p>
      )}
      <p className="market_info">Market Info</p>
      <p className="swap_card_details_p d-flex justify-content-between align-items-center">
        Borrow Fee
        <span className="fw-600">
          {tradeConditions.borrowFeeRate ? `${parseFloat(tradeConditions.borrowFeeRate?.toFixed(6))}% per hour` : "-"}
        </span>
      </p>
      <p className="swap_card_details_p d-flex justify-content-between align-items-center mb-0">
        Available Liquidity{" "}
        <span className="fw-600">
          {tradeConditions?.availableLiquidity
            ? `${parseFloat(Number(tradeConditions?.availableLiquidity)?.toFixed(5))} ${
              tradeConditions.selectedTradeTab === "long" ? tradeConditions.selectedTo.value : "USDT"
            }`
            : "-"}
        </span>
      </p>
    </div>
  );
};

export const OrderPrice = ({ orderType, setOrderType, tradeConditions, newEntryPrice, setNewEntryPrice }) => {
  return (
    <>
      <div>
        <label className="input_label">
          {
            <span className="d-flex align-items-center gap-1">
              Price
              {/*<InfoTag className="info_icon" data-tip={toolTipData.orderPrice} />*/}
            </span>
          }
        </label>
        <div className="input-wrapper ">
          <div className="USD_wrapper">
            <input
              type="number"
              onKeyPress={(event) => {
                if (event.charCode < 48 && event.charCode !== 46) {
                  event.preventDefault();
                }
              }}
              min="0"
              placeholder={orderType.value === "marketOrder" ? tradeConditions.entryPrice : ""}
              value={orderType.value === "limitOrder" ? Number(newEntryPrice) > 0 ? newEntryPrice : tradeConditions.entryPrice : ""}
              onChange={(e) =>
                setNewEntryPrice(e.target.value.length > 0 ? e.target.value : tradeConditions.entryPrice)
              }
              className={`${orderType.value === "marketOrder" && "cursor_not_allowed"}`}
            />
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <span
              onClick={() => setOrderType(orderTypes[0])}
              className={`order_type_options ${orderType.value === "marketOrder" ? "selected_type" : undefined}`}
            >
              Market
            </span>
            <span
              onClick={() => setOrderType(orderTypes[1])}
              className={`order_type_options ${orderType.value === "limitOrder" ? "selected_type" : undefined}`}
            >
              Limit
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export const LeverageComp = ({ leverage, setLeverage }) => {
  return (
    <div className="mt-3">
      <div className="d-flex justify-content-between">
        <label className="range_span" htmlFor="tempB">
          <span className="d-flex align-items-center gap-1">
            Leverage
            {/*<InfoTag className="info_icon" data-tip={toolTipData.leverage} />*/}
          </span>
        </label>
        <span className="range_data">{leverage}x</span>
      </div>

      <RangeSlider
        min={2}
        max={config.maxLeverage}
        value={leverage}
        setValue={setLeverage}
        tags={[10, 20, 30, 40, 50]}
      />
    </div>
  );
};

export const PositionSize = ({ ownerPositions, amountIn, sizeChange }) => {
  return (
    <p className="position_size_data d-flex justify-content-between align-items-center mt-4">
      Position Size
      <span className="fw-600 d-flex">
        {ownerPositions?.size > 0 && Number(amountIn) > 0 && (
          <span className="me-12 opacity-50 d-flex align-items-center gap-12">
            ${parseFloat(Number(ownerPositions.size).toFixed(2))}{" "}
            <img src={arrowIcon} height={10} width={14} />
          </span>
        )}
        {/*${Number(amountIn) > 0 ? parseFloat(Number(sizeChange).toFixed(3)) : "0"}{" "}*/}
        ${Number(amountIn) > 0 ? parseFloat((Number(sizeChange)
        + Number(ownerPositions ? ownerPositions.size : 0)).toFixed(3)) : "0"}{" "}
      </span>
    </p>
  );
};

export const IndexTokenSelect = ({ tradeConditions, setTradeConditions }) => {
  return (
    <div className="tag-select token_select_tag d-flex align-items-center justify-content-center gap-3">
      <span className="trade_index">Index</span>
      <Select
        styles={{
          control: (base) => ({
            ...base,
            height: 24,
            minHeight: 24,
          }),
        }}
        defaultValue={posSizeTokens[0]}
        value={tradeConditions.selectedTo}
        onChange={(val) => setTradeConditions({ ...tradeConditions, selectedTo: val })}
        options={posSizeTokens}
        isClearable={false}
        hideSelectedOptions
        classNamePrefix="select select-custom"
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: (props) => (
            <components.DropdownIndicator {...props}>
              <img src={DropdownArrowBlack} alt="icon" className="icon" />
            </components.DropdownIndicator>
          ),
        }}
      />
    </div>
  );
};

export const AmountDetails = ({ amountIn, setAmountIn, userData, setTradeConditions, tradeConditions }) => {
  return (
    <div className="mt-3 position-relative">
      <WithTagSelect
        label={
          <span className="d-flex align-items-center gap-1">
            Pay
            {/*<InfoTag className="info_icon" data-tip={toolTipData.orderPay} />*/}
          </span>
        }
        className={Number(amountIn) > tradeConditions.tokenBalance ? "loss" : undefined}
        placeholder={"0.0"}
        inputValue={amountIn}
        onChange={(e) => setAmountIn(e.target.value)}
        value={tradeConditions.selectedFrom}
        onSelectChange={(val) => setTradeConditions({ ...tradeConditions, selectedFrom: val })}
        optionValue={supportedTokens(userData?.balances)}
        // usdValue={parseFloat((Number(amountIn) * Number(tradeConditions.TokenAPrice)).toFixed(2))}
        usdValue={(0 < (Number(amountIn) * Number(tradeConditions.TokenAPrice)) &&
          (Number(amountIn) * Number(tradeConditions.TokenAPrice)) < 0.01)
          ? "<0.01"
          : parseFloat((Number(amountIn) * Number(tradeConditions.TokenAPrice)).toFixed(2))
        }
        selectClassName={"token-select"}
      />
    </div>
  );
};
