// Will mostly have only UI component imports and placement in this file.

import { Card, Col, Row, Tab, Tabs } from "react-bootstrap";

import icon from "../../assets/images/profileIcon.svg";

import GlobalMetrics from "./cards/GlobalMetrics";
import Trading from "./cards/Trading";
import Swap from "./cards/Swap";
import RewardsHistory from "./cards/RewardsHistory";
import AnnouncementBanner from "./cards/AnnouncementBanner";
import AllocationHistory from "./cards/AllocationHistory";
import ReferralActivity from "./cards/ReferralActivity";

const Page = ({ metricsData, loyaltyData, rewardHistoryData,account }) => {
  return (
    <>
      <Row>
        <h1 className="page_title d-flex align-items-center justify-content-start">
          <img src={icon} alt="" />
          MY PROFILE
        </h1>
        <p className="profile_wallet-address">{account}</p>
        <Tabs
          defaultActiveKey="overview"
          id="uncontrolled-tab-example"
          className="mb-4 mt-4 swap-details-tabs auction_page_tabs"
        >
          <Tab eventKey="overview" title="OVERVIEW">
            <GlobalMetrics metricsData={metricsData} />
            <Trading />
            <Swap />
          </Tab>

          <Tab eventKey="loyalty" title="LOYALTY">
            <AnnouncementBanner />
            <div className="trading-section mt-5">
              <Row xs={1} md={4} className="cards-deatils ">
                {loyaltyData.map((item, id) => (
                  <Col key={id}>
                    <Card className="shadowed-box p-0">
                      <Card.Body>
                        <div>
                          <p className="mb-2">{item.title}</p>
                          <span>{item.value}</span>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
            <RewardsHistory rewardHistoryData={rewardHistoryData} />
          </Tab>

          <Tab eventKey="referral" title="REFERRAL">
            <AnnouncementBanner />
            <ReferralActivity />
            <AllocationHistory />
          </Tab>
        </Tabs>
      </Row>
    </>
  );
};

export default Page;
