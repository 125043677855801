import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useMetaMask } from "metamask-react";
import { ethers } from "ethers";
import { Tab, Tabs } from "react-bootstrap";
import configs from "../config.json";
import loader from "../assets/images/loader.gif";

import { GET_TRADE_HISTORIES } from "../io/subgraph";
import {
  getPositionData,
} from "../io/kava";

import CompleteTransaction from "./modals/CompleteTransaction";

import HistoryTab from "./tradeTabs/HistoryTab";
import OrdersTab from "./tradeTabs/OrdersTab";
import PositionsTab from "./tradeTabs/PositionsTab";
import { useWeb3ConnectContext } from "../hooks/web3ConnectContext";
import loaderSvg from "../assets/images/icons8-refresh.svg";
import config from "../config.json";
import { _calcPnL, _getLiquidationPrice, _accrueInterest } from "./CalcOutPut";
import { useCommonStateContext } from "../hooks/commonStateContext";

const TradeDetail = () => {
  const { chainId, account } = useMetaMask();
  const { accountBalance } = useWeb3ConnectContext();
  const [orderMessage, setOrderMessage] = useState("");
  const [dataFetching, setDataFetching] = useState(false);
  const [isRefreshOn, setIsRefreshOn] = useState(false);
  const [completeTransactionModal, setCompleteTransactionModal] = useState(false);
  const { calcSwapOutDetails } = useCommonStateContext();

  //to set all trade orders from subgraph
  const [tableData, setTableData] = useState({});

  const [orderHistory, { refetch }] = useLazyQuery(GET_TRADE_HISTORIES, {
    variables: { owner: account, skip: 0, first: 1000 },
  });
  // console.log('calcSwapOutDetails--',calcSwapOutDetails);
  useEffect(() => {
    if (accountBalance !== undefined && calcSwapOutDetails !== undefined && account !== null) {
      fetchOrderDetails();
    }
  }, [account, accountBalance, chainId]);

  const fetchOrderDetails = async () => {
    setDataFetching(Object.entries(tableData).length === 0);
    const { data } = await orderHistory();
    const unixTime = (time) => {
      var u = new Date(time * 1000);
      return u.toLocaleString();
    };

    let history = [];
    data?.histories?.map((order) => {
      if (order.updateType !== "SWAP") {

        let updatedOrder = {
          ...order,
          collateralAsset: configs.tokensType[order.collateralToken.substring(1)],
          indexAsset: configs.tokensType[order.market.indexToken.id.substring(1)],
          priceTrigger: order.triggerPrice === null ? 0 : ethers.utils.formatEther(order.triggerPrice),
          sizeChangeValue: order.size === null ? 0 : ethers.utils.formatUnits(order.size, 30),
          collateral: order.collateralValue === null ? 0 : ethers.utils.formatUnits(order.collateralValue, 30),
          dateTime: unixTime(order.createdAtTimestamp),
        };

        history.push(updatedOrder);
      }
    });

    let ordersData = [];
    for (let i = 0; i < data?.orders.length; i++) {
      if (data?.orders[i].updateType !== "SWAP" && data?.orders[i].status === "OPEN") {

        const updatedOrder = {
          ...data?.orders[i],
          collateralAsset: configs.tokensType[data?.orders[i].collateralToken.substring(1)],
          collateral: ethers.utils.formatUnits(data?.orders[i].collateralValue, 30),
          indexAsset: configs.tokensType[data?.orders[i].market.indexToken.id.substring(1)],
          payAsset: data?.orders[i].payToken === configs.subgraphNativeAddress
            ? configs.nativeToken : configs.tokensType[data?.orders[i].payToken.substring(1)],
          priceTrigger: ethers.utils.formatUnits(data?.orders[i].price, 12),
          sizeChangeValue: ethers.utils.formatUnits(data?.orders[i]?.sizeChange, 30),
          marketPrice: calcSwapOutDetails.prices[
            configs.tokensType[data?.orders[i].market.indexToken.id.substring(1).toLowerCase()]
            ],
        };
        ordersData.push(updatedOrder);
      }
    }

    let openPositions = [];
    for (let i = 0; i < data?.positions.length; i++) {
      if (data?.positions[i].status === "OPEN") {

        let updatedOrder = {
          ...data?.positions[i],
          collateralAsset: configs.tokensType[data?.positions[i].collateralToken.substring(1)],
          indexAsset: configs.tokensType[data?.positions[i].market.indexToken.id.substring(1)],
          priceTrigger: ethers.utils.formatUnits(data?.positions[i].entryPrice, 12),
          collateral: ethers.utils.formatUnits(data?.positions[i].collateralValue, 30),
          sizeChangeValue: ethers.utils.formatUnits(data?.positions[i].size, 30),
          leverage: ethers.utils.formatUnits(data?.positions[i].leverage, 30),
          marketPrice: calcSwapOutDetails.prices[
            configs.tokensType[data?.positions[i].market.indexToken.id.substring(1)]
            ],
          side: (data?.positions[i].side === 0 || data?.positions[i].side === "LONG") ? 0 : 1,
        };

        let borrowIndex = _accrueInterest(
          calcSwapOutDetails.poolTokenInfo[updatedOrder.collateralAsset],
          calcSwapOutDetails.assetInfo[updatedOrder.collateralAsset],
          calcSwapOutDetails.interestRate[updatedOrder.collateralAsset],
          calcSwapOutDetails.accrualInterval
        );

        let ownerPosition = await getPositionData(account, updatedOrder.indexAsset, updatedOrder.side);

        //let borrowFeeValue = ((calcSwapOutDetails.poolTokenInfo[updatedOrder.collateralAsset].borrowIndex
        //  - ownerPosition.borrowIndex)
        //  * ownerPosition.size) / config.PRECISION;
        let borrowFeeValue = ((borrowIndex - ownerPosition.borrowIndex) * ownerPosition.size) / config.PRECISION;
        let closeFeeValue = updatedOrder.sizeChangeValue *
          calcSwapOutDetails.fees.positionFee / config.PRECISION;
        let totalFeeValue = borrowFeeValue + closeFeeValue + calcSwapOutDetails.fees.liquidationFee;
        // console.log("totalFeeValue-", totalFeeValue);
        let liquidationPrice = _getLiquidationPrice(
          updatedOrder.side,
          updatedOrder.priceTrigger,
          updatedOrder.collateral,
          updatedOrder.sizeChangeValue,
          totalFeeValue
        );
        let pnl = _calcPnL(
          updatedOrder.side,
          updatedOrder.sizeChangeValue,
          ownerPosition.entryPrice,
          updatedOrder.marketPrice
        );

        // console.log('remainPnL in tab-',pnl);
        updatedOrder = {
          ...updatedOrder,
          ownerPosition,
          closeFee: closeFeeValue,
          estimatedPnl: pnl,
          liquidationPrice,
          borrowFee: borrowFeeValue,
          netValue: Number(updatedOrder.collateral) + pnl - closeFeeValue - borrowFeeValue,
        };

        openPositions.push(updatedOrder);

      }
    }

    ordersData = ordersData.sort((a, b) => b.id - a.id);
    history = history.sort((a, b) => b.createdAtTimestamp - a.createdAtTimestamp);
    openPositions = openPositions.sort((a, b) => b.createdAtTimestamp - a.createdAtTimestamp);

    setTableData({
      allOrders: ordersData,
      allHistory: history,
      allPositions: openPositions,
    });
    setDataFetching(false);
    setIsRefreshOn(false);
  };

  useEffect(() => {
    if (calcSwapOutDetails !== undefined) {
      handleRefresh();
    }
  }, [calcSwapOutDetails]);

  const handleRefresh = async () => {
    if (account !== null) {
      setIsRefreshOn(true);
      await refetch();
      await fetchOrderDetails();
    }
  };

  const handleCloseSuccessModal = () => {
    setCompleteTransactionModal(false);
    handleRefresh();
  };

  return (
    <div className="shadowed-box order-details-wrappper position-relative">
       <span
         className="d-flex align-items-center justify-content-center gap-1 refresh_btn position-absolute pointer-cursor"
         onClick={handleRefresh}><img className={`${isRefreshOn ? "rotate" : undefined}`} src={loaderSvg} height={16}
                                      width={16} /></span>

      <Tabs
        defaultActiveKey="positions"
        id="uncontrolled-tab-example"
        className="trade-details-tabs swap-details-tabs"
      >
        <Tab eventKey="positions" title="Positions">
          {dataFetching ? (
            <div className="trade_history_loader w-100">
              <div className="loading">Loading</div>
            </div>
          ) : (
            <PositionsTab tableData={tableData}
                          setCompleteTransactionModal={setCompleteTransactionModal}
                          setOrderMessage={setOrderMessage} />
          )}
        </Tab>

        <Tab eventKey="orders" title="Orders">
          {dataFetching ? (
            <div className="trade_history_loader w-100">
              <div className="loading">Loading</div>
            </div>
          ) : (
            <OrdersTab tableData={tableData}
                       setCompleteTransactionModal={setCompleteTransactionModal}
                       setOrderMessage={setOrderMessage} />
          )}
        </Tab>

        <Tab eventKey="history" title="History">
          {dataFetching ? (
            <div className="trade_history_loader w-100">
              <div className="loading">Loading</div>
            </div>
          ) : (
            <HistoryTab tableData={tableData} />
          )}
        </Tab>

      </Tabs>

      {completeTransactionModal && (
        <CompleteTransaction
          orderMessage={orderMessage}
          show={completeTransactionModal}
          onHide={handleCloseSuccessModal}
        />
      )}

    </div>
  );
};

export default TradeDetail;
