import { Row, Modal } from "react-bootstrap";

import closeIcon from "../../../assets/images/modal-close-icon.svg";
import downArrow from "../../../assets/images/arrow_down.svg";

const Details = (props) => {
  const { show: data, onHide } = props;

  return (
    <Modal {...props} size="lg" centered dialogClassName="auction-modal" show={data}>
      <Modal.Body>
        <div className="close-icon" onClick={onHide}>
          <img src={closeIcon} />
        </div>
        <div className="modal-title mb-3">
          <h3>AUCTION DETAILS</h3>
        </div>
        <div className="mt-4">
          <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
            Total Supply{" "}
            <span className="fw-600">
              {data.totalSupply} {data.auctionAsset?.toUpperCase()}
            </span>
          </p>
          <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
            Starting Price
            <span className="fw-600 ">
              {data.startPrice} {data.payToken?.toUpperCase()}
            </span>
          </p>
          <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
            Floor Price
            <span className="fw-600 ">
              {data.floorPrice} {data.payToken?.toUpperCase()}
            </span>
          </p>
          <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
            Auction Price
            <span className="fw-600 ">
              {data.tokenPrice} {data.payToken?.toUpperCase()}
            </span>
          </p>
          <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
            Amount Raised
            <span className="fw-600 liquidity-value">
              {data.totalRaised} {data.payToken?.toUpperCase()}
            </span>
          </p>
        </div>
        <Row className="participants_box mx-0">
          <p className="ps-0">Participants (NA)</p>
          <div className="participants_details px-0">
            <div className="d-flex align-items-center header_div justify-content-center">
              {data.columnTitle?.map((item, i) => (
                <span>
                  {item.title} {item.title !== "Wallet" && <img height={8} width={8} src={downArrow} />}{" "}
                </span>
              ))}
            </div>
            <div className="auction-details-wrapper">
              {data.coulumnData?.map((item, i) => (
                <div className="d-flex align-items-center header_div justify-content-center">
                  <span>{item.wallet}</span>
                  <span className="font_bold">{item.spent}</span>
                  <span className="font_bold">{item.time}</span>
                </div>
              ))}
            </div>
          </div>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default Details;
