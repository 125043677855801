import HomeContainer from "../../pages/home/Container";
import DashboardContainer from "../../pages/dashboard/Container";
import TradeContainer from "../../pages/trade/Container";
import SwapContainer from "../../pages/swap/Container";
import EarnContainer from "../../pages/earn/Container";
import LiquidityContainer from "../../pages/liquidity/Container";
import LiquidityLampContainer from "../../pages/liquidityLamp/Container";
import LiquidityTrancheContainer from "../../pages/liquidityTranche/Container";
import AuctionContainer from "../../pages/auction/Container";
import DaoContainer from "../../pages/dao/Container";
import ProfileContainer from "../../pages/profile/Container";
import AnalyticsContainer from "../../pages/analytics/Container";
import LeaderboardContainer from "../../pages/leaderboard/Container";
import NotFound from "../../pages/NotFound";

export const AllRoutes = [
  {
    path: "/",
    Component: HomeContainer,
    isPrivate: false,
  },
  {
    path: "/dashboard",
    Component: DashboardContainer,
    isPrivate: true,
  },
  {
    path: "/trade",
    Component: TradeContainer,
    isPrivate: true,
  },
  {
    path: "/swap",
    Component: SwapContainer,
    isPrivate: true,
  },
  {
    path: "/earn",
    Component: EarnContainer,
    isPrivate: true,
  },
  {
    path: "/liquidity",
    Component: LiquidityContainer,
    isPrivate: true,
  },
  {
    path: "/liquidity/lamp",
    Component: LiquidityLampContainer,
    isPrivate: true,
  },
  {
    path: "/liquidity/:name",
    Component: LiquidityTrancheContainer,
    isPrivate: true,
  },
  {
    path: "/auction",
    Component: AuctionContainer,
    isPrivate: true,
  },
  {
    path: "/dao",
    Component: DaoContainer,
    isPrivate: true,
  },
  {
    path: "/profile",
    Component: ProfileContainer,
    isPrivate: true,
  },
  {
    path: "/analytics",
    Component: AnalyticsContainer,
    isPrivate: true,
  },
  {
    path: "/leaderboard",
    Component: LeaderboardContainer,
    isPrivate: true,
  },
  {
    path: "*",
    Component: NotFound,
    isPrivate: false,
  },
];
