// Will mostly have only UI component imports and placement in this file.

import { Card, Col, Row } from "react-bootstrap";

const GlobalMetrics = ({ metricsData }) => {
  return (
    <>
      <div className="trading-section mt-3">
        <Row xs={1} md={4} className="cards-deatils ">
          {metricsData.map((item, id) => (
            <Col key={id}>
              <Card className="shadowed-box p-0">
                <Card.Body>
                  <div>
                    <p className="mb-2">{item.title}</p>
                    <span>{item.value}</span>
                  </div>
                  {item.img}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default GlobalMetrics;
