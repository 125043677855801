// Will mostly have only UI component imports and placement in this file.

import { Row } from "react-bootstrap";

const AnnouncementBanner = () => {
  return (
    <>
      <div className="trading-section mt-3">
        <Row className="shadowed-box bordered w-100 mx-0">
          <p className="reward_distribute p-0 m-0 text-center">
            Next Rewards Distribution in
            <span className="time_counter">1 Day : 14 Hours : 24 Minutes : 17 Seconds</span>
            <span>( Mon, Apr 13 2023, 7:00 AM IST )</span>
          </p>
        </Row>
      </div>
    </>
  );
};

export default AnnouncementBanner;
