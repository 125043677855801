import PlButton from "./buttons/Button";


const RangeSlider = ({ min, max, tags, value, setValue }) => {

  const calculatePercentage = (val) => ((val - min) / (max - min)) * 100;

  const getLinearGradient = () => {
    const percentage = (value - min) / (max - min) * 100;
    return `linear-gradient(to right,  #FFD000 0%,  #FFD000 ${percentage}%, #000000 ${percentage}%, #000000 100%)`;
  };

  const trackStyle = {
    background: getLinearGradient(),
  };


  return (
    <div className="progress-bar-container">
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={(e) => setValue(parseInt(e.target.value))}
        className="form-range"
        style={trackStyle}
      />
      <div className="d-flex align-items-center justify-content-end gap-2">
        {tags.map((tag) => (
          <div
            key={tag}
            className="pointer-cursor"
            style={{ left: `${calculatePercentage(tag)}%` }}
            onClick={()=>setValue(tag)}
          >
            <PlButton
              // onClick={() => setSwapLimitPrice(Number(swapConditions?.price))}
              type={"percentageButton"}
              label={`${tag}x`}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default RangeSlider;