import { useState } from "react";

import DetailsModal from "../modals/Details";
import AuctionModal from "../modals/Auction";
import PlButton from "../../../components/buttons/Button";

import { convertDate, getRelativeTime } from "../../../common";

const Auction = ({ data, type }) => {
  const [auctionDetailModal, setAuctionDetailModal] = useState(false);
  const [lampAuctionModal, setLampAuctionModal] = useState(false);

  const handleModalOpen = () => {
    if (type === "Finished") {
      setAuctionDetailModal(data);
    } else {
      setLampAuctionModal(data);
    }
  };

  const auctionDetailData = {
    columnTitle: [
      { title: "Wallet", className: "ps-0", md: 4 },
      { title: "Spent", className: "ps-0", md: 3 },
      { title: "Time", className: "ps-0", md: 5 },
    ],
    coulumnData: [
      {
        wallet: "0x123...abc02",
        spent: "100 USDT",
        time: "27 Mar 2023, 12:30 PM IST",
      },
      {
        wallet: "0x123...abc02",
        spent: "100 USDT",
        time: "27 Mar 2023, 12:30 PM IST",
      },
      {
        wallet: "0x123...abc02",
        spent: "100 USDT",
        time: "27 Mar 2023, 12:30 PM IST",
      },
      {
        wallet: "0x123...abc02",
        spent: "100 USDT",
        time: "27 Mar 2023, 12:30 PM IST",
      },
      {
        wallet: "0x123...abc02",
        spent: "100 USDT",
        time: "27 Mar 2023, 12:30 PM IST",
      },
      {
        wallet: "0x123...abc02",
        spent: "100 USDT",
        time: "27 Mar 2023, 12:30 PM IST",
      },
    ],
  };

  return (
    <>
      <div className="shadowed-box">
        <div className="d-flex align-items-center">
          <div className="earn-heading auction_card"></div>
          <div className="auction-heading-text justify-content-center">
            <p className="mb-0">{data.auctionAsset} AUCTION</p>
          </div>
          <PlButton
            type={"percentageButton"}
            className="auction_type_tag"
            label={
              type === "Live"
                ? "Live"
                : type === "Upcomming"
                ? `Starts in ${getRelativeTime(data.startTime)}`
                : `Ended ${getRelativeTime(data.endTime)}`
            }
          />
        </div>

        <div className="mt-4">
          <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
            Auction Asset <span className="fw-600">{data.auctionAsset?.toUpperCase()}</span>
          </p>
          <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
            Total Supply{" "}
            <span className="fw-600 liquidity-value">
              {data.totalSupply} {data.auctionAsset?.toUpperCase()}
            </span>
          </p>
          <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
            Currency <span className="fw-600 ">{data.payToken?.toUpperCase()}</span>
          </p>
          <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
            Method<span className="fw-600">{data.method}</span>
          </p>
          <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
            Starting Price
            <span className="fw-600 ">
              {data.startPrice} {data.payToken?.toUpperCase()}
            </span>
          </p>
          <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
            Starts<span className="fw-600 ">{convertDate(data.startTime)}</span>
          </p>
          <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
            Ends<span className="fw-600 ">{convertDate(data.endTime)}</span>
          </p>
          <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
            Vesting<span className="fw-600 ">{data.vesting || "-"}</span>
          </p>
        </div>

        <div className="earn-deposit-button mt-4">
          <PlButton
            label={type === "Finished" ? "Auction Details" : "Go to Auction"}
            disabled={type === "Upcomming"}
            onClick={() => {
              handleModalOpen();
            }}
          />
        </div>
      </div>

      {auctionDetailModal && <DetailsModal show={auctionDetailModal} onHide={() => setAuctionDetailModal(false)} />}
      {lampAuctionModal && <AuctionModal show={lampAuctionModal} onHide={() => setLampAuctionModal(false)} />}
    </>
  );
};

export default Auction;
