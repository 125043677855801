// all the business logic will go here in container
// the page will be passed with all the props needed inside

import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import Page from "./Page";
import configs from "../../config.json";

import { ReactComponent as TotalValue } from "../../assets/images/totallocked.svg";
import { ReactComponent as TotalTrade } from "../../assets/images/totaltrading.svg";
import { ReactComponent as AccruedFee } from "../../assets/images/accruedfee.svg";

import {
  GET_BLOCK,
  TRADE_VOLUME,
  TRAILING_PROFIT,
  ACCRUED_FEE,
  GET_CHART_PRICE, TRADE_SWAP_VOLUME,
} from "../../io/subgraph";
import { useCommonStateContext } from "../../hooks/commonStateContext";
import Loader from "../../components/loader/Loader";
import { useWeb3ConnectContext } from "../../hooks/web3ConnectContext";
import { useMetaMask } from "metamask-react";
import {
  depositedGovernance,
  depositedPlatform,
  trancheSupply,
  getCalcOutData,
} from "../../io/kava";
import { timeConditions } from "../../components/static/TimeOptions";
import { ethers } from "ethers";

const liquidityData = [
  { title: "Senior Tranche", type: "senior", risk: "Low Risk", classname: "risk lowRisk" },
  { title: "Mezzanine Tranche", type: "mezzanine", risk: "Moderate Risk", classname: "risk moderateRisk" },
  { title: "Junior Tranche", type: "junior", risk: "High Risk", classname: "risk highRisk" },
];

const tradingData = {
  columnTitle: [
    { title: "Pair", className: "" },
    { title: "Last Price", className: "" },
    { title: "24h Change", className: "" },
    { title: "24h High", className: "" },
    { title: "24h Low", className: "" },
    { title: "24h Volume", className: "" },
    { title: "24h Volume (USD)", className: "" },
    { title: "", className: "" },
  ],
};

const Container = () => {
  const [tradingDetails, setTradingDetails] = useState([]);
  const [earnDetails, setEarnDetails] = useState({ totalLgo: 0, totalLvl: 0 });
  const [liquidityDetails, setLiquidityDetails] = useState([]);
  const [trancheAprDetails, setTrancheAprDetails] = useState();
  const [metricsDetails, setMetricsDetails] = useState();

  const { setLoading, loading, setCalcSwapOutDetails } = useCommonStateContext();
  const { chainId, account } = useMetaMask();

  const [stats, { refetch }] = useLazyQuery(TRADE_VOLUME, { context: { clientName: "analytics" } });
  const [tradeSwapVolume, { refetch: volumeRefetch }] = useLazyQuery(TRADE_SWAP_VOLUME, {
    context: { clientName: "analytics" },
    variables: { period: "total" },
  });
  const [blocks, { refetch: blockRefetch }] = useLazyQuery(GET_BLOCK, { context: { clientName: "analytics" } });
  const [tranchePrice] = useLazyQuery(TRAILING_PROFIT);
  const [accruedFees] = useLazyQuery(ACCRUED_FEE, { context: { clientName: "analytics" } });
  const [takeQuery] = useLazyQuery(GET_CHART_PRICE);

  const metricsData = [
    {
      title: "Total Value Locked",
      value: metricsDetails?.totalValueLocked === undefined ? "-" : metricsDetails.totalValueLocked,
      img: <TotalValue />,
    },
    {
      title: "Total Trading Volume",
      value: metricsDetails?.totalTradingVolume === undefined ? "-" : metricsDetails.totalTradingVolume,
      img: <TotalTrade />,
    },
    {
      title: "Accrued Fees",
      value: metricsDetails?.accruedFeeValue === undefined ? "-" : metricsDetails.accruedFeeValue,
      img: <AccruedFee />,
    },
  ];

  const earnData = [
    { title: "LAMP Gov Staking", deposited: earnDetails?.totalLgo },
    { title: "LAMP Staking", deposited: earnDetails?.totalLvl },
  ];

  useEffect(() => {
    getAllDetails();
  }, [account, chainId]);

  //for get trading pairs , metricsDetails, apr details for liquidity tranches and earn details
  const getAllDetails = async () => {
    setLoading(true);

    try {
      const { data: feeData } = await accruedFees();
      let totalFee = 0;
      feeData.tranches.map((tranche) => {
        totalFee += Number(tranche.totalFeeValue);
      });
      await volumeRefetch();
      const { data: totalVolumeData } = await tradeSwapVolume();
      let totalTradingVolume = totalVolumeData.volumeStats[0].trading;

      const trancheProfits = {};
      for (let i = 0; i < liquidityData.length; i++) {
        const { data: profitData } = await tranchePrice({
          context: { clientName: "analytics" },
          variables: { tranche: configs.tranches[liquidityData[i].type] },
        });
        trancheProfits[liquidityData[i].type] = profitData.trancheStats;
      }
      console.log("trancheProfits--", trancheProfits);
      const liqUpData = [];
      liquidityData.map((elem) => {
        let sum = 0;
        if (trancheProfits[elem.type].length > 0) {
          trancheProfits[elem.type].map((val) => {
            sum += Number(val.totalPnL);
          });
          let feeCount = Number(trancheProfits[elem.type][0].totalFeeValue) - Number(trancheProfits[elem.type][trancheProfits[elem.type].length - 1].totalFeeValue);
          console.log("sum--", sum);
          console.log("feeCount--", feeCount);
          sum += feeCount;
        }
        console.log("sum--out", sum);
        liqUpData.push({
          ...elem,
          profit: sum,
        });
      });
      setLiquidityDetails(liqUpData);

      const currentTime = Date.now() / 1000;
      let tokenPrices = {};
      for (let i = 0; i < configs.supportedTokens.length; i++) {
        if (configs.supportedTokens[i] !== configs.stableCoin) {
          const { data: tokenPriceData } = await takeQuery({
            variables: {
              start: Math.round(currentTime - timeConditions["24H"].timeGap),
              end: Math.round(currentTime),
              period: timeConditions["24H"].periodName,
              from: configs.tokens[configs.supportedTokens[i]],
              to: configs.tokens[configs.supportedTokens[i]],
            },
            context: { clientName: "analytics" },
          });
          let prices = [];
          tokenPriceData.tokenFrom.map((elem) => {
            prices.push(Number(ethers.utils.formatUnits(elem.value, 30 - configs.decimals[configs.supportedTokens[i]])));
          });
          tokenPrices[configs.supportedTokens[i]] = prices;
        }
      }
      console.log("tokenPrices--", tokenPrices);
      let tokenPriceDetails = [];
      configs.supportedTokens.map((token) => {
        if (token !== configs.stableCoin) {
          tokenPriceDetails.push({
            token,
            change: tokenPrices[token].length === 0 ? 0 : tokenPrices[token][tokenPrices[token].length - 1] - tokenPrices[token][0],
            open: tokenPrices[token].length === 0 ? 0 : tokenPrices[token][0],
            high: tokenPrices[token].length === 0 ? 0 : Math.max(...tokenPrices[token]),
            low: tokenPrices[token].length === 0 ? 0 : Math.min(...tokenPrices[token]),
            close: tokenPrices[token].length === 0 ? 0 : tokenPrices[token][tokenPrices[token].length - 1],
          });
        }
      });

      await blockRefetch();
      const { data: blockData } = await blocks();

      const { data } = await stats({
        variables: {
          b24: Number(blockData._meta.block.number) - configs.blockCount,
          eth: configs.tokens.ETH,
          btc: configs.tokens.BTC,
          kava: configs.tokens.KAVA,
        },
      });

      tokenPriceDetails = tokenPriceDetails.map((priceDetails) => {

        const currentVolume = `trade${priceDetails?.token}`;
        const pastVolume = `trade${priceDetails?.token}yesterday`;

        const volumeData = {
          volume: (Number(data[currentVolume].length > 0 ? data[currentVolume][0].volume : 0)
            - Number(data[pastVolume].length > 0 ? data[pastVolume][0].volume : 0))
            / configs.divideVolume,
          usdVolume: (Number(data[currentVolume].length > 0 ? data[currentVolume][0].volumeUsd : 0)
            - Number(data[pastVolume].length > 0 ? data[pastVolume][0].volumeUsd : 0))
            / configs.divideValue,
        };

        return {
          ...priceDetails,
          ...volumeData,
          price: priceDetails.close,
          changePercentage: priceDetails.change > 0 ? (priceDetails.change / Number(priceDetails.close)) * 100 : 0,
        };
      });

      setTradingDetails(tokenPriceDetails);

      if (account !== null && chainId === configs.CHAIN_ID) {

        const totalValueLocked = await trancheSupply();
        setMetricsDetails({ totalValueLocked, totalTradingVolume, accruedFeeValue: totalFee / 1e30 });

        const poolData = await getCalcOutData();
        setCalcSwapOutDetails(poolData);

        let tranchesData = liqUpData.map((elem) => {
          elem.liquidity = poolData.trancheValues[elem.type];
          return elem;
        });

        setLiquidityDetails(tranchesData);

        // const totalLgo = await depositedGovernance();
        // const totalLvl = await depositedPlatform();
        // setEarnDetails({ totalLgo, totalLvl });
      }
    } catch (err) {
      console.log("dashboard err--", err);
    }

    setLoading(false);
  };

  return (
    <div className="swap container">
      <Loader loading={loading} />
      <Page
        tradingDetails={tradingDetails}
        metricsData={metricsData}
        tradingData={tradingData}
        liquidityDetails={liquidityDetails}
        earnData={earnData}
      />
    </div>
  );
};

export default Container;
