// Will mostly have only UI component imports and placement in this file.

import { Col, Row } from "react-bootstrap";

import PlButton from "../../../components/buttons/Button";
import { useState } from "react";
import AddReferral from "../modals/AddReferral";
import Invite from "../modals/Invite";

const ReferralActivity = () => {
  const [referralModal, setReferralModal] = useState(false);
  const [referralLinkModal, setReferralLinkModal] = useState(false);

  return (
    <>
      <p className="lamp_twap">
        LAMP TWAP = <span>$5.60</span>
      </p>
      <Row xs={1} md={2} className="">
        <Col className="mt-2">
          <div className="shadowed-box card-main-content">
            <div className="referral_card-title d-flex align-items-center justify-content-start">
              <p className="mb-0">REDEEM LAMP</p>
              <span>TIER 0</span>
            </div>
            <div className="mt-4">
              <p className="referral_card_p mb-2">0 RP</p>
              <p className="referral_card_p mb-2">0% Rebate</p>
              <p className="referral_card_p mb-2">
                Estimated Rewards : <span> 0 LAMP</span>
              </p>
            </div>
            <div className="modal-confirm-btn mt-3">
              <PlButton label={"Get your Referral Link"} onClick={() => setReferralLinkModal(true)} />
            </div>
          </div>
        </Col>
        <Col className="mt-2">
          <div className="shadowed-box card-main-content">
            <div className="referral_card-title d-flex align-items-center justify-content-start">
              <p className="mb-0">TRADER</p>
            </div>
            <div className="mt-4">
              <p className="referral_card_p mb-2">0 TP</p>
              <p className="referral_card_p mb-2">0% Discount</p>
              <p className="referral_card_p mb-2">
                Estimated Rewards : <span> 0 LAMP</span>
              </p>
            </div>
            <div className="modal-confirm-btn mt-3">
              <PlButton label={"Add Referral Address"} onClick={() => setReferralModal(true)} />
            </div>
          </div>
        </Col>
      </Row>

      {referralModal && <AddReferral onHide={() => setReferralModal(false)} show={referralModal} />}
      {referralLinkModal && <Invite onHide={() => setReferralLinkModal(false)} show={referralLinkModal} />}
    </>
  );
};

export default ReferralActivity;
