import React, { createContext, useContext, useEffect, useState } from "react";
import { getCalcOutData, getUserData } from "../io/kava";
import { useMetaMask } from "metamask-react";
import config from "../config.json";

const CommonStateContext = createContext();

function CommonStateContextProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [usdValue, setUsdValue] = useState({ fromUsdValue: 0, toUsdValue: 0 });
  const [explorerURL, setExplorerURL] = useState("");
  const [calcSwapOutDetails, setCalcSwapOutDetails] = useState();
  const [syncStatus, setSyncStatus] = useState("synced");
  const [userData, setUserData] = useState();
  const { account, chainId, status } = useMetaMask();

  //for close last successfully message modal
  const handleCloseSuccessModal = () => {
    window.location.reload();
  };

  let intervalContract;
  let intervalUser;

  useEffect(() => {
    if(account && chainId === config.CHAIN_ID && status !== 'unavailable'){
      setLoading(true);
    }
  },[account, chainId]);

  // https://stackoverflow.com/questions/64406295/clearinterval-not-stopping-my-counter-in-useeffect
  useEffect(() => {
    if (account !== null) {

      // const interval = setInterval(async () => {
      intervalContract = setInterval(async () => {
        try {
          console.log("starting sync")
          setSyncStatus("syncing");
          const res1 = await getCalcOutData();
          const res2 = await getUserData(account);
          console.log("res1", res1)
          console.log("res2", res2)
          setCalcSwapOutDetails(res1);
          setUserData(res2);
          console.log("synced");
          //console.log("comCon:contract-", res);
          setSyncStatus("synced");
        } catch (err) {
          console.log("async", err);
          console.log("async error code", err.code);
          setSyncStatus("async");
        }
      }, 20000);
      //return () => clearInterval(interval);
    }
    return () => clearInterval(intervalContract);

  }, [account]);

  // useEffect(() => {
  //   if (account !== null) {
  //     // const interval = setInterval(async () => {
  //     intervalUser = setInterval(async () => {
  //       try {
  //         //setSyncStatus("syncing");
  //         //const res = await getUserData(account);
  //         //console.log("user data synced");
  //         //console.log("comCon:user-", res);
  //         //setUserData(res);
  //         //setSyncStatus("synced");
  //       } catch (err) {
  //         console.log("user data async", err);
  //         setSyncStatus("async");
  //       }
  //     }, 30000);
  //     // return () => clearInterval(interval);
  //   }
  //   return () => clearInterval(intervalUser);
  // }, [account]);

  return (
    <CommonStateContext.Provider
      value={{
        loading,
        setLoading,
        showConfirmModal,
        setShowConfirmModal,
        usdValue,
        setUsdValue,
        explorerURL,
        setExplorerURL,
        handleCloseSuccessModal,
        calcSwapOutDetails,
        setCalcSwapOutDetails,
        userData,
        setUserData,
        syncStatus,
      }}
    >
      {children}
    </CommonStateContext.Provider>
  );
}

export default CommonStateContextProvider;

export const useCommonStateContext = () => useContext(CommonStateContext);
