// Will mostly have only UI component imports and placement in this file.

import { Col, Row } from "react-bootstrap";

const RewardsHistory = ({ rewardHistoryData }) => {
  return (
    <>
      <div className="trading-section">
        <p className="title">Rewards History</p>
        <div className="shadowed-box max_box  mt-4">
          <div className="trading-section">
            <Row className="heading swap-content-wrapper reward_heading">
              {rewardHistoryData?.columnTitle?.map((item, i) => (
                <Col key={i} md={item?.md} className={item?.className}>
                  <p>{item?.title}</p>
                </Col>
              ))}
            </Row>
            <Row className="swap-order-wrapper">
              {rewardHistoryData.coulumnData.map((item, i) => (
                <Row className="swap-data pe-0 mb-1" key={i}>
                  <Col md={3} className="ps-0">
                    <span className="reward_data-span">{item.time}</span>
                  </Col>
                  <Col>
                    <span className="reward_data-span">{item.earned}</span>
                  </Col>
                  <Col>
                    <span className="reward_data-span">{item.rewards}</span>
                  </Col>
                  <Col className=" pe-0">
                    <span className="reward_data-span">{item.claimed}</span>
                  </Col>
                  <Col className="justify-content-start pe-0">
                    <span className="reward_data-span">{item.claimable}</span>
                  </Col>
                </Row>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default RewardsHistory;
