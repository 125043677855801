import Modal from "react-bootstrap/Modal";

import closeIcon from "../../../assets/images/modal-close-icon.svg";

import PlButton from "../../buttons/Button";
import WithTag from "../../inputFields/WithTag";
import { tokenImages } from "../../static/SupportedTokens";
import { PercentageButtons } from "../../buttons/Percentage";
import arrowIcon from "../../../assets/images/arrow-forward-white.svg";
import { SlippageRow } from "../../SlippageRow";
import { compareBigNums } from "../../HandleDecimals";
import { useMetaMask } from "metamask-react";
import config from "../../../config.json";

const Deposit = ({
                   show,
                   onHide,
                   depositOrder,
                   setDepositOrder,
                   depositAmount,
                   setDepositAmount,
                   handleDeposit,
                   handleApprove,
                 }) => {
  console.log("depositOrder-", depositOrder);
  const { account, chainId } = useMetaMask();
  return (
    <Modal size="lg" centered dialogClassName="lamp-modal claim-modal" show={show}>
      <Modal.Body>
        <div className="close-icon" onClick={onHide}>
          <img src={closeIcon} />
        </div>

        <div className="modal-title mb-4">
          <h3>DEPOSIT COLLATERAL</h3>
        </div>
        <div className="my-3">
          <WithTag
            label={""}
            placeholder={"0.0"}
            inputValue={depositAmount}
            onChange={(e) => setDepositAmount(e.target.value)}
            tag={
              <div className="d-flex align-items-center gap-1">
                <img src={tokenImages[depositOrder.collateralAsset].image} height={16} width={16} />
                {depositOrder.collateralAsset}
              </div>
            }
          />
          <div className="d-flex justify-content-between align-items-center mt-2">
            <span className="balance">BAL: {parseFloat(Number(depositOrder.balance).toFixed(3))}</span>
            <PercentageButtons setAmountIn={setDepositAmount} balance={depositOrder.balance} />
          </div>
        </div>
        <div className="mt-4 d-flex justify-content-between modal_span">
          <span>Position Size</span>
          <span className="fw-600">${parseFloat(Number(depositOrder.sizeChangeValue).toFixed(2))}</span>
        </div>
        <div className="mt-1 d-flex justify-content-between modal_span">
          <span>Collateral Asset</span>
          <span className="d-flex align-items-center gap-1 fw-600">
            <img src={tokenImages[depositOrder.collateralAsset].image} height={14} width={14} />
            {depositOrder.collateralAsset}
          </span>
        </div>
        <div className="mt-1 d-flex justify-content-between modal_span">
          <span>Collateral Value</span>
          <span className="fw-600 d-flex">
            {Number(depositAmount) > 0 && (
              <span className="me-12 opacity-50 d-flex align-items-center gap-12">
                ${parseFloat(Number(depositOrder.collateral).toFixed(2))} <img src={arrowIcon} height={10} width={14} />
              </span>
            )}
            ${parseFloat(Number(depositOrder?.newCollateralValue)?.toFixed(2))}
          </span>
        </div>
        <div className="mt-1 d-flex justify-content-between modal_span">
          <span>Net Value</span>
          <span className="fw-600 d-flex">
            {Number(depositAmount) > 0 && (
              <span className="me-12 opacity-50 d-flex align-items-center gap-12">
                ${parseFloat(Number(depositOrder.netValue).toFixed(2))} <img src={arrowIcon} height={10} width={14} />
              </span>
            )}
            ${parseFloat(Number(depositOrder?.newNetValue)?.toFixed(2))}
          </span>
        </div>
        <div className="mt-1 d-flex justify-content-between modal_span">
          <span>Leverage</span>
          <span className="fw-600 d-flex">
            {Number(depositAmount) > 0 && (
              <span className="me-12 opacity-50 d-flex align-items-center gap-12">
                {parseFloat(Number(depositOrder.leverage).toFixed(2))}X <img src={arrowIcon} height={10} width={14} />
              </span>
            )}
            {parseFloat(Number(depositOrder?.newLeverage)?.toFixed(2))}X
          </span>
        </div>
        <div className="mt-3 d-flex justify-content-between modal_span">
          <span>Liquidation Price</span>
          <span className="fw-600 d-flex">
            {Number(depositAmount) > 0 && (
              <span className="me-12 opacity-50 d-flex align-items-center gap-12">
                ${parseFloat(Number(depositOrder.liquidationPrice).toFixed(2))}
                <img src={arrowIcon} height={10} width={14} />
              </span>
            )}
            ${parseFloat(Number(depositOrder?.newLiquidationPrice)?.toFixed(2))}
          </span>
        </div>
        <div className="mt-1 d-flex justify-content-between modal_span">
          <span>Execution Fee</span>
          <span className="fw-600">{depositOrder.executionFee} KAVA</span>
        </div>
        <div className="mt-1 d-flex justify-content-between modal_span">
          <span>Borrow Fee</span>
          <span className="fw-600">${parseFloat(Number(depositOrder.borrowFee).toFixed(2))}</span>
        </div>
        <div className="mt-3 modal_span">
          <SlippageRow setAllDetails={setDepositOrder} allDetails={depositOrder} />
        </div>
        <div className="mt-3 d-flex justify-content-between modal_span">
          <span>Market Price</span>
          <span className="fw-600">${parseFloat(Number(depositOrder.marketPrice).toFixed(2))}</span>
        </div>
        <div className="mt-1 d-flex justify-content-between modal_span">
          <span>Trigger Condition</span>
          <span className="fw-600">
            Market Price {depositOrder.side === 0 ? "≤" : "≥"} $
            {depositOrder.side === 0
              ? parseFloat(
                (
                  Number(depositOrder.marketPrice) +
                  (Number(depositOrder.marketPrice) * Number(depositOrder.slippage.value)) / 100
                )?.toFixed(3),
              )
              : parseFloat(
                (
                  Number(depositOrder.marketPrice) -
                  (Number(depositOrder.marketPrice) * Number(depositOrder.slippage.value)) / 100
                )?.toFixed(3),
              )}
          </span>
        </div>
        <div className="modal-confirm-btn mt-4">
          <PlButton
            disabled={
              account === null || chainId !== config.CHAIN_ID ||
              Number(depositAmount) === 0 ||
              Number(depositOrder?.newLeverage) < 2 ||
              compareBigNums(depositAmount, depositOrder.balance)
            }
            label={
              (account === null || chainId !== config.CHAIN_ID)
                ? "DEPOSIT"
                : Number(depositAmount) === 0
                ? "enter an amount"
                : compareBigNums(depositAmount, depositOrder.balance)
                  ? "insufficient funds"
                  : Number(depositOrder?.newLeverage) < 2
                    ? "min leverage : 2x"
                    : Number(depositAmount) > Number(depositOrder.approvedBalance)
                      ? `APPROVE`
                      : `DEPOSIT`
            }
            onClick={() => {
              Number(depositAmount) > Number(depositOrder.approvedBalance) ? handleApprove() : handleDeposit();
              Number(depositAmount) < Number(depositOrder.approvedBalance) && onHide();
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Deposit;
