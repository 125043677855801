// all the business logic will go here in container
// the page will be passed with all the props needed inside

import { useEffect, useState } from "react";
import Page from "./Page";
import {
  getEarnData
} from "../../io/kava";
import { useMetaMask } from "metamask-react";

import { useCommonStateContext } from "../../hooks/commonStateContext";
import Loader from "../../components/loader/Loader";
import { useWeb3ConnectContext } from "../../hooks/web3ConnectContext";

const Container = () => {
  const { account, status } = useMetaMask();
  const { setLoading, loading } = useCommonStateContext();
  const { accountBalance } = useWeb3ConnectContext();

  // Level / LGO Token Contract Data
  const [earnData, setEarnData] = useState(null);

  // APR (Sub Graph) Data
  const [APR, setAPR] = useState(null);

  useEffect(() => {
    if (accountBalance !== undefined) {
      getData();
    }
  }, [accountBalance, account, status]);

  const getData = async () => {
    setLoading(true);

    const data = await getEarnData(account);
    setEarnData(data);
    console.log("EARN DATA", data);
    setLoading(false);
  };

  return (
    <div className="earn swap container">
      <Loader loading={loading} />
      <Page earnData={earnData}
            getData={getData}
            APR={APR}
      />
    </div>
  );
};

export default Container;
