export const convertDate = (timeStamp) => {
  return new Date(timeStamp * 1000)
    .toLocaleString("en-IN", {
      day: "2-digit",
      month: "long",
      year: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZoneName: "short",
    })
    .replace("am", "AM")
    .replace("pm", "PM");
};

const units = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
};
const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });
export const getRelativeTime = (timeStamp, d2 = new Date()) => {
  const elapsed = timeStamp * 1000 - d2;

  // "Math.abs" accounts for both "past" & "future" scenarios
  for (const u in units)
    if (Math.abs(elapsed) > units[u] || u == "second") return rtf.format(Math.round(elapsed / units[u]), u);
};
