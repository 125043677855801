// all the business logic will go here in container
// the page will be passed with all the props needed inside

import React, { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useCommonStateContext } from "../../hooks/commonStateContext";
import Loader from "../../components/loader/Loader";
import Page from "./Page";

import {
  approve,
  getCalcOutData,
  getUserData,
  limitSwap,
  marketSwap,
} from "../../io/kava";
import { supportedTokens } from "../../components/static/SupportedTokens";
import { slippageOptions } from "../../components/static/SlippageOptions";
import { waitingToast } from "../../components/toasts/Waiting";
import { successToast } from "../../components/toasts/Success";
import { handleError } from "../../components/toasts/Error";
import config from "../../config.json";
import { useWeb3ConnectContext } from "../../hooks/web3ConnectContext";
import { calcSwapAmountOut } from "../../components/CalcOutPut";

const Container = () => {
  const {
    setLoading,
    loading,
    setShowConfirmModal,
    setExplorerURL,
    calcSwapOutDetails,
    setCalcSwapOutDetails,
    userData,
    setUserData,
  } = useCommonStateContext();

  const { chainId, account, status } = useMetaMask();
  const { accountBalance } = useWeb3ConnectContext();

  //The user inputs the amount to be swapped.
  const [amountIn, setAmountIn] = useState("");

  //The user receives the swapped amount based on the "amountIn" and selected tokens.
  const [amountOut, setAmountOut] = useState("");

  // The user receives the amount according to the specified "amountOut" and slippage.
  const [minimumReceived, setMinimumReceived] = useState("");

  const [swapFee, setSwapFee] = useState(0);

  const [swapLimitPrice, setSwapLimitPrice] = useState(0);

  const [successModalMessage, setSuccessModalMessage] = useState("");

  //To store all swap conditions such as slippage, order type, token balance, and selected tokens.
  const [swapConditions, setSwapConditions] = useState({
    selectedFrom: supportedTokens(userData?.balances)[0],
    selectedTo: supportedTokens(userData?.balances)[3],
    slippage: slippageOptions[0],
    selectedSwapTab: "market",
  });

  useEffect(() => {
    fetchSwapDetails();
  }, [ account, chainId, status]);

  useEffect(() => {
    if (calcSwapOutDetails !== undefined && userData !== undefined) {
      updateSwapDetails();
    }
  }, [calcSwapOutDetails, userData, swapConditions.selectedFrom, swapConditions.selectedTo]);

  useEffect(() => {
    if (calcSwapOutDetails !== undefined) {
      setSwapLimitPrice(calcSwapOutDetails.prices[swapConditions.selectedFrom.value] / calcSwapOutDetails.prices[swapConditions.selectedTo.value]);
    }
  }, [swapConditions.selectedFrom, swapConditions.selectedTo]);

  useEffect(() => {
    if (calcSwapOutDetails !== undefined) {
      const tokenIn = swapConditions.selectedFrom.value;
      const tokenOut = swapConditions.selectedTo.value;
      let isLimit = swapConditions.selectedSwapTab === "limit";

      const { amountOutAfterFee, feePercentage } = calcSwapAmountOut(
        tokenIn,
        tokenOut,
        Number(amountIn),
        calcSwapOutDetails,
        isLimit,
        Number(swapLimitPrice),
      );

      console.log("swap:amountIn-", amountIn);
      console.log("swap:swapLimitPrice-", swapLimitPrice);
      console.log("swap:isLimit-", isLimit);
      console.log("swap:amountOutAfterFee-", amountOutAfterFee);
      console.log("swap:feePercentage-", feePercentage);

      if (Number(amountOutAfterFee) !== 0) {
        setAmountOut(Number(amountOutAfterFee));
      } else {
        setAmountOut("");
      }
      setSwapFee(feePercentage);
    }

  }, [
    swapConditions.selectedTo,
    swapConditions.selectedFrom,
    swapConditions.selectedSwapTab,
    swapConditions.price,
    swapLimitPrice,
    amountIn,
    calcSwapOutDetails,
  ]);

  // To calculate the slippage based on the selected market slippage and desired amountOut.
  useEffect(() => {
    const slippageCount = swapConditions.selectedSwapTab === "market" ?
      Number(amountOut) - (Number(amountOut) * Number(swapConditions.slippage.value)) / 100 :
      Number(amountOut);
    setMinimumReceived(slippageCount > 0 ? slippageCount : "");
  }, [amountOut, swapConditions.slippage, swapConditions.selectedSwapTab]);

  //to get all initial details of selected assets
  const fetchSwapDetails = async () => {

    if (account !== null && chainId === config.CHAIN_ID) {
      setLoading(true);
      try {
        const poolData = await getCalcOutData();
        setCalcSwapOutDetails(poolData);
        setSwapLimitPrice(
          poolData.prices[swapConditions.selectedFrom.value] / poolData.prices[swapConditions.selectedTo.value]
        );
        const userDetails = await getUserData(account);
        setUserData(userDetails);
        setLoading(false)
      }catch (err) {
        console.log('swap err--',err);
        setLoading(false)
      }
    }
    if (status === 'notConnected' || status === 'unavailable'
      || (status === 'connected' && chainId!==config.CHAIN_ID)){
      setLoading(false);
    }
  };

  const updateSwapDetails = () => {
    const price = calcSwapOutDetails.prices[swapConditions.selectedFrom.value] / calcSwapOutDetails.prices[swapConditions.selectedTo.value];
    const fromBalance = userData.balances[swapConditions.selectedFrom.value];
    const approvedAmount = userData.approvedOrderManager[swapConditions.selectedFrom.value];
    const availableTokens = calcSwapOutDetails.assetInfo[swapConditions.selectedTo.value].poolAmount - calcSwapOutDetails.assetInfo[swapConditions.selectedTo.value].reservedAmount;
    setSwapConditions({
      ...swapConditions,
      approvedBalance: Number(approvedAmount),
      price,
      availableTokens,
      fromBalance,
    });
  };

  //for reverse selected assets
  const handleSwapValue = () => {
    setSwapConditions({
      ...swapConditions,
      selectedFrom: swapConditions.selectedTo,
      selectedTo: swapConditions.selectedFrom,
    });
  };

  //to approve amount of selected asset(from)
  const handleApprove = async () => {
    setLoading(true);
    try {
      waitingToast(`Approving ${swapConditions.selectedFrom.value}`);

      await approve(swapConditions.selectedFrom.value, config.trade.orderManager, amountIn);

      successToast(`${swapConditions.selectedFrom.value} Approved!`);

    } catch (e) {
      handleError(e);
    }

    const userDetails = await getUserData(account);
    setUserData(userDetails);

    setLoading(false);
  };

  //to create a market swap order
  const handleSwap = async () => {
    setLoading(true);
    try {
      waitingToast(
        swapConditions.selectedSwapTab === "market"
          ? `Swapping ${Number(amountIn) < 0.00001 ? "<0.00001"
          : parseFloat(Number(amountIn).toFixed(5))} ${swapConditions.selectedFrom.value} for ${
            Number(amountOut) < 0.00001 ? "<0.00001" : parseFloat(Number(amountOut).toFixed(5))
          } ${swapConditions.selectedTo.value}`
          : `Creating order to swap ${Number(amountIn) < 0.00001 ? "<0.00001" : parseFloat(Number(amountIn).toFixed(5))} ${
            swapConditions.selectedFrom.value
          } for ${Number(minimumReceived) < 0.00001 ? "<0.00001" : parseFloat(Number(minimumReceived).toFixed(5))} ${
            swapConditions.selectedTo.value
          }`,
      );


      let res;
      {
        swapConditions.selectedSwapTab === "market"
          ? (res = await marketSwap(
          swapConditions.selectedFrom.value,
          swapConditions.selectedTo.value,
          amountIn,
          minimumReceived,
          amountOut,
          ))
          : (res = await limitSwap(
          swapConditions.selectedFrom.value,
          swapConditions.selectedTo.value,
          amountIn,
          minimumReceived,
          swapLimitPrice,
          ));
      }

      successToast(
        swapConditions.selectedSwapTab === "market"
          ? `Swapped ${Number(amountIn) < 0.00001 ? "<0.00001"
          : parseFloat(Number(amountIn).toFixed(5))} ${swapConditions.selectedFrom.value} for ${
            Number(amountOut) < 0.00001 ? "<0.00001" : parseFloat(Number(amountOut).toFixed(5),
            )} ${swapConditions.selectedTo.value}`
          : `Order created to swap ${Number(amountIn) < 0.00001 ? "<0.00001"
          : parseFloat(Number(amountIn).toFixed(5))} ${
            swapConditions.selectedFrom.value
          } for ${Number(minimumReceived) < 0.00001 ? "<0.00001" : parseFloat(Number(minimumReceived).toFixed(5))} ${
            swapConditions.selectedTo.value
          }`,
      );

      setSuccessModalMessage( swapConditions.selectedSwapTab === "market"
        ? `Swapped  <span>${Number(amountIn) < 0.00001 ? "<0.00001"
          : parseFloat(Number(amountIn).toFixed(5))} ${swapConditions.selectedFrom.value}</span> for  <span>${
          Number(amountOut) < 0.00001 ? "<0.00001" : parseFloat(Number(amountOut).toFixed(5),
          )} ${swapConditions.selectedTo.value}</span>`
        : `Order created to swap  <span>${Number(amountIn) < 0.00001 ? "<0.00001"
          : parseFloat(Number(amountIn).toFixed(5))} ${
          swapConditions.selectedFrom.value}</span> for <span>${Number(minimumReceived) < 0.00001 ? "<0.00001" : parseFloat(Number(minimumReceived).toFixed(5))} ${
          swapConditions.selectedTo.value}</span>`,)

      //This URL needs to be updated according to the Kava Explorer.
      setExplorerURL(`https://goerli.etherscan.io/tx/${res.hash}`);
      setShowConfirmModal(true);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  const handleCloseSuccessModal = () => {
    setShowConfirmModal(false);
    setAmountIn("");
    setAmountOut("");
    fetchSwapDetails();
  };

  return (
    <div className="swap container">
      <Loader loading={loading} />
      <Page
        swapConditions={swapConditions}
        setSwapConditions={setSwapConditions}
        amountIn={amountIn}
        setAmountIn={setAmountIn}
        amountOut={amountOut}
        minimumReceived={minimumReceived}
        handleSwapValue={handleSwapValue}
        handleApprove={handleApprove}
        handleSwap={handleSwap}
        swapFee={swapFee}
        swapLimitPrice={swapLimitPrice}
        setSwapLimitPrice={setSwapLimitPrice}
        handleCloseSuccessModal={handleCloseSuccessModal}
        successModalMessage={successModalMessage}
      />
    </div>
  );
};

export default Container;
