// Will mostly have only UI component imports and placement in this file.

import icon from "../../assets/images/leaderboardIcon.svg";
import TraderInfo from "./cards/TraderInfo";

const Page = ({ tradeData, tableData }) => {
  return (
    <>
      <h1 className="page_title dashboard_title d-flex align-items-center justify-content-start">
        <img src={icon} alt="" /> LEADERBOARD
      </h1>

      <TraderInfo tradeData={tradeData} tableData={tableData} />
    </>
  );
};

export default Page;
