import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useCommonStateContext } from "../../../hooks/commonStateContext";
import { useMetaMask } from "metamask-react";
import {
  approve,
  getUserData,
  placeOrder,
} from "../../../io/kava";
import configs from "../../../config.json";

import PlButton from "../../../components/buttons/Button";
import Confirm from "../../../components/modals/Confirm";
import CompleteTransaction from "../../../components/modals/CompleteTransaction";
import { orderTypes } from "../../../components/static/OrderTypes";
import {
  AllDataRows,
  AmountDetails,
  IndexTokenSelect,
  LeverageComp,
  OrderPrice,
  PositionSize,
} from "./commonComponents";
import { PercentageButtons } from "../../../components/buttons/Percentage";
import { SlippageRow } from "../../../components/SlippageRow";
import { waitingToast } from "../../../components/toasts/Waiting";

import { successToast } from "../../../components/toasts/Success";
import { handleError } from "../../../components/toasts/Error";
import ReactTooltip from "react-tooltip";
import { calcIncreasePosition } from "../../../components/CalcOutPut";
import { compareBigNums } from "../../../components/HandleDecimals";

const PlaceOrder = ({
                      tradeConditions,
                      setTradeConditions,
                      amountIn,
                      setAmountIn,
                      leverage,
                      setLeverage,
                      ownerPositions,
                      fetchTradeConditions,
                    }) => {
  // To display or hide the confirmation modal.
  console.log("trade conditions", tradeConditions);
  console.log("amountIn", amountIn);
  const [tradeConfirmModal, setTradeConfirmModal] = useState(false);
  const [swapFee, setSwapFee] = useState(0);
  const { account, chainId } = useMetaMask();

  const [liquidationPrice, setLiquidationPrice] = useState("");

  const [currentLiqPrice, setCurrentLiqPrice] = useState(0);

  const [positionFeeValue, setPositionFeeValue] = useState(0);

  //The user receives the swapped amount based on the amountIn, leverage, and selected token's price.
  const [amountOut, setAmountOut] = useState("");

  const [sizeChange, setSizeChange] = useState("");

  // Multiply the price of the selectedFrom token by the amountIn.
  const [collateralValue, setCollateralValue] = useState(0);

  const [newEntryPrice, setNewEntryPrice] = useState("");

  // To handle the selected order type, such as limit and market.
  const [orderType, setOrderType] = useState(orderTypes[0]);
  const {
    setUsdValue,
    showConfirmModal,
    setLoading,
    usdValue,
    setExplorerURL,
    setShowConfirmModal,
    calcSwapOutDetails,
    userData,
    setUserData,
  } = useCommonStateContext();

  //to count collateralValue,positionSize,positionFeeCount,amountOut,swapFee
  useEffect(() => {
    if (calcSwapOutDetails !== undefined && ownerPositions !== undefined && amountIn) {
      /*
      console.log('======')
      console.log('placeOrd:payToken', tradeConditions.selectedFrom.value)
      console.log('placeOrd:amountIn', amountIn)
      console.log('placeOrd:leverage', leverage)
      */
      if (Number(newEntryPrice) === 0) {
        setNewEntryPrice(calcSwapOutDetails.prices[tradeConditions.selectedTo.value]);
      }

      const {
        sizeChanged,
        newPositionSize,
        newCollateralValue,
        liquidationPrice,
        swapFeeRate,
        positionFeeValue,
        currentLiquidationPrice,
      } = calcIncreasePosition(
        account,
        tradeConditions.selectedFrom.value,
        Number(amountIn),
        leverage,
        tradeConditions.selectedTradeTab === "long" ? 0 : 1,
        tradeConditions.selectedTo.value,
        orderType.value === "limitOrder",
        Number(newEntryPrice),
        calcSwapOutDetails,
        ownerPositions,
        false,
      );

      // console.log('placeOrd:size', sizeChanged);
      // console.log('placeOrd:newSize', newPositionSize);
      // console.log('placeOrd:swapFeeRate', swapFeeRate)

      setSwapFee(swapFeeRate);
      // setSizeChange(newPositionSize > 0 ? newPositionSize : 0);
      setSizeChange(sizeChanged > 0 ? sizeChanged : 0);
      // console.log('placeOrd:collVal', newCollateralValue)
      setCollateralValue(newCollateralValue > 0 ? newCollateralValue : 0);
      setLiquidationPrice(liquidationPrice > 0 ? liquidationPrice : 0);
      setPositionFeeValue(positionFeeValue > 0 ? positionFeeValue : 0);
      setCurrentLiqPrice(currentLiquidationPrice > 0 ? currentLiquidationPrice : 0);
      console.log("newEntryPrice-", newEntryPrice);
      const amount = sizeChanged / Number(orderType.value === "marketOrder" || Number(newEntryPrice) === 0
        ? tradeConditions.entryPrice
        : newEntryPrice);

      setAmountOut(amount > 0 ? amount : 0);
    }

    // countValues();
  }, [
    amountIn,
    leverage,
    tradeConditions.TokenAPrice,
    newEntryPrice,
    tradeConditions.entryPrice,
    orderType,
    tradeConditions.selectedTradeTab,
    calcSwapOutDetails,
    ownerPositions,
  ]);


  useEffect(() => {
    if (calcSwapOutDetails !== undefined) {
      setNewEntryPrice(calcSwapOutDetails.prices[tradeConditions.selectedTo.value]);
    }
  }, [tradeConditions.selectedTo]);


  const handleShowModal = async () => {
    setTradeConfirmModal(true);

    //To calculate the USD value based on the provided amount and the selected token's price.
    let getFrom;
    let getTo;
    getFrom = Number(tradeConditions?.TokenAPrice) * Number(amountIn);
    getTo = Number(tradeConditions?.entryPrice) * Number(amountOut);

    setUsdValue({
      fromUsdValue: parseFloat(Number(getFrom).toFixed(2)),
      toUsdValue: parseFloat(Number(getTo).toFixed(2)),
    });
  };

  //to approve amount of selected asset(from)
  const handleApprove = async () => {
    setLoading(true);
    try {
      waitingToast(`Approving ${tradeConditions.selectedFrom.value}`);
      await approve(tradeConditions.selectedFrom.value, configs.trade.orderManager, amountIn);
      successToast(`${tradeConditions.selectedFrom.value} Approved!`);

    } catch (e) {
      handleError(e);
    }

    const userDetails = await getUserData(account);
    setUserData(userDetails);

    setLoading(false);
  };

  //to place order in trade
  const handleTrade = async () => {
    setLoading(true);

    try {
      const _LStab = tradeConditions.selectedTradeTab;
      const _index = tradeConditions.selectedTo;
      const _pay = tradeConditions.selectedFrom;
      const _exeFee = tradeConditions.executionFee;
      const _swapFee = tradeConditions.swapExecutionFee;
      const _posFee = tradeConditions.positionFeeRate;
      const _txExecFee = _LStab === "long"
        ? _pay !== _index
          ? Number(_exeFee) + Number(_swapFee)
          : _exeFee
        : _pay !== "USDT"
          ? Number(_exeFee) + Number(_swapFee)
          : _exeFee;

      waitingToast(
        `Requesting to Increase ${_index.value} ${_LStab.toUpperCase()} by $${parseFloat(Number(sizeChange).toFixed(3))}`,
      );
      const _triggerPrice = _LStab === "long"
        ? Number(tradeConditions.entryPrice) * (1 + Number(tradeConditions.slippage.value) / 100)
        : Number(tradeConditions.entryPrice) * (1 - Number(tradeConditions.slippage.value) / 100);

      const res = await placeOrder(
        // updateType(increase = 0 / decrease = 1)
        0,
        // side (long = 0 / short = 1)
        _LStab === "long" ? 0 : 1,
        // index token
        _index.value,
        // collateral token
        _LStab === "long" ? _index.value : "USDT",
        // orderType ( market = 0 / limit = 1)
        orderType.value === "marketOrder" ? 0 : 1,
        // order Price
        orderType.value === "marketOrder" ? _triggerPrice : newEntryPrice,
        // pay token
        _pay.value,
        //purchase / collateral amount
        amountIn,
        // sizeChange
        sizeChange,
        // position fee
        _posFee,
        // txn execution fee
        _txExecFee,
        // success message
        `Requesting to Increase ${_index.value} ${_LStab.toUpperCase()} by $${parseFloat(Number(sizeChange).toFixed(3))}`,
      );

      successToast(
        `Requested to Increase ${_index.value} ${_LStab.toUpperCase()} by $${parseFloat(Number(sizeChange).toFixed(3))}`,
      );

      //This URL needs to be updated according to the Kava Explorer.
      setExplorerURL(`https://goerli.etherscan.io/tx/${res.hash}`);
      setShowConfirmModal(true);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  const handleCloseSuccessModal = () => {
    setShowConfirmModal(false);
    setAmountIn("");
    setAmountOut("");
    fetchTradeConditions();
  };
  // console.log("newEntryPrice--", newEntryPrice);
  return (
    <>
      <div className="shadowed-box trading">
        <Tabs
          defaultActiveKey="long"
          onSelect={(k) => setTradeConditions({ ...tradeConditions, selectedTradeTab: k })}
          activeKey={tradeConditions.selectedTradeTab}
          id="uncontrolled-tab-example"
          className="mb-3 swap_card_tabs trade_tabs"
        >
          <Tab eventKey="long" title="LONG">
          </Tab>
          <Tab eventKey="short" title="SHORT">
          </Tab>
        </Tabs>
        <div className="tab-content">
          <IndexTokenSelect
            tradeConditions={tradeConditions}
            setTradeConditions={setTradeConditions}
          />
          <OrderPrice orderType={orderType}
                      setOrderType={setOrderType}
                      tradeConditions={tradeConditions}
                      newEntryPrice={newEntryPrice}
                      setNewEntryPrice={setNewEntryPrice}
          />
          <AmountDetails
            setTradeConditions={setTradeConditions}
            tradeConditions={tradeConditions}
            setAmountIn={setAmountIn}
            amountIn={amountIn}
            userData={userData}
          />

          <div className="d-flex justify-content-between align-items-center mt-2">
              <span className="balance">
                BAL: {tradeConditions?.tokenBalance ? parseFloat(Number(tradeConditions?.tokenBalance)?.toFixed(3)) : "-"}
              </span>
            <PercentageButtons setAmountIn={setAmountIn} balance={tradeConditions?.tokenBalance} />
          </div>

          <LeverageComp leverage={leverage} setLeverage={setLeverage} />

          {orderType.value === "marketOrder" &&
          <div className="mt-3">
            <SlippageRow setAllDetails={setTradeConditions} allDetails={tradeConditions} />
          </div>
          }

          <PositionSize amountIn={amountIn} sizeChange={sizeChange} ownerPositions={ownerPositions} />

          <div className="mt-4">
            <PlButton
              disabled={account === null || chainId !== configs.CHAIN_ID ||
              Number(amountIn) === 0 ||
              compareBigNums(amountIn, tradeConditions.tokenBalance) ||
              Number(collateralValue) < 5 ||
              compareBigNums(amountOut, tradeConditions.availableLiquidity)
              }
              label={
                (account === null || chainId !== configs.CHAIN_ID)
                  ? `${tradeConditions.selectedTradeTab === "long" ? "BUY" : "SELL"} ${tradeConditions.selectedTo.value}`
                  : Number(amountIn) === 0
                  ? "enter an amount"
                  : compareBigNums(amountIn, tradeConditions.tokenBalance)
                    ? "insufficient funds"
                    : compareBigNums(amountOut, tradeConditions.availableLiquidity)
                      ? "insufficient liquidity"
                      : Number(collateralValue) < 5
                        ? "MIN COLLATERAL VALUE: $5"
                        : Number(amountIn) > tradeConditions.approvedAmount
                          ? `APPROVE ${tradeConditions.selectedFrom.value}`
                          : `${tradeConditions.selectedTradeTab === "long" ? "BUY" : "SELL"} ${tradeConditions.selectedTo.value}`
              }
              onClick={Number(amountIn) > tradeConditions.approvedAmount ? handleApprove : handleShowModal}
            />
          </div>

          {/*
            (Number(collateralValue) > 5
              && ((tradeConditions.selectedTradeTab === "long"
              && liquidationPrice > calcSwapOutDetails.prices[tradeConditions.selectedTo.value])
              || (tradeConditions.selectedTradeTab === "short"
              && liquidationPrice < calcSwapOutDetails.prices[tradeConditions.selectedTo.value]))) &&
            <div className="mt-4 d-flex justify-content-center modal_span loss">
              <span>Your position will immediately get liquidated.</span>
            </div>
            */
          }

          <AllDataRows
            tradeConditions={tradeConditions}
            liquidationPrice={liquidationPrice}
            leverage={leverage}
            swapFee={swapFee}
            orderType={orderType}
            collateralValue={collateralValue}
            ownerPositions={ownerPositions}
            amountIn={amountIn}
            sizeChange={sizeChange}
            newEntryPrice={newEntryPrice}
            currentLiqPrice={currentLiqPrice}
          />
        </div>
      </div>

      {tradeConfirmModal && (
        <Confirm
          show={tradeConfirmModal}
          data={true}
          title={`${tradeConditions.selectedTradeTab.toUpperCase()} ${tradeConditions.selectedTo.value}`}
          onHide={() => setTradeConfirmModal(false)}
          orderType={orderType}
          modalDetails={{ ...tradeConditions, newEntryPrice }}
          amountIn={amountIn}
          amountOut={amountOut}
          positionFeeValue={positionFeeValue}
          handleTrade={handleTrade}
        />
      )}
      {/*<ReactTooltip backgroundColor="black" className="react_tooltip" arrowColor="black" place="top" type="dark"*/}
      {/*              effect="solid" />*/}
      {showConfirmModal && (
        <CompleteTransaction
          show={showConfirmModal}
          onHide={handleCloseSuccessModal}
          positionSize={sizeChange}
          tradeConditions={tradeConditions}
        />
      )}
    </>
  );
};

export default PlaceOrder;
