import Modal from "react-bootstrap/Modal";

import closeIcon from "../../../assets/images/modal-close-icon.svg";
import arrowIcon from "../../../assets/images/arrow-forward-white.svg";

import PlButton from "../../buttons/Button";
import WithTag from "../../inputFields/WithTag";
import { tokenImages } from "../../static/SupportedTokens";
import { PercentageButtons } from "../../buttons/Percentage";
import { SlippageRow } from "../../SlippageRow";
import warningIcon from "../../../assets/images/warning_icon.svg";

import { useMetaMask } from "metamask-react";
import config from "../../../config.json";

const Withdraw = ({
                    show,
                    onHide,
                    withdrawOrder,
                    setWithdrawOrder,
                    setWithdrawAmount,
                    withdrawAmount,
                    handleWithdraw,
                  }) => {
  //console.log("withdraw:withOrd-", withdrawOrder);
  const { account, chainId } = useMetaMask();
  return (
    <Modal size="lg" centered dialogClassName="lamp-modal claim-modal" show={show}>
      <Modal.Body>
        <div className="close-icon" onClick={onHide}>
          <img src={closeIcon} />
        </div>

        <div className="modal-title mb-4">
          <h3>WITHDRAW COLLATERAL</h3>
        </div>
        <div className="my-3">
          <WithTag
            label={""}
            placeholder={"0.0"}
            inputValue={withdrawAmount}
            onChange={(e) => setWithdrawAmount(e.target.value)}
            tag={<div className="d-flex align-items-center">USD</div>}
          />
          <div className="d-flex justify-content-between align-items-center mt-2">
            <span className="balance">Max Withdraw : ${parseFloat(Number(withdrawOrder.balance).toFixed(3))} </span>

            <PercentageButtons setAmountIn={setWithdrawAmount} balance={withdrawOrder.balance} />
          </div>
        </div>
        <div className="mt-4 d-flex justify-content-between modal_span">
          <span>Position Size</span>
          <span className="fw-600">${parseFloat(Number(withdrawOrder.sizeChangeValue).toFixed(2))}</span>
        </div>
        <div className="mt-1 d-flex justify-content-between modal_span">
          <span>Collateral Asset</span>
          <span className="d-flex align-items-center gap-1 fw-600">
            <img src={tokenImages[withdrawOrder.collateralAsset].image} height={14} width={14} />
            {withdrawOrder.collateralAsset}
          </span>
        </div>
        <div className="mt-1 d-flex justify-content-between modal_span">
          <span>Collateral Value</span>
          <span className="fw-600 d-flex">
            {Number(withdrawAmount) > 0 && (
              <span className="me-12 opacity-50 d-flex align-items-center gap-12">
                ${parseFloat(Number(withdrawOrder.collateral).toFixed(2))}{" "}
                <img src={arrowIcon} height={10} width={14} />
              </span>
            )}
            ${parseFloat(Number(withdrawOrder?.newCollateralValue)?.toFixed(2))}
          </span>
        </div>
        <div className="mt-1 d-flex justify-content-between modal_span">
          <span>Net Value</span>
          <span className="fw-600 d-flex">
            {Number(withdrawAmount) > 0 && (
              <span className="me-12 opacity-50 d-flex align-items-center gap-12">
                ${parseFloat(Number(withdrawOrder.netValue).toFixed(2))} <img src={arrowIcon} height={10} width={14} />
              </span>
            )}
            ${parseFloat(Number(withdrawOrder?.newNetValue)?.toFixed(2))}
          </span>
        </div>
        <div className="mt-1 d-flex justify-content-between modal_span">
          <span>Leverage</span>
          <span className="fw-600 d-flex">
            {Number(withdrawAmount) > 0 && (
              <span className="me-12 opacity-50 d-flex align-items-center gap-12">
                {parseFloat(Number(withdrawOrder.leverage).toFixed(2))}X <img src={arrowIcon} height={10} width={14} />
              </span>
            )}
            {parseFloat(Number(withdrawOrder?.newLeverage)?.toFixed(2))}X
          </span>
        </div>
        <div className="mt-3 d-flex justify-content-between modal_span">
          <span>Liquidation Price</span>
          <span className="fw-600 mb-0 d-flex">
            {Number(withdrawAmount) > 0 && (
              <span className="me-12 opacity-50 d-flex align-items-center gap-12">
                ${parseFloat(Number(withdrawOrder.liquidationPrice).toFixed(2))}
                <img className="ms-1" src={arrowIcon} height={10} width={14} />
              </span>
            )}
            ${parseFloat(Number(withdrawOrder?.newLiquidationPrice)?.toFixed(2))}
          </span>
        </div>
        <div className="mt-1 d-flex justify-content-between modal_span">
          <span>Execution Fee</span>
          <span className="fw-600">{withdrawOrder.executionFee} KAVA</span>
        </div>
        <div className="mt-1 d-flex justify-content-between modal_span">
          <span>Borrow Fee</span>
          <span className="fw-600">${parseFloat(Number(withdrawOrder.borrowFeeValue).toFixed(2))}</span>
        </div>
        <div className="mt-3 modal_span">
          <SlippageRow setAllDetails={setWithdrawOrder} allDetails={withdrawOrder} />
        </div>
        <div className="mt-3 d-flex justify-content-between modal_span">
          <span>Market Price</span>
          <span className="fw-600">${parseFloat(Number(withdrawOrder.marketPrice).toFixed(2))}</span>
        </div>
        <div className="mt-1 d-flex justify-content-between modal_span">
          <span>Trigger Condition</span>
          <span className="fw-600">
            Market Price {withdrawOrder.side === 0 ? "≥" : "≤"} $
            {withdrawOrder.side === 0
              ? parseFloat(
                (
                  Number(withdrawOrder.marketPrice) -
                  (Number(withdrawOrder.marketPrice) * Number(withdrawOrder.slippage.value)) / 100
                )?.toFixed(3),
              )
              : parseFloat(
                (
                  Number(withdrawOrder.marketPrice) +
                  (Number(withdrawOrder.marketPrice) * Number(withdrawOrder.slippage.value)) / 100
                )?.toFixed(3),
              )}
          </span>
        </div>
        <div className="mt-1 d-flex justify-content-between modal_span">
          <span>Minimum Received</span>
          <span className="fw-600">
            {Number(withdrawOrder.minPayoutAmount) > 0
              ? Number(withdrawOrder.minPayoutAmount) < 0.00001
                ? "<0.00001"
                : parseFloat((Number(withdrawOrder.minPayoutAmount)).toFixed(5))
              : "-"}{" "}
            {withdrawOrder.collateralAsset}
          </span>
        </div>
        <div className="modal-confirm-btn mt-4">
          <PlButton
            disabled={account === null || chainId !== config.CHAIN_ID || Number(withdrawAmount) === 0 || Number(withdrawAmount) > Number(withdrawOrder.balance)}
            label={
              (account === null || chainId !== config.CHAIN_ID)
                ? "withdraw"
                : Number(withdrawAmount) === 0
                ? "enter an amount"
                : Number(withdrawAmount) > Number(withdrawOrder.balance)
                  ? "insufficient funds"
                  : "withdraw"
            }
            onClick={() => {
              handleWithdraw();
              onHide();
            }}
          />
        </div>
        {((withdrawOrder.side === 0 && Number(withdrawOrder?.newLiquidationPrice) > Number(withdrawOrder?.marketPrice)) ||
          (withdrawOrder.side === 1 && Number(withdrawOrder?.newLiquidationPrice) < Number(withdrawOrder?.marketPrice))) &&
        <div className="mt-3 d-flex justify-content-center align-items-center modal_span loss">
          <img src={warningIcon} alt="" /><span>Your position will immediately get liquidated.</span>
        </div>
        }
      </Modal.Body>
    </Modal>
  );
};

export default Withdraw;
