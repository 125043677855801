// Will mostly have only UI component imports and placement in this file.

import icon from "../../assets/images/dashboard_poll.svg";

import PlatformMetrics from "./cards/PlatformMetrics";
import TradingPairs from "./cards/TradingPairs";
import Liquidity from "./cards/Liquidity";
import Earn from "./cards/Earn";

const Page = ({ tradingDetails, metricsData, tradingData, liquidityDetails, earnData }) => {
  return (
    <>
      {/*<h1 className="page_title dashboard_title d-flex align-items-center justify-content-start">*/}
      {/*  <img src={icon} alt="" /> DASHBOARD*/}
      {/*</h1>*/}

      <PlatformMetrics metricsData={metricsData} />
      <TradingPairs tradingDetails={tradingDetails} tradingData={tradingData} />
      <Liquidity liquidityDetails={liquidityDetails} />
      {/*<Earn earnData={earnData} />*/}
    </>
  );
};

export default Page;
