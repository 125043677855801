import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
  AreaChart,
  Area,
  ComposedChart,
  Line,
} from "recharts";
import { Col } from "react-bootstrap";

const Chart = ({ AUMDetails, tokenChartDetails, volumeDetails, feeVolumeDetails }) => {

  const YaxisFormat = (value) => {
    return `$${value > 1000000 ? `${value / 1000000}M` : value > 1000 ? `${value / 1000}K` : value}`;
  };

  const CustomAumTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label mb-1">{payload[0].payload.withYear}</p>
          <p className="desc btcDesc">Junior: ${payload[0].payload.Junior.toLocaleString()}</p>
          <p className="desc ethDesc">Mezzanine: ${payload[0].payload.Mezzanine.toLocaleString()}</p>
          <p className="desc bnbDesc">Senior: ${payload[0].payload.Senior.toLocaleString()}</p>
        </div>
      );
    }

    return null;
  };

  const CustomTokensTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label mb-1">{payload[0].payload.withYear}</p>
          <p className="desc btcDesc">BTC: ${parseFloat(payload[0].payload.BTC.toFixed(5)).toLocaleString()}</p>
          <p className="desc ethDesc">ETH: ${parseFloat(payload[0].payload.ETH.toFixed(5)).toLocaleString()}</p>
          <p className="desc bnbDesc">KAVA: ${parseFloat(payload[0].payload.KAVA.toFixed(5)).toLocaleString()}</p>
          <p className="desc usdtDesc">USDT: ${parseFloat(payload[0].payload.USDT.toFixed(5)).toLocaleString()}</p>
        </div>
      );
    }
    return null;
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label mb-1">{payload[0].payload?.withYear}</p>
          {payload[0].payload.__typename === "FeeStat" ?
            <>
              <p className="desc btcDesc">Leveraged Trading:
                ${parseFloat(payload[0].payload["Leveraged Trading"].toFixed(5))}</p>
              <p className="desc ethDesc">Swap: ${parseFloat(payload[0].payload.Swap.toFixed(5))}</p>
              <p className="desc usdtDesc">Cumulative: ${parseFloat(payload[0].payload.Cumulative.toFixed(5))}</p>
            </> :
            <>
              <p className="desc btcDesc">Leveraged Trading:
                ${payload[0].payload["Leveraged Trading"].toLocaleString()}</p>
              <p className="desc ethDesc">Swap: ${payload[0].payload.Swap.toLocaleString()}</p>
              <p className="desc usdtDesc">Cumulative: ${payload[0].payload.Cumulative.toLocaleString()}</p>
            </>
          }
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <Col>
        <div className="mb-4 mt-0 shadowed-box px-0">
          <div className="chart-wrapper pool_chart">
            <div className="d-flex justify-content-between align-items-center mb-3 chart-title ps-4">
              <h2 className="mb-0 mt-3">AUM by Tranches</h2>
            </div>
            <ResponsiveContainer width="100%" height="85%">
              <AreaChart
                width={500}
                height={300}
                data={AUMDetails}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                className='analytics_axis'
              >
                <CartesianGrid strokeOpacity="0.2" />
                <XAxis dataKey="dayMonth"
                       interval={Math.trunc(AUMDetails?.length / 9)}
                       fontSize={12}
                />
                <Tooltip content={<CustomAumTooltip />} />
                <YAxis tickFormatter={(value) => YaxisFormat(value)} fontSize={12} />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="Junior"
                  stroke="#F7931A"
                  fill="#F7931A"
                  stackId="1"
                  className="BTC"
                  dot={null}
                />
                <Area
                  type="monotone"
                  dataKey="Mezzanine"
                  stroke="#627EEA"
                  fill="#627EEA"
                  stackId="1"
                  className="ETH"
                  dot={null}
                />
                <Area
                  type="monotone"
                  dataKey="Senior"
                  stroke="#26A17B"
                  fill="#26A17B"
                  stackId="1"
                  className="KAVA"
                  dot={null}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Col>
      <Col>
        <div className="mb-4 mt-0 shadowed-box px-0">
          <div className="chart-wrapper pool_chart">
            <div className="d-flex justify-content-between align-items-center mb-3 chart-title ps-4">
              <h2 className="mb-0 mt-3">AUM by Tokens</h2>
            </div>
            <ResponsiveContainer width="100%" height="85%">
              <AreaChart
                width={500}
                height={300}
                data={tokenChartDetails}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                className='analytics_axis'
              >
                <CartesianGrid strokeOpacity="0.2" />
                <XAxis dataKey="dayMonth" interval={Math.trunc(tokenChartDetails?.length / 9)} fontSize={12} />
                <YAxis
                  tickFormatter={(value) => YaxisFormat(value)}
                  fontSize={12} />
                <Tooltip content={<CustomTokensTooltip />} />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="BTC"
                  stroke="#F7931A"
                  fill="#F7931A"
                  stackId="1"
                  className="BTC"
                  dot={null}
                />
                <Area
                  type="monotone"
                  dataKey="ETH"
                  stroke="#627EEA"
                  fill="#627EEA"
                  stackId="1"
                  className="ETH"
                  dot={null}
                />
                <Area
                  type="monotone"
                  dataKey="KAVA"
                  stroke="#26A17B"
                  fill="#26A17B"
                  stackId="1"
                  className="KAVA"
                  dot={null}
                />
                <Area
                  type="monotone"
                  dataKey="USDT"
                  stroke="#F3BA2F"
                  fill="#F3BA2F"
                  stackId="1"
                  className="USDT"
                  dot={null}
                />
              </AreaChart>

            </ResponsiveContainer>
          </div>
        </div>
      </Col>
      <Col>
        <div className="mt-0 shadowed-box px-0">
          <div className="chart-wrapper pool_chart">
            <div className="d-flex justify-content-between align-items-center mb-3 chart-title ps-4">
              <h2 className="mb-0 mt-3">Volume</h2>
            </div>
            <ResponsiveContainer width="100%" height="85%">
              <ComposedChart
                width={500}
                height={300}
                data={volumeDetails}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                className='analytics_axis'
              >
                <CartesianGrid strokeOpacity="0.2" />
                <XAxis dataKey="dayMonth" interval={Math.trunc(volumeDetails?.length / 9)} fontSize={12} />
                <YAxis yAxisId="left" tickFormatter={(value) => YaxisFormat(value)} fontSize={12} />
                <YAxis yAxisId="right" orientation="right" tickFormatter={(value) => YaxisFormat(value)}
                       fontSize={12} />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Bar maxBarSize={20} dataKey="Leveraged Trading" fill="#F7931A" stackId="a" yAxisId="left" />
                <Bar maxBarSize={20} dataKey="Swap" fill="#627EEA" stackId="a" yAxisId="left" />
                <Line
                  dataKey="Cumulative"
                  type="monotone"
                  strokeWidth="3px"
                  stroke="#F3BA2F"
                  dot={null}
                  isAnimationActive={false}
                  yAxisId="right"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Col>
      <Col>
        <div className="mt-0 shadowed-box px-0">
          <div className="chart-wrapper pool_chart">
            <div className="d-flex justify-content-between align-items-center mb-3 chart-title ps-4">
              <h2 className="mb-0 mt-3">Fees</h2>
            </div>
            <ResponsiveContainer width="100%" height="85%">
              <ComposedChart
                width={500}
                height={300}
                data={feeVolumeDetails}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                className='analytics_axis'
              >
                <CartesianGrid strokeOpacity="0.2" />
                <XAxis dataKey="dayMonth"
                       interval={Math.trunc(feeVolumeDetails?.length / 9)}
                       fontSize={12} />
                <YAxis yAxisId="left" tickFormatter={(value) => YaxisFormat(value)} fontSize={12} />
                <YAxis yAxisId="right" orientation="right" tickFormatter={(value) => YaxisFormat(value)}
                       fontSize={12} />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Bar maxBarSize={20} dataKey="Leveraged Trading" stackId="a" fill="#F7931A" yAxisId="left" />
                <Bar maxBarSize={20} dataKey="Swap" stackId="a" fill="#627EEA" yAxisId="left" />
                <Line
                  dataKey="Cumulative"
                  type="monotone"
                  strokeWidth="3px"
                  stroke="#F3BA2F"
                  dot={null}
                  yAxisId="right"
                  isAnimationActive={false}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Col>
    </>
  );
};

export default Chart;
