// Will mostly have only UI component imports and placement in this file.

import { Col, Row } from "react-bootstrap";

const Status = ({ trancheStatusData, trancheConditions }) => {
  return (
    <Col md={8} className="mt-0">
      <Row>
        <div className="trading-section">
          <p className="title">Tranche Status</p>
          <Row className="heading mx-0 ps-3 token-dist-head">
            {trancheStatusData?.columnTitle?.map((item, i) => (
              <Col key={i} className={item.className}>
                <p className="mx-0">{item.title}</p>
              </Col>
            ))}
          </Row>
          {trancheConditions.assetDetails?.map((item, i) => (
            <Row className="data shadowed-box py-0 pe-0 mx-0 ps-3" key={i}>
              <Col>
                <img src={item.image} height={20} width={20} />
                <span className="bold ms-2">{item.token}</span>
              </Col>
              <Col>
                <span>{item.amount !== undefined ? (item.amount < 0.001 && item.amount > 0) ? "<0.001" : parseFloat(item.amount.toFixed(3)).toLocaleString() : "-"}</span>
              </Col>
              <Col>
                <span>{item.value !== undefined ? `$${(item.value < 0.01 && item.value > 0) ? "<0.01" : item.value > 1 ? Math.round(item.value).toLocaleString() : parseFloat(item.value.toFixed(2))}` : "-"}</span>
              </Col>
              <Col>
                <span>{item.utilization !== undefined ? `${(item.utilization < 0.01 && item.utilization > 0) ? "<0.01" : item.utilization.toFixed(2)}%` : "-"}</span>
              </Col>
              {/*<Col className="justify-content-center">*/}
              {/*  <span>-</span>*/}
              {/*</Col>*/}
            </Row>
          ))}
        </div>
      </Row>
    </Col>
  );
};

export default Status;
