import React, { createContext, useContext, useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { getAipxBalance, providerHandler } from "../io/kava";
import config from "../config.json";
import { switchNetworkToast } from "../components/toasts/SwitchNetwork";
import { toast } from "react-toastify";

const web3ConnectContext = createContext();

function Web3ConnectProvider({ children }) {
  const { chainId,account } = useMetaMask();
  const [accountBalance, setAccountBalance] = useState();
  const [lampBalance, setLampBalance] = useState();
  const [isChainAvailable, setIsChainAvailable] = useState(true);

  console.log("init:web3prov");
  useEffect(() => {
    handleKavaChainChange(chainId);
  }, [chainId]);

  const handleWeb3Connect = async () => {
    // console.log('web3:chainId',chainId);
    const balance = await providerHandler();
    // console.log("web3:provHan", balance);
    setAccountBalance(balance);

    if(account !== null){
      const platformBalance = await getAipxBalance(account)
      setLampBalance(platformBalance)
    }
  };

  const handleAddNetwork = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: config.CHAIN_ID,
            chainName: "Goerli test network",
            rpcUrls: ["https://goerli.infura.io/v3/"],
            nativeCurrency: {
              name: "GoerliETH",
              symbol: "GoerliETH",
              decimals: 18,
            },
            blockExplorerUrls: ["https://goerli.etherscan.io"],
          },
        ],
      }).then(() => {
        toast.dismiss();
        setIsChainAvailable(true);
      });
    } catch (err) {
      console.log("addChain err--", err);
      console.log("addChain err code--", err.code);
    }
  };

  const handleKavaChainChange = async (chainId) => {
    const chainID = config.CHAIN_ID;
    if (chainId !== null && chainId !== chainID) {
      switchNetworkToast(`switch${chainID}`, true);
      try {
        await window.ethereum
          .request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: chainID }],
          })
          .then(() => {
            toast.dismiss();
          });
      } catch (e) {
        if (e.code === 4902) {
          toast.dismiss(`switch${chainID}`);
          console.log("$$$$$$$$$$$$$$$");
          switchNetworkToast(`add${chainID}`, false);
          setIsChainAvailable(false);
        }
      }
    } else {
      toast.dismiss(chainID);
    }
  };

  return (
    <web3ConnectContext.Provider
      value={{
        handleWeb3Connect,
        accountBalance,
        lampBalance,
        handleKavaChainChange,
        isChainAvailable,
        handleAddNetwork,
      }}
    >
      {children}
    </web3ConnectContext.Provider>
  );
}

export default Web3ConnectProvider;

export const useWeb3ConnectContext = () => useContext(web3ConnectContext);
