import config from "../config.json";
import { ethers } from "ethers";

export const calcIncreasePosition = (
  owner,
  payToken,
  amountIn,
  leverage,
  side,
  indexToken,
  isLimit,
  limitPrice,
  calcSwapOutDetails,
  ownerPosition,
  isDeposit,
) => {
  // console.log('calcIncreasePosition >>>>> ');
  /*
  console.log(' INP:owner-', owner);
  console.log(' INP:payToken-', payToken);
  console.log(' INP:amountIn-',amountIn);
  console.log(' INP:leverage-',leverage);
  console.log(' INP:side-',side);
  console.log(' INP:indexToken-',indexToken);
  console.log(' INP:isLimit-',isLimit);
  console.log(' INP:limitPrice-',limitPrice);
  console.log(' INP:calcSwapOutDetails-',calcSwapOutDetails);
  console.log(' INP:ownerPosition-',ownerPosition);
  let collateralToken = side === 0 ? indexToken : config.stableCoin;
  let currentCloseFeeValue = (ownerPosition.size * calcSwapOutDetails.fees.positionFee) / config.PRECISION;
  let totalCurrentFeeValue = currentCloseFeeValue + calcSwapOutDetails.fees.liquidationFee;
  let currentLiquidationPrice = _getLiquidationPrice(
    side, ownerPosition.entryPrice, ownerPosition.collateralValue, ownerPosition.size, totalCurrentFeeValue
  );
  */

  let collateralToken = side === 0 ? indexToken : config.stableCoin;
  let collateralAmount = amountIn;
  let swapFeeRate = 0;
  // let noSwap = payToken === indexToken;
  // console.log(" VAR:collateralToken", collateralToken);

  let noSwap = payToken === collateralToken;
  if (!isDeposit && !noSwap) {
    const { amountOutAfterFee, feePercentage } = calcSwapAmountOut(
      payToken,
      collateralToken,
      amountIn,
      calcSwapOutDetails,
      isLimit,
      limitPrice,
      true,
    );
    collateralAmount = amountOutAfterFee;
    swapFeeRate = feePercentage;
  }
  // let collateralAmount = noSwap ? amountIn : amountOutAfterFee;
  // let collateralPrice = isLimit ? limitPrice : calcSwapOutDetails.prices[indexToken];
  let collateralPrice = side === 1
    ? calcSwapOutDetails.prices[collateralToken]
    : isLimit
      ? limitPrice
      : calcSwapOutDetails.prices[collateralToken];
  let collateralValueAdded = collateralAmount * collateralPrice;
  let borrowIndex = _accrueInterest(
    calcSwapOutDetails.poolTokenInfo[collateralToken],
    calcSwapOutDetails.assetInfo[collateralToken],
    calcSwapOutDetails.interestRate[collateralToken],
    calcSwapOutDetails.accrualInterval,
  );
  // console.log(" VAR:colValAdd", collateralValueAdded)

  let borrowFeeValue = ((borrowIndex - ownerPosition?.borrowIndex) * ownerPosition.size) / config.PRECISION;
  // console.log(" VAR:borrowFeeVal", borrowFeeValue);

  let collateralValue = collateralValueAdded - borrowFeeValue;
  let newPositionSize = ownerPosition.size;
  let sizeChanged = 0;
  let positionFeeValue = 0;

  if (!isDeposit) {
    sizeChanged += ((collateralValueAdded - borrowFeeValue) * leverage * config.PRECISION) /
      (config.PRECISION + leverage * calcSwapOutDetails.fees.positionFee);
    // console.log(" VAR:sizeChange", sizeChanged);
    // sizeChanged = sizeChanged * collateralPrice / calcSwapOutDetails.prices[indexToken];
    // console.log(" VAR:ownPosSize", ownerPosition.size);
    positionFeeValue = (sizeChanged * calcSwapOutDetails.fees.positionFee) / config.PRECISION;
    collateralValue -= positionFeeValue;
    newPositionSize += sizeChanged;
  }

  let newCollateralValue = ownerPosition.collateralValue + collateralValue;
  // console.log("   newLev-", newPositionSize/newCollateralValue);
  // console.log(" VAR:ownPosEntryPr", ownerPosition.entryPrice)
  // console.log(" VAR:indPr", calcSwapOutDetails.prices[indexToken])
  let newEntryPrice = _calcAveragePrice(
    side,
    ownerPosition.size,
    newPositionSize,
    ownerPosition.entryPrice,
    isLimit ? limitPrice : calcSwapOutDetails.prices[indexToken],
    0,
  );
  // console.log(" VAR:newEntryPr", newEntryPrice);

  let currentCloseFeeValue = (ownerPosition.size * calcSwapOutDetails.fees.positionFee) / config.PRECISION;
  let totalCurrentFeeValue = borrowFeeValue + currentCloseFeeValue + calcSwapOutDetails.fees.liquidationFee;
  let currentLiquidationPrice = _getLiquidationPrice(
    side, ownerPosition.entryPrice, ownerPosition.collateralValue, ownerPosition.size, totalCurrentFeeValue,
  );

  let closeFeeValue = (newPositionSize * calcSwapOutDetails.fees.positionFee) / config.PRECISION;
  let totalFeeValue = closeFeeValue + calcSwapOutDetails.fees.liquidationFee;
  let liquidationPrice = _getLiquidationPrice(
    side, newEntryPrice, newCollateralValue, newPositionSize, totalFeeValue,
  );
  // console.log("incPos:liqPrice", liquidationPrice);

  // console.log(' OUT:liquidationPrice-',liquidationPrice);
  /*
  console.log(' OUT:sizeChanged-',sizeChanged);
  console.log(' OUT:collateralValue-',collateralValue);
  console.log(' OUT:collateralToken-',collateralToken);
  console.log(' OUT:newPositionSize-',newPositionSize);
  console.log(' OUT:liquidationPrice-',liquidationPrice);
  console.log(' OUT:swapFeeRate-',swapFeeRate);
  console.log(' OUT:positionFeeValue-',positionFeeValue);
  console.log(' OUT:newCollateralValue-',newCollateralValue);
  console.log(' OUT:currentLiquidationPrice-',currentLiquidationPrice);
  */
  // console.log('<<<<<')

  return {
    sizeChanged,
    collateralValue,
    collateralToken,
    newPositionSize,
    liquidationPrice,
    swapFeeRate,
    positionFeeValue,
    newCollateralValue,
    currentLiquidationPrice,
  };
};

export const calcDecreasePosition = (
  owner,
  collateralToken,
  sizeChanged,
  collateralChanged,
  side,
  indexToken,
  slippage,
  isLimit,
  limitPrice,
  calcSwapOutDetails,
  ownerPosition,
) => {
  console.log(">>>>> CalcDecreasePos");
  sizeChanged = Math.min(ownerPosition.size, sizeChanged);
  let collateralReduced =
    ownerPosition.collateralValue < collateralChanged || ownerPosition.size === sizeChanged
      ? ownerPosition.collateralValue
      : collateralChanged;

  let borrowIndex = _accrueInterest(
    calcSwapOutDetails.poolTokenInfo[collateralToken],
    calcSwapOutDetails.assetInfo[collateralToken],
    calcSwapOutDetails.interestRate[collateralToken],
    calcSwapOutDetails.accrualInterval,
  );
  console.log("	CDP:sizeChanged-", sizeChanged);
  console.log("	CDP:collateralReduced-", collateralReduced);
  console.log("	CDP:isLimit-", isLimit);
  console.log("	CDP:ownPos.entrPr", ownerPosition.entryPrice);
  console.log("	CDP:slippage", slippage);

  let price = isLimit ? limitPrice : calcSwapOutDetails.prices[indexToken];
  let priceWithSlippage =
    side === 0 ? price * (1 - slippage / config.PRECISION) : price * (1 + slippage / config.PRECISION);

  let pnl = _calcPnL(side, sizeChanged, ownerPosition.entryPrice, price);
  // let minPnL = _calcPnL(side, sizeChanged, ownerPosition.entryPrice, priceWithSlippage);
  let minPnL = isLimit
    ? _calcPnL(side, sizeChanged, ownerPosition.entryPrice, price)
    : _calcPnL(side, sizeChanged, ownerPosition.entryPrice, priceWithSlippage);
  // let remainPnL = _calcPnL(
  // side, ownerPosition.size - sizeChanged, ownerPosition.entryPrice, priceWithSlippage
  // );
  let remainPnL = _calcPnL(
    side, ownerPosition.size - sizeChanged, ownerPosition.entryPrice, price,
  );

  console.log("	CDP:price-", price);
  console.log("	CDP:priceWithSlippage-", priceWithSlippage);
  console.log("	CDP:pnl-", pnl);
  console.log("	CDP:minPnL-", minPnL);
  console.log("	CDP:remainPnL-", remainPnL);

  // let borrowFeeValue =
  //   ((calcSwapOutDetails.poolTokenInfo[collateralToken].borrowIndex
  //     - ownerPosition.borrowIndex) * ownerPosition.size) / config.PRECISION;
  console.log("   borrowIndex", borrowIndex);
  console.log("   poolTk.borInd", calcSwapOutDetails.poolTokenInfo[collateralToken].borrowIndex);
  console.log("   ownPos.borInd", ownerPosition.borrowIndex);
  let borrowFeeValue = ((borrowIndex - ownerPosition.borrowIndex) * ownerPosition.size) / config.PRECISION;
  let closeFeeValue = (sizeChanged * calcSwapOutDetails.fees.positionFee) / config.PRECISION;

  let payoutValue = pnl + collateralReduced - borrowFeeValue - closeFeeValue;
  let minPayoutValue = minPnL + collateralReduced - borrowFeeValue - closeFeeValue;

  // console.log("	CDP:BI from ACR_INT-", borrowIndex);
  // console.log("	CDP:BI from CSOD-",calcSwapOutDetails.poolTokenInfo[collateralToken].borrowIndex);
  console.log("	CDP:borrowFeeValue-", borrowFeeValue);
  console.log("	CDP:closeFeeValue -", closeFeeValue);
  console.log("	CDP:payoutValue -", payoutValue);
  console.log("	CDP:minPayoutValue -", minPayoutValue);

  let remainingCollateral = ownerPosition.collateralValue - collateralReduced;
  //if (payoutValue < 0 && (sizeChanged > 0 || collateralChanged > 0)) {
  if (payoutValue < 0) {
    remainingCollateral = remainingCollateral + payoutValue;
  }

  if (minPayoutValue < 0) minPayoutValue = 0;

  // let minPayoutAmount = minPayoutValue / calcSwapOutDetails.prices[collateralToken];
  let minPayoutAmount = isLimit
    ? minPayoutValue / price
    : minPayoutValue / calcSwapOutDetails.prices[collateralToken];

  if (remainingCollateral < 0) {
    remainingCollateral = 0;
  }

  let newCollateralValue = remainingCollateral;
  let newPositionSize = ownerPosition.size - sizeChanged;

  let newCloseFeeValue = (newPositionSize * calcSwapOutDetails.fees.positionFee) / config.PRECISION;
  let newLeverage = newPositionSize <= 0 ? 0 : newPositionSize / newCollateralValue;
  // let newNetValue = newCollateralValue - newCloseFeeValue - borrowFeeValue + remainPnL;
  let newNetValue = newCollateralValue - newCloseFeeValue + remainPnL;

  let totalFee = newCloseFeeValue + borrowFeeValue + calcSwapOutDetails.fees.liquidationFee;

  let newLiquidationPrice =
    newPositionSize <= 0
      ? 0
      // : _getLiquidationPrice(side, ownerPosition.entryPrice, newCollateralValue, newPositionSize, totalFee);
      : _getLiquidationPrice(side, ownerPosition.entryPrice, newNetValue, newPositionSize, totalFee);

  console.log("	CDP:remainingCollateral -", remainingCollateral);
  console.log("	CDP:newCloseFeeValue -", newCloseFeeValue);
  console.log("	CDP:minPayoutAmount -", minPayoutAmount);
  console.log("	CDP:newCollateralValue -", newCollateralValue);
  console.log("	CDP:newPositionSize -", newPositionSize);
  console.log("	CDP:newLeverage-", newLeverage);
  console.log("	CDP:newNetValue -", newNetValue);
  console.log("	CDP:newLiquidationPrice -", newLiquidationPrice);
  console.log("	CDP:totalFee -", totalFee);

  console.log("<<<<<< CalcDecreasePos");

  return {
    newLiquidationPrice,
    newPositionSize,
    newCollateralValue,
    newNetValue,
    newLeverage,
    minPnL,
    closeFeeValue,
    borrowFeeValue,
    minPayoutAmount,
  };
};

//To obtain the swap value based on the provided amountIn.
export const calcSwapAmountOut = (tokenIn, tokenOut, amountIn, calcSwapOutDetails, isLimit, limitPrice, isTrade) => {
  let valueChange = calcSwapOutDetails.prices[tokenIn] * Number(amountIn);
  let isStableSwap = tokenIn === config.stableCoin && tokenOut === config.stableCoin;
  let baseSwapFee = calcSwapOutDetails.fees.baseSwapFees[isStableSwap ? "USDT" : "BTC"];
  let taxBasisPoint = calcSwapOutDetails.fees.taxBasisPoints[isStableSwap ? "USDT" : "BTC"];
  let priceOut = isLimit
    ? isTrade
      ? limitPrice
      : calcSwapOutDetails.prices[tokenIn] / limitPrice
    : calcSwapOutDetails.prices[tokenOut];

  let feeIn = _calcFeeRate(
    calcSwapOutDetails.targetWeights[tokenIn],
    calcSwapOutDetails.totalWeight,
    calcSwapOutDetails.prices[tokenIn],
    calcSwapOutDetails.assetInfo[tokenIn].poolAmount,
    calcSwapOutDetails.virtualPoolValue,
    valueChange,
    // calcSwapOutDetails.fees.baseSwapFees[isStableSwap ? config.stableCoin : tokenIn],
    // calcSwapOutDetails.fees.taxBasisPoints[isStableSwap ? config.stableCoin : tokenIn],
    baseSwapFee,
    taxBasisPoint,
    true,
  );
  let feeOut = _calcFeeRate(
    calcSwapOutDetails.targetWeights[tokenOut],
    calcSwapOutDetails.totalWeight,
    priceOut,
    // calcSwapOutDetails.prices[tokenOut],
    calcSwapOutDetails.assetInfo[tokenOut].poolAmount,
    calcSwapOutDetails.virtualPoolValue,
    valueChange,
    //calcSwapOutDetails.fees.baseSwapFees[isStableSwap ? config.stableCoin : tokenOut],
    //calcSwapOutDetails.fees.taxBasisPoints[isStableSwap ? config.stableCoin : tokenOut],
    baseSwapFee,
    taxBasisPoint,
    false,
  );

  let fee = feeIn > feeOut ? feeIn : feeOut;

  let amountOutAfterFee = (valueChange * (config.PRECISION - fee)) / priceOut / config.PRECISION;
  let feePercentage = (fee / config.PRECISION) * 100;
  console.log('amountOutAfterFee',amountOutAfterFee);
  return { amountOutAfterFee, feePercentage };
};

//to calculate Liquidity from token to tranche
export const calcAddLiquidity = (tranche, asset, amountIn, calcSwapOutDetails) => {
  let valueChange = amountIn * calcSwapOutDetails.prices[asset];

  let fee = _calcFeeRate(
    calcSwapOutDetails.targetWeights[asset],
    calcSwapOutDetails.totalWeight,
    calcSwapOutDetails.prices[asset],
    calcSwapOutDetails.assetInfo[asset].poolAmount,
    calcSwapOutDetails.virtualPoolValue,
    valueChange,
    calcSwapOutDetails.fees.addRemoveLiquidityFee,
    config.addRemoveTaxBasisPoints,
    true,
  );

  let userAmount = (amountIn * (config.PRECISION - fee)) / config.PRECISION;
  let daoFee = (amountIn - userAmount) * config.daoFee / 1e10;

  let lpAmount;
  if (calcSwapOutDetails.lpSupply[tranche] === 0 || calcSwapOutDetails.trancheValues[tranche] === 0) {
    lpAmount = userAmount * calcSwapOutDetails.prices[asset];
    // lpAmount = userAmount * calcSwapOutDetails.prices[asset] / LP_INITIAL_VALUE
  } else {
    lpAmount =
      (userAmount * calcSwapOutDetails.prices[asset] * calcSwapOutDetails.lpSupply[tranche]) /
      calcSwapOutDetails.trancheValues[tranche];
  }

  /*
  console.log('calcAddLiq:fee', fee);
  console.log('calcAddLiq:daoFee', daoFee);
  console.log('calcAddLiq:userAmount', userAmount);
  console.log('calcAddLiq:amountInAfterDaoFee', amountIn - daoFee)

  console.log('calcAddLiq:lpSupp', calcSwapOutDetails.lpSupply[tranche])
  console.log('calcAddLiq:trancheVal', calcSwapOutDetails.trancheValues[tranche])
  console.log('calcAddLiq:lpAmount', lpAmount);
  */

  return { lpAmount, feeRate: fee / config.PRECISION };
};

//to calculate Liquidity from tranche to token
export const calcRemoveLiquidity = (tranche, asset, lpAmountIn, calcSwapOutDetails) => {
  let valueChange = (lpAmountIn * calcSwapOutDetails.trancheValues[tranche]) / calcSwapOutDetails.lpSupply[tranche];

  let feeRate = _calcFeeRate(
    calcSwapOutDetails.targetWeights[asset],
    calcSwapOutDetails.totalWeight,
    calcSwapOutDetails.prices[asset],
    calcSwapOutDetails.assetInfo[asset].poolAmount,
    calcSwapOutDetails.virtualPoolValue,
    valueChange,
    calcSwapOutDetails.fees.addRemoveLiquidityFee,
    config.addRemoveTaxBasisPoints,
    false,
  );

  let amountOutBeforeFee =
    (lpAmountIn * calcSwapOutDetails.trancheValues[tranche]) /
    calcSwapOutDetails.lpSupply[tranche] /
    calcSwapOutDetails.prices[asset];
  let amountOut = amountOutBeforeFee * (config.PRECISION - feeRate) / config.PRECISION;

  // return { lpAmount: amountOutBeforeFee, feeRate: feeRate / config.PRECISION };
  return { lpAmount: amountOut, feeRate: feeRate / config.PRECISION };
};

const _calcFeeRate = (
  tokenTargetWeight,
  totalWeight,
  tokenPrice,
  tokenPoolAmount,
  virtualPoolValue,
  valueChange,
  baseFee,
  taxBasisPoint,
  isIncrease,
) => {
  let targetValue = totalWeight === 0 ? 0 : (tokenTargetWeight * virtualPoolValue) / totalWeight;
  //console.log('calcFeeRate:targetValue-', targetValue)
  //console.log('calcFeeRate:baseFee-', baseFee)
  if (targetValue === 0) {
    return baseFee;
  }

  let currentValue = tokenPrice * tokenPoolAmount;
  let nextValue = isIncrease ? currentValue + valueChange : currentValue - valueChange;

  let initDiff = Math.abs(currentValue - targetValue);
  let nextDiff = Math.abs(nextValue - targetValue);

  if (nextDiff < initDiff) {
    let feeAdjust = (taxBasisPoint * initDiff) / targetValue;
    let rate = baseFee > feeAdjust ? baseFee - feeAdjust : 0;
    let MIN_SWAP_FEE = config.MIN_SWAP_FEE;
    return rate > MIN_SWAP_FEE ? rate : MIN_SWAP_FEE;
    // return Math.max(0, baseFee - feeAdjust);
  } else {
    let avgDiff = (initDiff + nextDiff) / 2;
    let feeAdjust = avgDiff > targetValue ? taxBasisPoint : (taxBasisPoint * avgDiff) / targetValue;
    return baseFee + feeAdjust;
  }
};

export const _accrueInterest = (tokenInfo, asset, interestRate, accrualInterval) => {
  let assetInfo = tokenInfo;
  // console.log('acrIn:tokenInfo', assetInfo);
  const now = Math.floor(Date.now() / 1000);
  // console.log('acrIn:asset', asset);
  // console.log('acrIn:accrualInterval', accrualInterval);
  // console.log('acrIn:borInd', assetInfo.borrowIndex);
  // console.log('acrIn:interestRate', interestRate);

  let newBorrowIndex = assetInfo.borrowIndex;
  if (assetInfo.lastAccrualTimestamp === 0 || asset.poolAmount === 0) {
    // assetInfo.lastAccrualTimestamp = (now / accrualInterval) * accrualInterval;
  } else {
    let nInterval = Math.floor((now - assetInfo.lastAccrualTimestamp) / accrualInterval);
    // console.log('acrIn:nInterval', nInterval);
    if (nInterval === 0) {
      return assetInfo?.borrowIndex;
    } else {
      // assetInfo.borrowIndex += Math.floor((nInterval * interestRate * asset.reservedAmount)
      newBorrowIndex += Math.floor((nInterval * interestRate * asset.reservedAmount)
        // / asset.poolAmount / config.PRECISION);
        / asset.poolAmount);
    }
  }
  // console.log('acrIn:borInd', newBorrowIndex);

  // return assetInfo?.borrowIndex;
  return newBorrowIndex;
};

const _calcAveragePrice = (side, lastSize, nextSize, entryPrice, nextPrice, realizedPnL) => {
  if (nextSize === 0) {
    return 0;
  }
  if (lastSize === 0) {
    return nextPrice;
  }
  let pnl = _calcPnL(side, lastSize, entryPrice, nextPrice) - realizedPnL;
  // console.log(" calcPnl", pnl)
  let nxtSize = Number(nextSize);
  let divisor = side === 0 ? nxtSize + pnl : nxtSize - pnl;
  return divisor <= 0 ? 0 : (nextSize * nextPrice) / divisor;
};

export const _calcPnL = (side, positionSize, entryPrice, indexPrice) => {
  if (positionSize === 0 || entryPrice === 0) {
    return 0;
  }
  let _entryPrice = Number(entryPrice);
  let _positionSize = Number(positionSize);
  let _indexPrice = Number(indexPrice);

  if (side === 0) {
    return ((_indexPrice - _entryPrice) * _positionSize) / _entryPrice;
  } else {
    return ((_entryPrice - _indexPrice) * _positionSize) / _entryPrice;
  }
};

export const _getLiquidationPrice = (side, entryPrice, collateralValue, size, feeValue) => {
  let liquidationPrice;
  if (side === 0) {
    let price1 = entryPrice * (1 + config.maintenanceMargin / config.PRECISION - collateralValue / size);
    let price2 = entryPrice * (1 - (collateralValue - feeValue) / size);
    liquidationPrice = Math.max(price1, price2);
  } else {
    let price1 = entryPrice * (1 - config.maintenanceMargin / config.PRECISION + collateralValue / size);
    let price2 = entryPrice * (1 + (collateralValue - feeValue) / size);
    console.log(" liqPrice:short", price1, price2);
    liquidationPrice = Math.min(price1, price2);
  }
  return liquidationPrice;
};

export const _getPositionKey = (owner, indexToken, side) => {
  const encodeData = ethers.utils.defaultAbiCoder.encode(
    ["address", "address", "address", "uint256"],
    [owner, config.tokens[indexToken], config.tokens[side === 0 ? indexToken : config.stableCoin], side],
  );
  return ethers.utils.keccak256(encodeData);
};

export const getPendingReward = (_lpBalance, poolInfo, userInfo) => {
  let _arps = poolInfo.accRewardPerShare;

  let currentTime = Math.floor(Date.now() / 1000);
  if (currentTime > poolInfo.lastRewardTime && _lpBalance !== 0) {
    let time = currentTime - poolInfo.lastRewardTime;
    _arps +=
      (time * config.rewardPerSecond * poolInfo.allocPoint * config.ACC_REWARD_PRECISION) /
      config.totalAllocPoint /
      _lpBalance;
  }
  let pendingReward = (userInfo.amount * _arps) / config.ACC_REWARD_PRECISION - userInfo.rewardDebt;

  return pendingReward;
};

export const getWeightValue = (calcSwapOutDetails, token) => {
  let aum = 0;

  config.lpTokens.map((lpToken) => {
    let asset = calcSwapOutDetails.trancheAssetInfo[`${lpToken}${token}`];
    let price = calcSwapOutDetails.prices[token];

    if (token === config.stableCoin) {
      aum = aum + (price * asset.poolAmount);
    } else {
      // let  averageShortPrice = 0;
      let averageShortPrice = calcSwapOutDetails.averageShortPrices[`${lpToken}${token}`];
      let shortPnl = _calcPnL(
        1,
        asset.totalShortSize,
        averageShortPrice,
        price,
      );
      aum =
        aum +
        ((asset.poolAmount - asset.reservedAmount) *
          price +
          asset.guaranteedValue) -
        shortPnl;
    }
  });
  // console.log('aum--',aum);
  // aum MUST not be negative. If it is, please debug
  return aum;
};
