import { ethers } from "ethers";
import { Contract, Provider } from "ethers-multicall";
import { loadingToast } from "../components/toasts/Loading";

import config from "../config.json";

import orderManagerAbi from "./abis/OrderManager.json";
import poolAbi from "./abis/Pool.json";
import { useMetaMask } from "metamask-react";
import lampMasterAbi from "./abis/LampMasterAbi.json";
import liquidityRouterAbi from "./abis/LiquidityRouterAbi.json";
import oracleAbi from "./abis/Oracle.json";
import erc20Abi from "./abis/ERC20.json";
import trancheAbi from "./abis/Tranche.json";

import platformAbi from "./abis/PlatformAbi.json";
import governanceAbi from "./abis/GovernanceAbi.json";

import batchAuctionFactoryAbi from "./abis/BatchAuctionFactoryAbi.json";
import dutchAuctionFactoryAbi from "./abis/DutchAuctionFactoryAbi.json";
import batchAuctionAbi from "./abis/BatchAuctionAbi.json";
import dutchAuctionAbi from "./abis/DutchAuctionAbi.json";

import governanceStakeAbi from "./abis/GovernanceStakeAbi.json";
import platformStakeAbi from "./abis/PlatformStakeAbi.json";
import governanceRedemptionPoolAbi from "./abis/GovernanceRedemptionPoolAbi.json";

import { formatEther, formatUnits, parseEther, parseUnits } from "ethers/lib/utils";
import { handleDecimals } from "../components/HandleDecimals";
import { _getPositionKey } from "../components/CalcOutPut";

import { providers } from "@0xsequence/multicall";
import { toast } from "react-toastify";

let multiProvider;

let provider;
let ethcallProvider;

let orderManagerContract;
let poolContract;
let lampMasterContract;
let oracleContract;
let liquidityRouterContract;
let lgoRedemptionPoolContract;
let tokenContracts = {};
let trancheContracts = {};
let auctionContracts = {};
let earnContracts = {};
// console.log("$$$$$$$$$$$$$", window?.ethereum);
if (window?.ethereum !== undefined) {
  provider = new ethers.providers.Web3Provider(window?.ethereum, "any");
  // console.log("provider--", provider);
  const signer = provider.getSigner();
  poolContract = new ethers.Contract(config.trade.pool, poolAbi, signer);
  multiProvider = new providers.MulticallProvider(provider);
  // multiProvider.multicall.options.contract = config.multicallContract;

// console.log('multicallContract-', multiProvider.multicall.options.contract)

  ethcallProvider = new Provider(provider);
//  await ethcallProvider.init();

  orderManagerContract = new ethers.Contract(config.trade.orderManager, orderManagerAbi, signer);
//poolContract = new ethers.Contract(config.trade.pool, poolAbi, signer);
  lampMasterContract = new ethers.Contract(config.trade.lampMaster, lampMasterAbi, signer);
  oracleContract = new ethers.Contract(config.trade.oracle, oracleAbi, signer);
  liquidityRouterContract = new ethers.Contract(config.trade.liquidityRouter, liquidityRouterAbi, signer);

// Token Contracts
  tokenContracts.BTCContract = new ethers.Contract(config.tokens.BTC, erc20Abi, signer);
  tokenContracts.ETHContract = new ethers.Contract(config.tokens.ETH, erc20Abi, signer);
  tokenContracts.KAVAContract = new ethers.Contract(config.tokens.KAVA, erc20Abi, signer);
  tokenContracts.USDTContract = new ethers.Contract(config.tokens.USDT, erc20Abi, signer);
  tokenContracts.GovernanceContract = new ethers.Contract(config.tokens.Governance, erc20Abi, signer);
  tokenContracts.PlatformContract = new ethers.Contract(config.tokens.Platform, erc20Abi, signer);

// Tranche Contracts
  trancheContracts.seniorTrancheContract = new ethers.Contract(config.tranches.senior, trancheAbi, signer);
  trancheContracts.mezzanineTrancheContract = new ethers.Contract(config.tranches.mezzanine, trancheAbi, signer);
  trancheContracts.juniorTrancheContract = new ethers.Contract(config.tranches.junior, trancheAbi, signer);
}

//for initialize all contracts and get balance of user's wallet
export const providerHandler = async () => {
  //provider = new ethers.providers.Web3Provider(window.ethereum);

  if (provider) {
    const account = await provider.listAccounts();
    const address = account[0];
    // console.log("address--", address);
    let balance;
    if (address !== undefined) {
      balance = await provider.getBalance(address);
      return ethers.utils.formatEther(balance.toString());
    } else {
      return 0;
    }
  }
  //const signer = provider.getSigner();
  // multiProvider = new providers.MulticallProvider(provider);
  // ethcallProvider = new Provider(provider);
  // await ethcallProvider.init();

  // orderManagerContract = new ethers.Contract(config.trade.orderManager, orderManagerAbi, signer);
  // //poolContract = new ethers.Contract(config.trade.pool, poolAbi, signer);
  // lampMasterContract = new ethers.Contract(config.trade.lampMaster, lampMasterAbi, signer);
  // oracleContract = new ethers.Contract(config.trade.oracle, oracleAbi, signer);
  // liquidityRouterContract = new ethers.Contract(config.trade.liquidityRouter, liquidityRouterAbi, signer);

  // // Token Contracts
  // tokenContracts.BTCContract = new ethers.Contract(config.tokens.BTC, erc20Abi, signer);
  // tokenContracts.ETHContract = new ethers.Contract(config.tokens.ETH, erc20Abi, signer);
  // tokenContracts.FLRContract = new ethers.Contract(config.tokens.FLR, erc20Abi, signer);
  // tokenContracts.USDTContract = new ethers.Contract(config.tokens.USDT, erc20Abi, signer);
  // tokenContracts.GovernanceContract = new ethers.Contract(config.tokens.Governance, erc20Abi, signer);
  // tokenContracts.PlatformContract = new ethers.Contract(config.tokens.Platform, erc20Abi, signer);

  // // Tranche Contracts
  // trancheContracts.seniorTrancheContract = new ethers.Contract(config.tranches.senior, trancheAbi, signer);
  // trancheContracts.mezzanineTrancheContract = new ethers.Contract(config.tranches.mezzanine, trancheAbi, signer);
  // trancheContracts.juniorTrancheContract = new ethers.Contract(config.tranches.junior, trancheAbi, signer);

  // Auction Contracts
  // auctionContracts.batchAuctionContract = new ethers.Contract(
  //   config.auction.batchAuctionContract,
  //   batchAuctionFactoryAbi,
  //   signer,
  // );
  // auctionContracts.dutchAuctionContract = new ethers.Contract(
  //   config.auction.duchAuctionContract,
  //   dutchAuctionFactoryAbi,
  //   signer,
  // );
  // auctionContracts.PTcontract = new ethers.Contract(config.auction.platformContract, platformAbi, signer);
  // auctionContracts.GTcontract = new ethers.Contract(config.auction.governanceContract, governanceAbi, signer);

  // // Earn Contracts
  // earnContracts.governanceStake = new ethers.Contract(config.earn.governanceStake, governanceStakeAbi, signer);
  // earnContracts.platformStake = new ethers.Contract(config.earn.platformStake, platformStakeAbi, signer);

  //dao Contracts
  // lgoRedemptionPoolContract = new ethers.Contract(
  //   config.dao.governanceRedemptionPool,
  //   governanceRedemptionPoolAbi,
  //   signer,
  // );


};

export const getAipxBalance = async (userAddress) => {
  const platformTokenContract = new ethers.Contract(config.tokens.Platform, platformAbi, multiProvider);
  const n = await platformTokenContract.balanceOf(userAddress);
  return formatEther(n);
};

// GLOBAL USE FUNCTIONS

export const getUserData = async (userAddress) => {
  // let web3Provider = new ethers.providers.Web3Provider(window.ethereum);
  // console.log("@@@@@@@@@@@");
  // try {
  const balance = await provider.getBalance(userAddress);
  //const platFormMultiContract = new ethers.Contract(config.tokens.Platform, platformAbi, multiProvider);
  const platformTokenContract = new ethers.Contract(config.tokens.Platform, platformAbi, multiProvider);
  const platFormBalance = formatEther(await platformTokenContract.balanceOf(userAddress));

  const balances = { [config.nativeToken]: formatEther(balance) };
  const approvedOrderManager = { [config.nativeToken]: formatEther(balance) };
  const approvedLiquidityRouter = { [config.nativeToken]: formatEther(balance) };
  const approvedLampMaster = { [config.nativeToken]: formatEther(balance) };
  const userPositions = {};
  const trancheBalances = {};
  const trancheStakingBalances = {};
  const approvedTrancheLiquidityRouter = {};
  const approvedTrancheLampMaster = {};

  const poolMultiContract = new ethers.Contract(config.trade.pool, poolAbi, multiProvider);

  let assetTokensQueries = [];
  for (let i = 0; i < config.supportedTokens.length; i++) {
    if (config.supportedTokens[i] !== config.nativeToken) {
      const tokenContract = new ethers.Contract(config.tokens[config.supportedTokens[i]], erc20Abi, multiProvider);
      // multiCallArr.push(
      assetTokensQueries.push(
        tokenContract.balanceOf(userAddress),
        tokenContract.allowance(userAddress, config.trade.orderManager),
        tokenContract.allowance(userAddress, config.trade.liquidityRouter),
        tokenContract.allowance(userAddress, config.trade.lampMaster),
      );
    }
  }

  const assetTokensInfo = await Promise.all(assetTokensQueries);
  config.supportedTokens.map((elem, i) => {
    if (elem !== config.nativeToken) {
      balances[elem] = formatUnits(assetTokensInfo[i * 4], config.decimals[elem]);
      approvedOrderManager[elem] = formatUnits(assetTokensInfo[i * 4 + 1], config.decimals[elem]);
      approvedLiquidityRouter[elem] = formatUnits(assetTokensInfo[i * 4 + 2], config.decimals[elem]);
      approvedLampMaster[elem] = formatUnits(assetTokensInfo[i * 4 + 3], config.decimals[elem]);
    }
  });
  //console.log('userData:assetTokensInfo set');

  const possiblePairs = [];

  // const possiblePairs = [
  //   { indexToken: "BTC", side: 0 },
  //   { indexToken: "BTC", side: 1 },
  //   { indexToken: "ETH", side: 0 },
  //   { indexToken: "ETH", side: 1 },
  //   { indexToken: "FLR", side: 0 },
  //   { indexToken: "FLR", side: 1 },
  // ];

  for (let i = 0; i < config.supportedTokens.length; i++) {
    let token = config.supportedTokens[i];
    if (token !== config.stableCoin) {
      possiblePairs.push({ indexToken: token, side: 0 });
      possiblePairs.push({ indexToken: token, side: 1 });
    }
  }

  const allKeys = [];
  const keyToIndexToken = {};
  possiblePairs.map((elem) => {
    let key = _getPositionKey(userAddress, elem.indexToken, elem.side);
    allKeys.push(key);
    keyToIndexToken[key] = elem.indexToken;
  });

  let userPositionsQueries = [];
  for (let i = 0; i < allKeys.length; i++) {
    //multiCallArr.push(poolMultiContract.positions(allKeys[i]));
    userPositionsQueries.push(poolMultiContract.positions(allKeys[i]));
  }

  const userPositionsInfo = await Promise.all(userPositionsQueries);
  allKeys.map((key, i) => {
    let _decimals = config.decimals[keyToIndexToken[key]];
    let _pos = userPositionsInfo[i];
    userPositions[key] = {
      size: Number(formatUnits(_pos.size, 30)),
      collateralValue: Number(formatUnits(_pos.collateralValue, 30)),
      entryPrice: Number(formatUnits(_pos.entryPrice, 30 - _decimals)),
      reserveAmount: Number(formatUnits(_pos.reserveAmount, _decimals)),
      borrowIndex: Number(_pos.borrowIndex),
    };
  });
  // console.log('userData:userPositionsInfo set');

  let lpTokensQueries = [];
  for (let i = 0; i < config.lpTokens.length; i++) {
    const lpContract = new ethers.Contract(config.tranches[config.lpTokens[i]], trancheAbi, multiProvider);
    // multiCallArr.push(
    lpTokensQueries.push(
      lpContract.balanceOf(userAddress),
      lpContract.allowance(userAddress, config.trade.liquidityRouter),
      lpContract.allowance(userAddress, config.trade.lampMaster),
      lampMasterContract.userInfo(i, userAddress),
    );
  }

  const lpTokensInfo = await Promise.all(lpTokensQueries);
  config.lpTokens.map((elem, i) => {
    trancheBalances[elem] = formatEther(lpTokensInfo[i * 4]);
    approvedTrancheLiquidityRouter[elem] = formatEther(lpTokensInfo[i * 4 + 1]);
    approvedTrancheLampMaster[elem] = formatEther(lpTokensInfo[i * 4 + 2]);
    trancheStakingBalances[elem] = formatEther(lpTokensInfo[i * 4 + 3].amount);
  });
  // console.log('userData:lpTokensInfo set');

  /*
for (let i = 0; i < config.lpTokens.length; i++) {
  const bal = await lampMasterContract.userInfo(i, userAddress);
  trancheStakingBalances[config.lpTokens[i]] = formatEther(bal.amount);
}
*/

  // multiCallArr.push(platFormMultiContract.balanceOf(userAddress));
  // const resultArr = await Promise.all(multiCallArr);
  // const resultArr = await ethcallProvider.all(multiCallArr);

  /*
config.supportedTokens.map((elem, i) => {
  if (elem !== config.nativeToken) {
    balances[elem] = formatUnits(resultArr[i * 4], config.decimals[elem]);
    approvedOrderManager[elem] = formatUnits(resultArr[i * 4 + 1], config.decimals[elem]);
    approvedLiquidityRouter[elem] = formatUnits(resultArr[i * 4 + 2], config.decimals[elem]);
    approvedLampMaster[elem] = formatUnits(resultArr[i * 4 + 3], config.decimals[elem]);
  }
});

allKeys.map((key, i) => {
  let _decimals = config.decimals[keyToIndexToken[key]];
  userPositions[key] = {
    size: Number(formatUnits(resultArr[i + 12].size, 30)),
    collateralValue: Number(formatUnits(resultArr[i + 12].collateralValue, 30)),
    entryPrice: Number(formatUnits(resultArr[i + 12].entryPrice, 30 - _decimals)),
    reserveAmount: Number(formatUnits(resultArr[i + 12].reserveAmount, _decimals)),
    borrowIndex: Number(resultArr[i + 12].borrowIndex),
  };
});

config.lpTokens.map((elem, i) => {
  trancheBalances[elem] = formatEther(resultArr[i * 3 + 18]);
  approvedTrancheLiquidityRouter[elem] = formatEther(resultArr[i * 3 + 19]);
  approvedTrancheLampMaster[elem] = formatEther(resultArr[i * 3 + 20]);
});
platFormBalance = formatEther(resultArr[27]);
for (let i = 0; i < config.lpTokens.length; i++) {
  const bal = await lampMasterContract.userInfo(i, userAddress);
  trancheStakingBalances[config.lpTokens[i]] = formatEther(bal.amount);
}
*/

  return {
    balances,
    approvedOrderManager,
    approvedLiquidityRouter,
    approvedLampMaster,
    userPositions,
    trancheBalances,
    trancheStakingBalances,
    approvedTrancheLiquidityRouter,
    approvedTrancheLampMaster,
    platFormBalance,
  };
  // } catch (err) {
  //   console.log("ERR:getUserData", err);
  // MetamaskError()
  // }
};

export const getCalcOutData = async () => {
  // const oracleMultiContract = new Contract(config.trade.oracle, oracleAbi);
  // const poolMultiContract = new Contract(config.trade.pool, poolAbi);
  // console.log("@@@@@@@@@@@ in calcOut");
  // try {
  const oracleMultiContract = new ethers.Contract(config.trade.oracle, oracleAbi, multiProvider);
  const poolMultiContract = new ethers.Contract(config.trade.pool, poolAbi, multiProvider);
  // console.log("oracleMultiContract-", oracleMultiContract);
  // console.log("poolMultiContract-", poolMultiContract);
  let virtualPoolValue;
  let totalPoolValue;
  let poolTokenInfo = {};
  let assetInfo = {};
  let prices = {};
  let lpSupply = {};
  let trancheValues = {};
  let trancheLampBalances = {};
  let averageShortPrices = {};
  let trancheAssetInfo = {};

  const poolValueQueries = [poolMultiContract.virtualPoolValue(), poolMultiContract.getPoolValue(true)];

  const poolValueInfo = await Promise.all(poolValueQueries);
  virtualPoolValue = Number(formatUnits(poolValueInfo[0], 30));
  totalPoolValue = Number(formatUnits(poolValueInfo[1], 30));
  // console.log('val:conVirt', formatUnits(poolValueInfo[0],30))
  // console.log('val:conTot', formatUnits(poolValueInfo[1],30))
  // console.log('val:virt', virtualPoolValue)
  // console.log('val:total', totalPoolValue)

  const assetTokensQueries = [];
  const lpAssetTokensQueries = [];
  for (let i = 0; i < config.supportedTokens.length; i++) {
    //multiCallArr.push(
    assetTokensQueries.push(
      poolMultiContract.getPoolAsset(config.tokens[config.supportedTokens[i]]),
      poolMultiContract.poolTokens(config.tokens[config.supportedTokens[i]]),
      oracleMultiContract._getPrice(config.tokens[config.supportedTokens[i]]),
    );
    config.lpTokens.map((lpToken) => {
      lpAssetTokensQueries.push(poolMultiContract.averageShortPrices(config.tranches[lpToken], config.tokens[config.supportedTokens[i]]));
      lpAssetTokensQueries.push(poolMultiContract.trancheAssets(config.tranches[lpToken], config.tokens[config.supportedTokens[i]]));
    });
  }

  const lpAssetTokensInfo = await Promise.all(lpAssetTokensQueries);
  const assetTokensInfo = await Promise.all(assetTokensQueries);

  config.supportedTokens.map((elem, i) => {
    let _decimals = config.decimals[elem];
    let _assetInfo = assetTokensInfo[i * 3];
    let _poolInfo = assetTokensInfo[i * 3 + 1];

    assetInfo[elem] = {
      poolAmount: Number(formatUnits(_assetInfo.poolAmount, _decimals)),
      reservedAmount: Number(formatUnits(_assetInfo.reservedAmount, _decimals)),
      guaranteedValue: Number(formatUnits(_assetInfo.guaranteedValue, 30)),
      totalShortSize: Number(formatUnits(_assetInfo.totalShortSize, 30)),
    };
    // console.log(`assetInfo[${elem}]`, assetInfo[elem])
    poolTokenInfo[elem] = {
      feeReserve: Number(formatUnits(_poolInfo.feeReserve, _decimals)),
      poolBalance: Number(formatUnits(_poolInfo.poolBalance, _decimals)),
      borrowIndex: Number(_poolInfo.borrowIndex),
      lastAccrualTimestamp: Number(_poolInfo.lastAccrualTimestamp),
    };
    // console.log(`poolInfo[${elem}]`, poolTokenInfo[elem])
    prices[elem] = Number(formatUnits(assetTokensInfo[i * 3 + 2], 30 - _decimals));
    // console.log(`prices[${elem}]`, prices[elem])

    config.lpTokens.map((lpToken, index) => {
      const _key = `${lpToken}${elem}`;
      const _lpAssetInfo = lpAssetTokensInfo[((i * 3 + index) * 2) + 1];
      averageShortPrices[_key] = Number(formatUnits(lpAssetTokensInfo[((i * 3) + index) * 2], 30 - _decimals));
      trancheAssetInfo[_key] = {
        poolAmount: Number(formatUnits(_lpAssetInfo.poolAmount, _decimals)),
        reservedAmount: Number(formatUnits(_lpAssetInfo.reservedAmount, _decimals)),
        guaranteedValue: Number(formatUnits(_lpAssetInfo.guaranteedValue, 30)),
        totalShortSize: Number(formatUnits(_lpAssetInfo.totalShortSize, 30)),
      };
    });
  });
  // console.log("contData:averageShortPrices set", averageShortPrices);
  // console.log("contData:trancheAssetInfo set", trancheAssetInfo);

  const lpTokensQueries = [];
  for (let i = 0; i < config.lpTokens.length; i++) {
    const trancheMultiContract = new ethers.Contract(config.tranches[config.lpTokens[i]], trancheAbi, multiProvider);
    lpTokensQueries.push(
      trancheMultiContract.totalSupply(),
      poolMultiContract.getTrancheValue(config.tranches[config.lpTokens[i]], true),
      trancheMultiContract.balanceOf(config.trade.lampMaster),
    );
  }

  const lpTokensInfo = await Promise.all(lpTokensQueries);
  config.lpTokens.map((elem, i) => {
    lpSupply[elem] = Number(formatEther(lpTokensInfo[i * 3]));
    trancheValues[elem] = Number(formatUnits(lpTokensInfo[i * 3 + 1], 30));
    trancheLampBalances[elem] = Number(formatEther(lpTokensInfo[i * 3 + 2]));
  });
  // console.log('contData:lpTokensInfo set');

  // multiCallArr.push(poolMultiContract.getPoolValue(true));
  // const resultArr = await Promise.all(multiCallArr);

  /*
config.supportedTokens.map((elem, i) => {
  assetInfo[elem] = {
    //poolAmount: Number(formatEther(resultArr[i * 3 + 1].poolAmount)),
    //reservedAmount: Number(formatEther(resultArr[i * 3 + 1].reservedAmount)),
    poolAmount: Number(formatUnits(resultArr[i * 3 + 1].poolAmount, config.decimals[elem])),
    reservedAmount: Number(formatUnits(resultArr[i * 3 + 1].reservedAmount, config.decimals[elem])),
    guaranteedValue: Number(formatUnits(resultArr[i * 3 + 1].guaranteedValue, 30)),
    totalShortSize: Number(formatUnits(resultArr[i * 3 + 1].totalShortSize, 30)),
  };
  poolTokenInfo[elem] = {
    // feeReserve: Number(formatEther(resultArr[i * 3 + 2].feeReserve)),
    // poolBalance: Number(formatEther(resultArr[i * 3 + 2].poolBalance)),
    feeReserve: Number(formatUnits(resultArr[i * 3 + 2].feeReserve, config.decimals[elem])),
    poolBalance: Number(formatUnits(resultArr[i * 3 + 2].poolBalance, config.decimals[elem])),
    borrowIndex: Number(resultArr[i * 3 + 2].borrowIndex),
    lastAccrualTimestamp: Number(resultArr[i * 3 + 2].lastAccrualTimestamp),
  };
  prices[elem] = Number(formatUnits(resultArr[(i + 1) * 3], 30 - config.decimals[elem]));
});

config.lpTokens.map((elem, i) => {
  lpSupply[elem] = Number(formatEther(resultArr[i * 3 + 13]));
  trancheValues[elem] = Number(formatUnits(resultArr[i * 3 + 14], 30));
  trancheLampBalances[elem] = Number(formatEther(resultArr[i * 3 + 15]));
});
*/

  return {
    fees: {
      taxBasisPoints: config.taxBasisPoints,
      baseSwapFees: config.baseSwapFees,
      addRemoveLiquidityFee: config.addRemoveLiquidityFee,
      daoFee: config.daoFee,
      positionFee: config.positionFee,
      liquidationFee: config.liquidationFee,
      executionFee: config.minPerpetualExecutionFee,
      swapExecutionFee: config.minSwapExecutionFee,
    },
    poolTokenInfo,
    assetInfo,
    prices,
    lpSupply,
    trancheValues,
    virtualPoolValue,
    totalWeight: config.totalWeight,
    targetWeights: config.targetWeights,
    accrualInterval: config.accrualInterval,
    interestRate: config.interestRate,
    totalPoolValue,
    trancheLampBalances,
    averageShortPrices,
    trancheAssetInfo,
  };
  // } catch (err) {
  //   console.log("ERR:getCalcOutData", err);
  // MetamaskError()
  // }
};

// to approve token amount according to spenderAddress like pool and orderManager
export const approve = async (tokenType, spenderAddress, amount) => {
  const contract = `${tokenType}Contract`;
  // console.log("&&&&&&&&&&&&&&&", spenderAddress);
  const n = await tokenContracts[contract].approve(spenderAddress, parseUnits(handleDecimals(amount, config.decimals[tokenType]), config.decimals[tokenType]));
  toast.dismiss("waiting");
  // loadingToast(`Approving ${tokenType}.`);
  await n.wait();
  return n;
};

//SWAP FUNCTIONS

//to execute market swap order
export const marketSwap = async (tokenFrom, tokenTo, amountIn, minAmountOut, toInputValue) => {
  // console.log("amountIn--", amountIn.toString()));
  minAmountOut = Number(minAmountOut).toFixed(config.decimals[tokenTo]).replace(/\.?0+$/, "");
  console.log("marketSwap----minAmountOut--", minAmountOut);
  console.log("marketSwap----amountIn--", amountIn);
  let n;
  if (tokenFrom === config.nativeToken) {
    n = await orderManagerContract.swap(
      config.nativeAddress,
      config.tokens[tokenTo],
      parseUnits(handleDecimals(amountIn, config.decimals[tokenFrom]), config.decimals[tokenFrom]),
      parseUnits(handleDecimals(minAmountOut, config.decimals[tokenTo]), config.decimals[tokenTo]),
      { value: parseEther(amountIn.toString()) },
    );
  } else if (tokenTo === config.nativeToken) {
    n = await orderManagerContract.swap(
      config.tokens[tokenFrom],
      config.nativeAddress,
      parseUnits(handleDecimals(amountIn, config.decimals[tokenFrom]), config.decimals[tokenFrom]),
      parseUnits(handleDecimals(minAmountOut, config.decimals[tokenTo]), config.decimals[tokenTo]),
    );
  } else {
    n = await orderManagerContract.swap(
      config.tokens[tokenFrom],
      config.tokens[tokenTo],
      parseUnits(handleDecimals(amountIn, config.decimals[tokenFrom]), config.decimals[tokenFrom]),
      parseUnits(handleDecimals(minAmountOut, config.decimals[tokenTo]), config.decimals[tokenTo]),
    );
  }
  toast.dismiss("waiting");
  // loadingToast(
  //   `Swapping ${parseFloat(Number(amountIn).toFixed(5))} ${tokenFrom} for ${parseFloat(
  //     Number(toInputValue).toFixed(5))} ${tokenTo}.`,
  // );

  await n.wait();
  return n;
};

//to place limit swap order
export const limitSwap = async (tokenFrom, tokenTo, amountIn, minAmountOut, price) => {
  // const swapExeFee = await getSwapExecutionFee();
  minAmountOut = Number(minAmountOut).toFixed(config.decimals[tokenTo]).replace(/\.?0+$/, "");
  console.log("amountIn--", amountIn);
  console.log("minAmountOut--", minAmountOut);
  console.log("price--", price);
  let n;

  if (tokenFrom === config.nativeToken) {
    const amountWithFee = Number(amountIn) + config.minSwapExecutionFee;
    n = await orderManagerContract.placeSwapOrder(
      config.nativeAddress,
      config.tokens[tokenTo],
      parseUnits(handleDecimals(amountIn, config.decimals[tokenFrom]), config.decimals[tokenFrom]),
      parseUnits(handleDecimals(minAmountOut, config.decimals[tokenTo]), config.decimals[tokenTo]),
      parseUnits(Number(price).toFixed(12).replace(/\.?0+$/, ""), "szabo"),
      { value: parseEther(amountWithFee.toString()) },
    );
  } else if (tokenTo === config.nativeToken) {
    n = await orderManagerContract.placeSwapOrder(
      config.tokens[tokenFrom],
      config.nativeAddress,
      parseUnits(handleDecimals(amountIn, config.decimals[tokenFrom]), config.decimals[tokenFrom]),
      parseUnits(handleDecimals(minAmountOut, config.decimals[tokenTo]), config.decimals[tokenTo]),
      parseUnits(Number(price).toFixed(12).replace(/\.?0+$/, ""), "szabo"),
      { value: parseEther(config.minSwapExecutionFee.toString()) },
    );
  } else {
    n = await orderManagerContract.placeSwapOrder(
      config.tokens[tokenFrom],
      config.tokens[tokenTo],
      parseUnits(handleDecimals(amountIn, config.decimals[tokenFrom]), config.decimals[tokenFrom]),
      parseUnits(handleDecimals(minAmountOut, config.decimals[tokenTo]), config.decimals[tokenTo]),
      parseUnits(Number(price).toFixed(12).replace(/\.?0+$/, ""), "szabo"),
      { value: parseEther(config.minSwapExecutionFee.toString()) },
    );
  }
  toast.dismiss("waiting");
  // loadingToast(
  //   `Creating order to swap ${parseFloat(Number(amountIn).toFixed(5))} ${tokenFrom} for ${parseFloat(
  //     Number(minAmountOut).toFixed(5))} ${tokenTo}.`,
  // );

  await n.wait();
  return n;
};

// to cancel limit order which is placed by user
export const cancelOrder = async (order) => {
  console.log("order.orderId-", order.orderId);
  const n = await orderManagerContract.cancelSwapOrder(order.orderId);
  toast.dismiss("waiting");
  // loadingToast(
  //   `Cancelling order to swap ${parseFloat(Number(order.priceIn).toFixed(2))} ${order.assetIn} for ${parseFloat(
  //     Number(order.priceOut).toFixed(2))} ${order.assetOut}.`,
  // );

  await n.wait();
  return n;
};

// TRADE FUNCTIONS

export const getPositionData = async (owner, indexToken, side) => {
  const encodeData = ethers.utils.defaultAbiCoder.encode(
    ["address", "address", "address", "uint256"],
    [owner, config.tokens[indexToken], config.tokens[side === 0 ? indexToken : config.stableCoin], side],
  );
  const positionKey = ethers.utils.keccak256(encodeData);

  const n = await poolContract.positions(positionKey);
  return {
    size: Number(formatUnits(n.size, 30)),
    collateralValue: Number(formatUnits(n.collateralValue, 30)),
    entryPrice: Number(formatUnits(n.entryPrice, 30 - config.decimals[indexToken])),
    reserveAmount: Number(formatEther(n.reserveAmount)),
    borrowIndex: Number(n.borrowIndex),
    positionKey: positionKey,
  };
};

//to place order in trade page
export const placeOrder = async (
  updateType,
  side,
  indexToken,
  collateralToken,
  orderType,
  orderPrice,
  orderPayToken,
  purchaseAmount,
  sizeChange,
  feeValue,
  executionFee,
  toastMessage,
  isLimit,
  closeType,
) => {
  console.log("updateType--", updateType);
  console.log("side--", side);
  console.log("indexToken--A", indexToken, config.tokens[indexToken]);
  console.log("collateralToken--B", collateralToken, config.tokens[collateralToken]);
  console.log("orderType--", orderType);
  console.log("orderPrice--", orderPrice);
  console.log("purchaseAmount--", purchaseAmount);
  console.log("sizeChange--", ethers.utils.parseUnits(sizeChange.toString(), 30));
  console.log("feeValue--", feeValue);
  console.log("executionFee--", executionFee);
  console.log("isLimit--", isLimit);

  const triggerAboveThreshold = side === 0 ? closeType === "takeProfit" : closeType === "stopLoss";
  const orderPayTokenAddress =
    orderPayToken === config.nativeToken ? config.nativeAddress : config.tokens[orderPayToken];
  const purchaseFeeAmount =
    orderPayToken === config.nativeToken && updateType === 0
      ? Number(purchaseAmount) + Number(executionFee)
      : executionFee;

  console.log("triggerAboveThreshold--", triggerAboveThreshold);
  console.log("orderPayTokenAddress--", orderPayTokenAddress);
  console.log("purchaseFeeAmount--", parseEther(purchaseFeeAmount.toString()));

  const extraData = ethers.utils.defaultAbiCoder.encode(
    ["uint256", "uint256", "uint256"],
    [
      ethers.utils.parseUnits(sizeChange.toString(), 30),
      parseEther(handleDecimals(purchaseAmount, 18)),
      parseEther(feeValue.toString()),
    ],
  );

  let exp1 = 30 - config.decimals[indexToken];
  let num1 = Number(orderPrice).toFixed(exp1);
  const _ordPrice = ethers.utils.parseUnits(parseFloat(num1).toString(), exp1);
  console.log("ordPrice", _ordPrice);

  const encodeData =
    updateType === 0
      ? //for increase order (new position and deposit)
        // purchaseAmount for increase order = amount of payToken in ETH,BTC,FLR OR USDT
      [
        _ordPrice,
        orderPayTokenAddress,
        parseUnits(handleDecimals(purchaseAmount, config.decimals[orderPayToken]), config.decimals[orderPayToken]),
        sizeChange > 0 ? ethers.utils.parseUnits(sizeChange.toString(), 30) : 0,
        parseUnits(handleDecimals(purchaseAmount, config.decimals[orderPayToken]), config.decimals[orderPayToken]),
        extraData,
      ]
      : // purchaseAmount for decrease order = collateralValue (sizeChange / leverage ) in USD($)
      isLimit
        ? // for limit decrease order (close with stopLoss and takeProfit)
        [
          _ordPrice,
          triggerAboveThreshold,
          orderPayTokenAddress,
          ethers.utils.parseUnits(sizeChange.toString(), 30),
          ethers.utils.parseUnits(purchaseAmount.toString(), 30), //
          extraData,
        ]
        : //for market decrease order (close with market Price and withdraw)
        [
          _ordPrice,
          orderPayTokenAddress,
          sizeChange > 0 ? ethers.utils.parseUnits(sizeChange.toString(), 30) : 0,
          ethers.utils.parseUnits(purchaseAmount.toString(), 30),
          extraData,
        ];

  const n = await orderManagerContract.placeOrder(
    updateType,
    side,
    config.tokens[indexToken],
    config.tokens[collateralToken],
    orderType,
    ethers.utils.defaultAbiCoder.encode(
      updateType === 0
        ? ["uint256", "address", "uint256", "uint256", "uint256", "bytes"]
        : isLimit
        ? ["uint256", "bool", "address", "uint256", "uint256", "bytes"]
        : ["uint256", "address", "uint256", "uint256", "bytes"],
      encodeData,
    ),
    // { value: "203500000000000010" },
    { value: parseEther(purchaseFeeAmount.toString()) },
  );
  toast.dismiss("waiting");
  // loadingToast(toastMessage);

  await n.wait();
  return n;
};

// to cancel trade order which is placed by user
export const cancelTradeOrder = async (order) => {
  console.log("orderId--", Number(order.id));
  const n = await orderManagerContract.cancelOrder(Number(order.id));
  toast.dismiss("waiting");
  // loadingToast(
  //   `Cancelling order to ${order.updateType} ${order.collateralAsset} ${order.side === 0 ? "LONG" : "SHORT"}.`,
  // );

  await n.wait();
  return n;
};

// LIQUIDITY FUNCTIONS

//get all details of tokens according to specific tranche
export const getTokensDetails = async (data, trancheAddress, prices) => {
  const multiPoolContract = new ethers.Contract(config.trade.pool, poolAbi, multiProvider);
  let new_array = [];
  const returnData = [];
  let tokenFunds = {};

  for (let i = 0; i < data.length; i++) {
    const n = multiPoolContract.trancheAssets(trancheAddress, config.tokens[data[i].token]);
    new_array.push(n);
  }

  const finalResponse = await Promise.all(new_array);
  // console.log("finalResponse--", finalResponse);
  data.map((item, i) => {
    let _poolAmt = Number(formatUnits(finalResponse[i].poolAmount, config.decimals[item.token]));
    let _reservedAmt = Number(formatUnits(finalResponse[i].reservedAmount, config.decimals[item.token]));

    item.amount = _poolAmt;
    item.value = Number(prices[item.token]) * _poolAmt;
    item.utilization = (_reservedAmt / _poolAmt) * 100;
    returnData.push(item);
    tokenFunds[item.token] = item.amount - _reservedAmt;
  });

  return { assetData: returnData, tokenFunds };
};

export const getTranchesDetails = async (tradingTrancheData) => {
  let points = [];

  for (let i = 0; i < tradingTrancheData.length; i++) {
    const p = await lampMasterContract.poolInfo(i);
    points.push(p);
  }

  const returnData = [];
  await tradingTrancheData.map((trancheData, i) => {
    trancheData.dailyPoolLampReward = Math.round(
      (config.rewardPerSecond * 86400 * Number(points[i].allocPoint)) / config.totalAllocPoint,
    );
    returnData.push(trancheData);
  });
  return returnData;
};

export const getMasterPoolInfo = async (trancheId) => {
  const n = await lampMasterContract.poolInfo(trancheId);

  return {
    accRewardPerShare: Number(n.accRewardPerShare),
    lastRewardTime: Number(n.lastRewardTime),
    allocPoint: Number(n.allocPoint),
    staking: n.staking,
  };
};

export const getMasterUserInfo = async (trancheId, userAddress) => {
  const n = await lampMasterContract.userInfo(trancheId, userAddress);

  return {
    amount: Number(formatEther(n.amount)),
    rewardDebt: Number(formatEther(n.rewardDebt)),
  };
};

//to get supply of all tranche
export const trancheSupply = async () => {
  const n = await poolContract.getPoolValue(true);
  return ethers.utils.formatUnits(n, 30);
};

// to approve tranche amount according to spenderAddress like pool
export const poolApprove = async (trancheType, spenderAddress, amount) => {
  const contract = `${trancheType}TrancheContract`;

  const n = await trancheContracts[contract].approve(spenderAddress, parseEther(handleDecimals(amount, 18)));
  toast.dismiss("waiting");
  // loadingToast(
  //   `Approving ${trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} ALP.`,
  // );
  await n.wait();
  return n;
};

//to buy tranche amount
export const addLiquidity = async (
  trancheType,
  tokenType,
  amountIn,
  minimumAmount,
  userAddress,
  amountOut,
  isSwitchOn,
) => {
  let n;
  minimumAmount = Number(minimumAmount).toFixed(18).replace(/\.?0+$/, "");
  console.log("trancheType--", trancheType === "senior" ? 0 : trancheType === "mezzanine" ? 1 : 2);
  console.log("minimumAmount--", minimumAmount);
  console.log("amountIn--", amountIn);
  console.log("userAddress--", userAddress);
  console.log("isSwitchOn--", isSwitchOn);

  if (isSwitchOn) {
    if (tokenType === config.nativeToken) {
      n = await lampMasterContract.addLiquidityETH(
        trancheType === "senior" ? 0 : trancheType === "mezzanine" ? 1 : 2,
        parseEther(handleDecimals(minimumAmount, 18)),
        userAddress,
        { value: parseEther(amountIn.toString()) },
      );
    } else {
      // stake LLP tokens
      n = await lampMasterContract.addLiquidity(
        trancheType === "senior" ? 0 : trancheType === "mezzanine" ? 1 : 2,
        config.tokens[tokenType],
        parseUnits(handleDecimals(amountIn, config.decimals[tokenType]), config.decimals[tokenType]),
        parseEther(handleDecimals(minimumAmount, 18)),
        userAddress,
      );
    }
  } else {
    // buy LLP tokens
    if (tokenType === config.nativeToken) {
      n = await liquidityRouterContract.addLiquidityETH(
        config.tranches[trancheType],
        // parseEther(parseFloat(Number(amountIn).toFixed(18)).toString()),
        parseEther(handleDecimals(minimumAmount, 18)),
        userAddress,
        { value: parseEther(amountIn.toString()) },
      );
    } else {
      console.log(
        "amountIn-",
        parseUnits(handleDecimals(amountIn, config.decimals[tokenType]), config.decimals[tokenType]),
      );
      n = await liquidityRouterContract.addLiquidity(
        config.tranches[trancheType],
        config.tokens[tokenType],
        parseUnits(handleDecimals(amountIn, config.decimals[tokenType]), config.decimals[tokenType]),
        parseEther(handleDecimals(minimumAmount, 18)),
        userAddress,
      );
    }
  }
  toast.dismiss("waiting");
  // loadingToast(
  //   `Buying ${parseFloat(Number(amountOut).toFixed(5))} ${trancheType === "senior"
  //       ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} ALP for ${parseFloat(
  //         Number(amountIn).toFixed(5))} ${tokenType}.`,
  // );

  await n.wait();
  return n;
};

//to sell tranche amount
export const removeLiquidity = async (
  trancheType,
  tokenType,
  lpAmountIn,
  minimumAmount,
  userAddress,
  amountOut,
  isSwitchOn,
) => {
  minimumAmount = Number(minimumAmount).toFixed(config.decimals[tokenType]).replace(/\.?0+$/, "");
  console.log("userAddress-", userAddress);
  console.log("lpAmountIn-", lpAmountIn);
  console.log("minimumAmount-", minimumAmount);
  console.log("trancheType-", trancheType);
  console.log("tokenType-", tokenType);
  let n;
  if (isSwitchOn) {
    if (tokenType === config.nativeToken) {
      n = await lampMasterContract.removeLiquidityETH(
        trancheType === "senior" ? 0 : trancheType === "mezzanine" ? 1 : 2,
        parseEther(handleDecimals(lpAmountIn, 18)),
        parseUnits(handleDecimals(minimumAmount, config.decimals[tokenType]), config.decimals[tokenType]),
        userAddress,
      );
    } else {
      n = await lampMasterContract.removeLiquidity(
        //withdraw and sell LLP tokens from staking balance
        trancheType === "senior" ? 0 : trancheType === "mezzanine" ? 1 : 2,
        parseEther(handleDecimals(lpAmountIn, 18)),
        config.tokens[tokenType],
        parseUnits(handleDecimals(minimumAmount, config.decimals[tokenType]), config.decimals[tokenType]),
        userAddress,
      );
    }
  } else {
    // sell LLP tokens from wallet balance
    if (tokenType === config.nativeToken) {
      n = await liquidityRouterContract.removeLiquidityETH(
        config.tranches[trancheType],
        parseEther(handleDecimals(lpAmountIn, 18)),
        parseUnits(handleDecimals(minimumAmount, config.decimals[tokenType]), config.decimals[tokenType]),
        userAddress,
      );
    } else {
      n = await liquidityRouterContract.removeLiquidity(
        config.tranches[trancheType],
        config.tokens[tokenType],
        parseEther(handleDecimals(lpAmountIn, 18)),
        parseUnits(handleDecimals(minimumAmount, config.decimals[tokenType]), config.decimals[tokenType]),
        userAddress,
      );
    }
  }
  toast.dismiss("waiting");
  // loadingToast(
  //   `Selling ${parseFloat(Number(lpAmountIn).toFixed(5))} ${
  //     trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"
  //   } ALP for ${parseFloat(Number(amountOut).toFixed(5))} ${tokenType}.`,
  // );

  await n.wait();
  return n;
};

export const depositTranche = async (trancheId, amount, userAddress) => {
  const n = await lampMasterContract.deposit(trancheId, parseEther(handleDecimals(amount, 18)), userAddress);
  // loadingToast(`Depositing ${parseFloat(Number(amount).toFixed(5))} ${trancheId === 0
  //     ? "Senior" : trancheId === 2 ? "Junior" : "Mezzanine"} ALP.`);
  toast.dismiss("waiting");
  await n.wait();
  return n;
};

export const withdrawTranche = async (trancheId, amount, userAddress) => {
  const n = await lampMasterContract.withdraw(trancheId, parseEther(handleDecimals(amount, 18)), userAddress);
  toast.dismiss("waiting");
  // loadingToast(`Withdrawing ${parseFloat(Number(amount).toFixed(5))} ${trancheId === 0
  //     ? "Senior" : trancheId === 2 ? "Junior" : "Mezzanine"} ALP.`);
  await n.wait();
  return n;
};

export const trancheClaim = async (trancheId, userAddress) => {
  console.log("trancheId--", trancheId);
  const n = await lampMasterContract.harvest(trancheId, userAddress);
  toast.dismiss("waiting");
  // loadingToast(`Claiming reward.`);
  await n.wait();
  return n;
};

// AUCTION FUNCTIONS

export const getTokenBalance = async (tokenAddress, userAddress) => {
  const tokenContract = new ethers.Contract(tokenAddress, erc20Abi, provider.getSigner());

  const balance = await tokenContract.balanceOf(userAddress);
  return formatEther(balance.toString());
};

export const getAuctions = async () => {
  const currentTime = parseInt(Date.now() / 1000);
  const signer = provider.getSigner();

  const batchAuctions = await auctionContracts.batchAuctionContract.totalAuctions().then((res) => {
    return res.toNumber();
  });
  const dutchAuctions = await auctionContracts.dutchAuctionContract.totalAuctions().then((res) => {
    return res.toNumber();
  });

  let batchAuctionAddresses = [];
  let dutchAuctionAddresses = [];

  const liveAuction = [];
  const upcomingAuction = [];
  const finishedAuction = [];

  if (batchAuctions) {
    const batchAuctionMultiCall = new Contract(config.auction.batchAuctionContract, batchAuctionFactoryAbi);

    const multicallArr = [];
    for (let i = 0; i < batchAuctions; i++) multicallArr.push(await batchAuctionMultiCall.auctions(i));

    batchAuctionAddresses = await ethcallProvider.all(multicallArr);
  }

  for (let i = 0; i < batchAuctions; i++) {
    let auctionData = {
      address: batchAuctionAddresses[i],
      method: "Batch Auction",
    };

    const batchAuctionContract = new Contract(batchAuctionAddresses[i], batchAuctionAbi);

    const multicallArr = [
      await batchAuctionContract.endTime(),
      await batchAuctionContract.startTime(),
      await batchAuctionContract.auctionToken(),
      await batchAuctionContract.totalTokens(),
      await batchAuctionContract.payToken(),
      await batchAuctionContract.startPrice(),
      await batchAuctionContract.vestingDuration(),
      await batchAuctionContract.tokenPrice(),
      await batchAuctionContract.minimumPrice(),
      await batchAuctionContract.commitmentsTotal(),
    ];

    const resultArr = await ethcallProvider.all(multicallArr);

    auctionData.endTime = resultArr[0].toNumber();
    auctionData.startTime = resultArr[1].toNumber();
    auctionData.auctionAssetAddress = resultArr[2];
    auctionData.totalSupply = formatEther(resultArr[3]);
    auctionData.payTokenAddress = resultArr[4];
    auctionData.startPrice = formatEther(resultArr[5]);
    auctionData.vesting = resultArr[6];
    auctionData.tokenPrice = formatEther(resultArr[6]);
    auctionData.floorPrice = formatEther(resultArr[7]);
    auctionData.totalRaised = formatEther(resultArr[8]);

    const auctionAssetContract = new ethers.Contract(resultArr[2], erc20Abi, signer);
    auctionData.auctionAsset = await auctionAssetContract.symbol();

    const payTokenContract = new ethers.Contract(resultArr[4], erc20Abi, signer);
    auctionData.payToken = await payTokenContract.symbol();

    if (auctionData.endTime > currentTime && auctionData.startTime < currentTime) liveAuction.push(auctionData);

    if (auctionData.endTime > currentTime && auctionData.startTime > currentTime) upcomingAuction.push(auctionData);

    if (auctionData.endTime < currentTime && auctionData.startTime < currentTime) finishedAuction.push(auctionData);
  }

  if (dutchAuctions) {
    const dutchAuctionMultiCall = new Contract(config.auction.duchAuctionContract, dutchAuctionFactoryAbi);

    const multicallArr = [];
    for (let i = 0; i < dutchAuctions; i++) multicallArr.push(await dutchAuctionMultiCall.auctions(i));

    dutchAuctionAddresses = await ethcallProvider.all(multicallArr);
  }

  for (let i = 0; i < dutchAuctions; i++) {
    let auctionData = {
      address: dutchAuctionAddresses[i],
      method: "Dutch Auction",
      vesting: false,
    };

    const dutchAuctionContract = new Contract(dutchAuctionAddresses[i], dutchAuctionAbi);

    const multicallArr = [
      await dutchAuctionContract.endTime(),
      await dutchAuctionContract.startTime(),
      await dutchAuctionContract.auctionToken(),
      await dutchAuctionContract.totalTokens(),
      await dutchAuctionContract.payToken(),
      await dutchAuctionContract.startPrice(),
      await dutchAuctionContract.tokenPrice(),
      await dutchAuctionContract.minimumPrice(),
      await dutchAuctionContract.commitmentsTotal(),
    ];

    const resultArr = await ethcallProvider.all(multicallArr);

    auctionData.endTime = resultArr[0].toNumber();
    auctionData.startTime = resultArr[1].toNumber();
    auctionData.auctionAssetAddress = resultArr[2];
    auctionData.totalSupply = formatEther(resultArr[3]);
    auctionData.payTokenAddress = resultArr[4];
    auctionData.startPrice = formatEther(resultArr[5]);
    auctionData.tokenPrice = formatEther(resultArr[6]);
    auctionData.floorPrice = formatEther(resultArr[7]);
    auctionData.totalRaised = formatEther(resultArr[8]);

    const auctionAssetContract = new ethers.Contract(resultArr[2], erc20Abi, signer);
    auctionData.auctionAsset = await auctionAssetContract.symbol();

    const payTokenContract = new ethers.Contract(resultArr[4], erc20Abi, signer);
    auctionData.payToken = await payTokenContract.symbol();

    if (auctionData.endTime > currentTime && auctionData.startTime < currentTime) liveAuction.push(auctionData);

    if (auctionData.endTime > currentTime && auctionData.startTime > currentTime) upcomingAuction.push(auctionData);

    if (auctionData.endTime < currentTime && auctionData.startTime < currentTime) finishedAuction.push(auctionData);
  }

  return {
    liveAuction: liveAuction,
    upcomingAuction: upcomingAuction,
    finishedAuction: finishedAuction,
  };
};

export const getUserAuctionData = async (auctionAddress, method, account) => {
  const auctionContract = new Contract(auctionAddress, method === "Dutch Auction" ? dutchAuctionAbi : batchAuctionAbi);

  const resultArr = await ethcallProvider.all([
    await auctionContract.commitments(account),
    await auctionContract.claimed(account),
  ]);

  return {
    commitments: formatEther(resultArr[0]),
    claimed: formatEther(resultArr[1]),
  };
};

export const approveTokenSpend = async (tokenAddress, spenderAddress, amount) => {
  const signer = provider.getSigner();
  const tokenContract = new ethers.Contract(tokenAddress, erc20Abi, signer);

  const n = await tokenContract.approve(spenderAddress, amount);
  await n.wait();

  return n;
};

export const commitTokensForAuction = async (auctionAddress, method, userAddress, amount) => {
  const signer = provider.getSigner();
  const auctionContract = new ethers.Contract(
    auctionAddress,
    method === "Dutch Auction" ? dutchAuctionAbi : batchAuctionAbi,
    signer,
  );

  const n = await auctionContract.commitTokens(userAddress, parseEther(amount));
  await n.wait();

  return n;
};

// Earn Functions
export const getEarnData = async (account) => {
  const governanceTokenContract = new Contract(config.tokens.Governance, erc20Abi);
  const platformTokenContract = new Contract(config.tokens.Platform, erc20Abi);
  const governanceStakeContract = new Contract(config.earn.governanceStake, governanceStakeAbi);
  const platformStakeContract = new Contract(config.earn.platformStake, platformStakeAbi);

  const multicallArr = [
    await governanceTokenContract.balanceOf(config.earn.governanceStake),
    await platformTokenContract.balanceOf(config.earn.platformStake),
    await governanceStakeContract.getRewardsPerSecond(),
    await platformStakeContract.rewardsPerSecond(),
    await platformStakeContract.stakingTax(),
  ];

  if (account) {
    multicallArr.push(
      await governanceStakeContract.userInfo(account),
      await platformStakeContract.userInfo(account),
      await governanceStakeContract.pendingRewards(account),
      await platformStakeContract.pendingRewards(account),
      await governanceTokenContract.balanceOf(account),
      await platformTokenContract.balanceOf(account),
      await governanceTokenContract.allowance(account, config.earn.governanceStake),
      await platformTokenContract.allowance(account, config.earn.platformStake),
    );
  }
  const resultArr = await ethcallProvider.all(multicallArr);

  return {
    governance: {
      deposit: formatEther(resultArr[0]),
      reward: formatEther(resultArr[2].mul(86400)),
      userDeposit: formatEther(resultArr[5].amount),
      userReward: formatEther(resultArr[7]),
      userBalance: formatEther(resultArr[9]),
      approvedBalance: formatEther(resultArr[11]),
    },
    platform: {
      deposit: formatEther(resultArr[1]),
      reward: formatEther(resultArr[3].mul(86400)),
      userDeposit: formatEther(resultArr[6].amount),
      userReward: formatEther(resultArr[8]),
      stakingTax: formatEther(resultArr[4]),
      userBalance: formatEther(resultArr[10]),
      approvedBalance: formatEther(resultArr[12]),
    },
  };
};

export const depositedGovernance = async () => {
  const n = await tokenContracts.GovernanceContract.balanceOf(config.earn.governanceStake);
  return formatEther(n);
};

export const depositedPlatform = async () => {
  const n = await tokenContracts.PlatformContract.balanceOf(config.earn.platformStake);
  return formatEther(n);
};

export const approvedGovernance = async (userAddress) => {
  const n = await tokenContracts.GovernanceContract.allowance(userAddress, config.earn.governanceStake);
  return formatEther(n);
};

export const approvedPlatform = async (userAddress) => {
  const n = await tokenContracts.PlatformContract.allowance(userAddress, config.earn.platformStake);
  return formatEther(n);
};

export const governanceApprove = async () => {
  const n = await tokenContracts.GovernanceContract.approve(config.earn.governanceStake, 10);
  loadingToast("Approving Governance.");
  await n.wait();
  return n;
};

export const platformApprove = async () => {
  const n = await tokenContracts.PlatformContract.approve(config.earn.platformStake, 10);
  loadingToast("Approving Platform.");
  await n.wait();
  return n;
};

export const governanceDeposit = async (account, amount) => {
  const n = await earnContracts.governanceStake.stake(account, parseEther(amount.toString()));
  loadingToast(`Depositing ${amount} Governance.`);
  await n.wait();

  return n;
};

export const governanceWithdraw = async (account, amount) => {
  const n = await earnContracts.governanceStake.unstake(account, parseEther(amount.toString()));
  loadingToast(`Withdrawing ${amount} Governance.`);
  await n.wait();
  return n;
};

export const governanceClaim = async (account) => {
  const n = await earnContracts.governanceStake.claimRewards(account);
  loadingToast(`Claiming Governance.`);
  await n.wait();

  return n;
};

export const platformDeposit = async (account, amount) => {
  console.log("amount-", amount);
  const n = await earnContracts.platformStake.stake(account, parseEther(amount.toString()));
  loadingToast(`Depositing ${amount} Platform.`);
  await n.wait();

  return n;
};

export const platformWithdraw = async (account, amount) => {
  const n = await earnContracts.platformStake.unstake(account, parseEther(amount.toString()));
  loadingToast(`Withdrawing ${amount} Platform.`);
  await n.wait();

  return n;
};

export const platformClaim = async (account) => {
  const n = await earnContracts.platformStake.claimRewards(account);
  loadingToast("Claiming Platform.");
  await n.wait();

  return n;
};

//  Dao functions
export const getLvlBalance = async (userAddress) => {
  const n = await auctionContracts.PTcontract.balanceOf(userAddress);
  return ethers.utils.formatEther(n);
};

export const getApprovedLvl = async (userAddress, spenderAddress) => {
  console.log("tokenContracts.LAMPContract-", tokenContracts.PlatformContract);
  const n = await auctionContracts.PTcontract.allowance(userAddress, spenderAddress);
  return ethers.utils.formatEther(n.toString());
};

export const lvlApprove = async (spenderAddress) => {
  const n = await auctionContracts.PTcontract.approve(spenderAddress, 10);
  loadingToast(`Approving Lamp`);
  await n.wait();
  return n;
};

export const getStakedBalance = async (userAddress) => {
  const n = await earnContracts.platformStake.userInfo(userAddress);
  return ethers.utils.formatEther(n.amount.toString());
};

export const getPendingRewards = async (userAddress) => {
  const n = await earnContracts.platformStake.pendingRewards(userAddress);
  return ethers.utils.formatEther(n);
};

export const lvlStake = async (userAddress, amount) => {
  const n = await earnContracts.platformStake.stake(userAddress, parseEther(amount));
  loadingToast(`Staking ${amount} Lamp`);
  await n.wait();

  return n;
};

export const lvlUnstake = async (userAddress, amount) => {
  const n = await earnContracts.platformStake.unstake(userAddress, parseEther(amount));
  loadingToast(`Unstaking ${amount} Lamp`);
  await n.wait();

  return n;
};

export const lvlClaimRewards = async (userAddress) => {
  console.log("userAddress-", userAddress);
  const n = await earnContracts.platformStake.claimRewards(userAddress);
  await n.wait();

  return n;
};

export const getLgoBalance = async (userAddress) => {
  const n = await auctionContracts.GTcontract.balanceOf(userAddress);
  return ethers.utils.formatEther(n);
};

export const getApprovedLgo = async (userAddress, spenderAddress) => {
  const n = await auctionContracts.GTcontract.allowance(userAddress, spenderAddress);
  return ethers.utils.formatEther(n.toString());
};

export const lgoApprove = async (spenderAddress) => {
  const n = await auctionContracts.GTcontract.approve(spenderAddress, 10);
  loadingToast(`Approving LGO`);
  await n.wait();
  return n;
};

export const lvlRedeem = async (userAddress, amount) => {
  const n = await lgoRedemptionPoolContract.redeem(userAddress, parseEther(amount));
  await n.wait();

  return n;
};
