import { Col } from "react-bootstrap";

import PlButton from "../../components/buttons/Button";
import WithTag from "../../components/inputFields/WithTag";
import { PercentageButtons } from "../../components/buttons/Percentage";
import { useState } from "react";
import { lvlClaimRewards, lvlStake, lvlUnstake } from "../../io/kava";
import { waitingToast } from "../../components/toasts/Waiting";
import { successToast } from "../../components/toasts/Success";
import { handleError } from "../../components/toasts/Error";
import { useCommonStateContext } from "../../hooks/commonStateContext";
import CompleteTransaction from "../../components/modals/CompleteTransaction";

const DaoCardDetail = ({ balances, handleLvlApprove, approvedBalance, account }) => {
  const { setLoading, setExplorerURL, setShowConfirmModal, showConfirmModal, handleCloseSuccessModal } =
    useCommonStateContext();
  const [stakeAmount, setStakeAmount] = useState("");
  const [unstakeAmount, setUnstakeAmount] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleStake = async () => {
    setLoading(true);
    try {
      waitingToast(`Staking ${stakeAmount} Lamp`);

      const res = await lvlStake(account, stakeAmount);

      successToast(`${stakeAmount} Lamp Staked!`);

      //This URL needs to be updated according to the Kava Explorer.
      setExplorerURL(`https://goerli.etherscan.io/tx/${res.hash}`);
      setSuccessMessage(`${stakeAmount} Lamp Staked!`);
      setShowConfirmModal(true);
    } catch (e) {
      handleError(e);
      setLoading(false);
    }
  };

  const handleUnstake = async () => {
    setLoading(true);
    try {
      waitingToast(`Unstaking ${unstakeAmount} Lamp`);

      const res = await lvlUnstake(account, unstakeAmount);

      successToast(`${unstakeAmount} Lamp Unstaked!`);

      //This URL needs to be updated according to the Kava Explorer.
      setExplorerURL(`https://goerli.etherscan.io/tx/${res.hash}`);
      setSuccessMessage(`${unstakeAmount} Lamp Unstaked!`);
      setShowConfirmModal(true);
    } catch (e) {
      handleError(e);
      setLoading(false);
    }
  };

  const handleClaim = async () => {
    const res = await lvlClaimRewards(account);
  };

  return (
    <>
      <Col className="mt-2">
        <div className="shadowed-box card-main-content">
          <div className="card-title mb-3">
            <h3>STAKE LAMP</h3>
          </div>
          <div className="mt-4">
            <WithTag
              label={"Amount"}
              placeholder={"0.0"}
              tag={
                <div className="d-flex align-items-center gap-1">
                  <div className="lamp_tag eth_lamp"></div>
                  LAMP
                </div>
              }
              inputValue={stakeAmount}
              onChange={(e) => setStakeAmount(e.target.value)}
            />
            <div className="d-flex justify-content-between align-items-center mt-2">
              <span className="balance">Bal: {parseFloat(balances.toStake.toFixed(3))} LAMP</span>
              <PercentageButtons setAmountIn={setStakeAmount} balance={balances.toStake} />
            </div>
          </div>
          <p className="earn_details_p d-flex justify-content-between align-items-center mt-3">
            Staking Fee <span className="fw-600">0.5%</span>
          </p>
          <div className="modal-confirm-btn mt-4">
            <PlButton
              disabled={Number(stakeAmount) === 0 || balances.toStake < Number(stakeAmount)}
              onClick={approvedBalance.lvl < Number(stakeAmount) ? handleLvlApprove : handleStake}
              label={
                Number(stakeAmount) === 0
                  ? "Enter an amount"
                  : balances.toStake < Number(stakeAmount)
                  ? "insufficient funds"
                  : approvedBalance.lvl < Number(stakeAmount)
                  ? "approve"
                  : "STAKE"
              }
            />
          </div>
        </div>
      </Col>
      <Col className="mt-2">
        <div className="shadowed-box card-main-content">
          <div className="card-title mb-3">
            <h3>UNSTAKE LAMP</h3>
          </div>
          <div className="mt-4">
            <WithTag
              label={"Amount"}
              placeholder={"0.0"}
              tag={
                <div className="d-flex align-items-center gap-1">
                  <div className="lamp_tag eth_lamp"></div>
                  LAMP
                </div>
              }
              inputValue={unstakeAmount}
              onChange={(e) => setUnstakeAmount(e.target.value)}
            />
            <div className="d-flex justify-content-between align-items-center mt-2">
              <span className="balance">Staked : {parseFloat(balances.staked.toFixed(3))} LAMP</span>
              <PercentageButtons setAmountIn={setUnstakeAmount} balance={balances.staked} />
            </div>
          </div>

          <div className="modal-confirm-btn mt-4">
            <PlButton
              disabled={Number(unstakeAmount) === 0 || balances.staked < Number(unstakeAmount)}
              onClick={handleUnstake}
              label={
                Number(unstakeAmount) === 0
                  ? "Enter an amount"
                  : balances.staked < Number(unstakeAmount)
                  ? "insufficient funds"
                  : "UNSTAKE"
              }
            />
          </div>
        </div>
      </Col>
      <Col className="mt-2">
        <div className="shadowed-box card-main-content">
          <div className="card-title mb-3">
            <h3>CLAIM LAMP</h3>
          </div>
          <div className="mt-4">
            <WithTag
              label={"Amount"}
              placeholder={"0.0"}
              tag={
                <div className="d-flex align-items-center gap-1">
                  <div className="lamp_tag eth_lamp"></div>
                  LAMP
                </div>
              }
              inputValue={balances.claimable}
            />
          </div>

          <div className="modal-confirm-btn mt-4">
            <PlButton disabled={balances.claimable === 0} onClick={handleClaim} label={"CLAIM"} />
          </div>
        </div>
      </Col>

      {showConfirmModal && (
        <CompleteTransaction show={showConfirmModal} onHide={handleCloseSuccessModal} successMessage={successMessage} />
      )}
    </>
  );
};

export default DaoCardDetail;
