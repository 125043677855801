// Will mostly have only UI component imports and placement in this file.

import TradeDetail from "../../../components/TradeDetail";

const Trading = ({}) => {
  return (
    <>
      {/*<div className="trading-section">*/}
      <p className="title">Trading</p>
      <TradeDetail />
      {/*</div>*/}
    </>
  );
};

export default Trading;
