// all the business logic will go here in container
// the page will be passed with all the props needed inside

import Page from "./Page";

import { ReactComponent as TreasuryReserve } from "../../assets/images/treasuryReserve.svg";
import { ReactComponent as AccruedFee } from "../../assets/images/accruedfee.svg";
import { ReactComponent as TotalTrade } from "../../assets/images/totaltrading.svg";
import { ReactComponent as Referral } from "../../assets/images/referralPoint.svg";
import Loader from "../../components/loader/Loader";
import { useCommonStateContext } from "../../hooks/commonStateContext";
import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";

const metricsData = [
  { title: "Total Trading Volume", value: "$0", img: <TotalTrade /> },
  { title: "Total Net Profit", value: "$0", img: <TreasuryReserve /> },
  { title: "Total Fee Paid", value: "$0", img: <AccruedFee /> },
  { title: "Referral Point", value: "$0", img: <Referral /> },
];

const loyaltyData = [
  { title: "Your Balance", value: "0 lyLAMP" },
  { title: "lyLAMP Supply", value: "164,287,201.9" },
  { title: "Est. Claimable Rewards", value: "0 LAMP" },
  { title: "Total LAMP Allocation", value: "68,124,012 LAMP" },
];

const rewardHistoryData = {
  columnTitle: [
    { title: "Batch Ended", className: "", md: 3 },
    { title: "You Earned", className: "" },
    { title: "Your Rewards", className: "" },
    { title: "Claimed Rewards", className: "" },
    { title: "Claimable Rewards", className: "" },
  ],
  coulumnData: [
    {
      time: "Mon, Apr 10 2023, 9:00 PM IST",
      earned: "0 lyLAMP",
      rewards: "0 LAMP",
      claimed: "0 LAMP",
      claimable: "0 LAMP",
    },
    {
      time: "Mon, Apr 10 2023, 9:00 PM IST",
      earned: "0 lyLAMP",
      rewards: "0 LAMP",
      claimed: "0 LAMP",
      claimable: "0 LAMP",
    },
    {
      time: "Mon, Apr 10 2023, 9:00 PM IST",
      earned: "0 lyLAMP",
      rewards: "0 LAMP",
      claimed: "0 LAMP",
      claimable: "0 LAMP",
    },
    {
      time: "Mon, Apr 10 2023, 9:00 PM IST",
      earned: "0 lyLAMP",
      rewards: "0 LAMP",
      claimed: "0 LAMP",
      claimable: "0 LAMP",
    },
    {
      time: "Mon, Apr 10 2023, 9:00 PM IST",
      earned: "0 lyLAMP",
      rewards: "0 LAMP",
      claimed: "0 LAMP",
      claimable: "0 LAMP",
    },
    {
      time: "Mon, Apr 10 2023, 9:00 PM IST",
      earned: "0 lyLAMP",
      rewards: "0 LAMP",
      claimed: "0 LAMP",
      claimable: "0 LAMP",
    },
  ],
};

const Container = () => {
  const { loading } = useCommonStateContext();
  const { account, chainId } = useMetaMask();
  // for overview tab
  const [metricsDetails, setMetricsDetails] = useState();

  //for loyalty tab
  const [loyaltyDetails, setLoyaltyDetails] = useState();
  const [rewardHistory, setRewardHistory] = useState();
  const [nextDistributionTime, setNextDistributionTime] = useState(); // time for Next Rewards Distribution

  //for referral tab and modals
  const [allocationHistory, setAllocationHistory] = useState();
  const [redeemLampDetails, setRedeemLampDetails] = useState();
  const [referralLink, setReferralLink] = useState();
  const [traderDetails, setTraderDetails] = useState();
  const [referralAddress, setReferralAddress] = useState();

  useEffect(() => {
    fetchAllDetails();
  }, []);

  const fetchAllDetails = () => {};

  const getReferralLink = () => {};

  const handleAddReferralAddress = () => {};

  return (
    <div className="swap container">
      <Loader loading={loading} />
      <Page metricsData={metricsData} loyaltyData={loyaltyData} rewardHistoryData={rewardHistoryData} account={account} />
    </div>
  );
};

export default Container;
