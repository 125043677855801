// all the business logic will go here in container
// the page will be passed with all the props needed inside

import Page from "./Page";
import { leaderBoardData } from "../../io/subgraph";
import { useEffect, useState } from "react";
import { useCommonStateContext } from "../../hooks/commonStateContext";
import Loader from "../../components/loader/Loader";

const tradeData = {
  columnTitle: [
    { title: "S.No.", className: "ps-0", md: 1 },
    { title: "Wallet", className: "", md: 7 },
    { title: "Trading Volume", className: "", md: 2 },
    { title: "Net Profit", className: "", md: 2 },
  ],
};

const Container = () => {
  const { loading, setLoading } = useCommonStateContext();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetchApiData();
  }, []);

  const fetchApiData = async () => {
    setLoading(true);
    const boardData = await leaderBoardData("volume", "desc", "1");
    setTableData(boardData);
    // console.log("res--", res);
    setLoading(false);
  };

  return (
    <div className="swap container">
      <Loader loading={loading} />
      <Page tradeData={tradeData} tableData={tableData} />
    </div>
  );
};

export default Container;
