// Will mostly have only UI component imports and placement in this file.

import { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import DepositWithdraw from "../modals/DepositWithdraw";
import {
  depositTranche,
  getUserData,
  poolApprove,
  withdrawTranche,
} from "../../../io/kava";
import { waitingToast } from "../../../components/toasts/Waiting";
import { handleError } from "../../../components/toasts/Error";
import { useCommonStateContext } from "../../../hooks/commonStateContext";
import { successToast } from "../../../components/toasts/Success";
import configs from "../../../config.json";
import { useMetaMask } from "metamask-react";

const Metrics = ({ trancheMetricsData, trancheConditions, trancheType }) => {
  const [modalOpen, setModalOpen] = useState(false); // to manage deposit.withdraw modal
  const [modalDetails, setModalDetails] = useState({ title: "", balance: "", label: "", approvedBalance: 0 });
  const [modalAmount, setModalAmount] = useState("");
  const { setLoading, setUserData } = useCommonStateContext();
  const { account } = useMetaMask();

  const handleOpenModal = (item) => {

    setModalDetails({
      title: item.tag,
      balance: item.valueBalance,
      label: item.label,
      approvedBalance: trancheConditions?.approvedDepositAmount,
    });
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalAmount("");
    setModalDetails({ title: "", balance: "", label: "", approvedBalance: 0 });
    setModalOpen(false);
  };

  const handleApprove = async () => {
    setLoading(true);
    try {
      waitingToast(`Approving ${trancheType.toUpperCase()} LLP`);

      await poolApprove(trancheType, configs.trade.lampMaster, modalAmount);

      successToast(`${trancheType.toUpperCase()} LLP Approved!`);

    } catch (e) {
      handleError(e);
    }

    const userDetails = await getUserData(account);
    setUserData(userDetails);

    setModalDetails({
      ...modalDetails,
      approvedBalance: userDetails.approvedTrancheLampMaster[trancheType],
    });

    setLoading(false);
  };

  const handleDeposit = async () => {
    setLoading(true);
    try {
      waitingToast(
        `Depositing ${Number(modalAmount) < 0.00001 ? "<0.00001" : parseFloat(Number(modalAmount).toFixed(5))} ${trancheType.toUpperCase()} LLP.`,
      );
      const res = await depositTranche(
        trancheType === "senior" ? 0 : trancheType === "junior" ? 2 : 1,
        modalAmount,
        account,
      );

      successToast(
        `${Number(modalAmount) < 0.00001 ? "<0.00001" : parseFloat(Number(modalAmount).toFixed(5))} ${trancheType.toUpperCase()} LLP deposited.`,
      );
    } catch (e) {
      handleError(e);
    }

    const userDetails = await getUserData(account);
    setUserData(userDetails);
    setLoading(false);
  };

  const handleWithdraw = async () => {
    setLoading(true);
    try {
      waitingToast(
        `Withdrawing ${Number(modalAmount) < 0.00001 ? "<0.00001" : parseFloat(Number(modalAmount).toFixed(5))} ${trancheType.toUpperCase()} LLP.`,
      );
      const res = await withdrawTranche(
        trancheType === "senior" ? 0 : trancheType === "junior" ? 2 : 1,
        modalAmount,
        account,
      );

      successToast(
        `${Number(modalAmount) < 0.00001 ? "<0.00001" : parseFloat(Number(modalAmount).toFixed(5))} ${trancheType.toUpperCase()} LLP withdrawn.`,
      );
    } catch (e) {
      handleError(e);
    }

    const userDetails = await getUserData(account);
    setUserData(userDetails);

    setLoading(false);
  };
  // console.log("trancheMetricsData-", trancheMetricsData);
  return (
    <>
      <Row xs={1} md={4} className="cards-deatils liquidity-details-cards">
        {trancheMetricsData.map((item, id) => (
          <Col key={id}>
            <Card className="shadowed-box p-0 pools_card">
              <Card.Body>
                <div>
                  {item.title}
                  <span className={item?.className}>
                    {item.value ? item.value : item.valueBalance !== undefined
                      ? Number(item.valueBalance) < 0.01 && Number(item.valueBalance) > 0
                        ? "<0.01"
                        : parseFloat(Number(item.valueBalance).toFixed(2)).toLocaleString() : "-"} {item?.label}
                  </span>
                  {item.desc &&
                  <p className="card_desc_span">{item.desc}</p>
                  }
                  {item.tag && (
                    <p onClick={() => handleOpenModal(item)} className="card_desc_tag">
                      {item.tag}
                    </p>
                  )}
                </div>
                {item.img}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {modalOpen && (
        <DepositWithdraw
          modalAmount={modalAmount}
          setModalAmount={setModalAmount}
          modalDetails={modalDetails}
          show={modalOpen}
          onHide={handleCloseModal}
          handleDeposit={handleDeposit}
          trancheConditions={trancheConditions}
          handleWithdraw={handleWithdraw}
          handleApprove={handleApprove}
        />
      )}
    </>
  );
};

export default Metrics;
