import BigNumber from "bignumber.js";

export const handleDecimals = (amount, decimalPoint) => {
  const v = amount.toString().split(".");
  let f = v[1] || "";
  if (decimalPoint <= 0 || f.length <= 0) return v[0];
  if (f.length > decimalPoint) return `${v[0]}.${f.substr(0, decimalPoint)}`;
  return `${v[0]}.${f}`;
  //return amount.toString().split(".")[1]?.length > decimalPoint
  //  ? Number(amount)
  // .toFixed(decimalPoint - 1)
  //      .toFixed(decimalPoint)
  //      .toString()
  //  : amount.toString();
};

export const compareBigNums = (amount1, amount2) => {
  const bigNumber1 = new BigNumber(amount1);
  const bigNumber2 = new BigNumber(amount2);

  const comparisonResult = bigNumber1.comparedTo(bigNumber2);

  return comparisonResult === 1;
};
