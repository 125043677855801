import Modal from "react-bootstrap/Modal";

import closeIcon from "../../../assets/images/modal-close-icon.svg";

import PlButton from "../../buttons/Button";
import WithTag from "../../inputFields/WithTag";
import { tokenImages } from "../../static/SupportedTokens";
import { useMetaMask } from "metamask-react";
import config from "../../../config.json";

const CancelOrder = ({ show, onHide, handleCancelOrder, cancelOrder }) => {
  const { account, chainId } = useMetaMask();
  console.log("cancelOrder-", cancelOrder);
  return (
    <Modal size="lg" centered dialogClassName="claim-modal" show={show}>
      <Modal.Body>
        <div className="close-icon" onClick={onHide}>
          <img src={closeIcon} />
        </div>

        <div className="modal-title mb-3">
          <h3>
            CANCEL {cancelOrder.collateralAsset} {cancelOrder.side === 0 ? "LONG" : "SHORT"} ORDER
          </h3>
        </div>
        <div className="mt-4">
          <div className="input-wrapper px-4 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-1 input_modal_tag">
              <img src={tokenImages[cancelOrder.indexAsset].image} height={20} width={20} />{" "}
              {/*<img className="trade_head_img" height={20} width={20}
                 src={tokenImages.USD.image} /> */}
              {cancelOrder.indexAsset}/USD
              <WithTag
                isInput={false}
                tag={cancelOrder.side === 0 ? "long" : "short"}
                className={cancelOrder.side === 0 ? "long" : "short"}
              />
            </div>
            <span className="order_type_tag">{cancelOrder.updateType}</span>
          </div>
        </div>
        <div className="mt-3 d-flex justify-content-between">
          <span>Size</span>
          <span className="fw-600">${parseFloat(Number(cancelOrder.sizeChangeValue).toFixed(3))}</span>
        </div>
        <div className="mt-3 d-flex justify-content-between">
          <span>Pay Asset</span>
          <span className="d-flex align-items-center gap-1 fw-600">
            <img src={tokenImages[cancelOrder.payAsset].image} height={14} width={14} /> {cancelOrder.payAsset}
          </span>
        </div>
        <div className="mt-3 d-flex justify-content-between">
          <span>Collateral Asset</span>
          <span className="d-flex align-items-center gap-1 fw-600">
            <img src={tokenImages[cancelOrder.collateralAsset].image} height={14} width={14} />{" "}
            {cancelOrder.collateralAsset}
          </span>
        </div>

        <div className="mt-3 d-flex justify-content-between">
          <span>Trigger Condition</span>
          <span className="fw-600">
            Market Price {cancelOrder.triggerAboveThreshold ? "≥" : "≤"}{" "}
            ${parseFloat(Number(cancelOrder.priceTrigger).toFixed(3))}
          </span>
        </div>
        <div className="mt-3 d-flex justify-content-between">
          <span>Market Price</span>
          <span className="fw-600">${parseFloat(Number(cancelOrder.marketPrice).toFixed(3))}</span>
        </div>
        {/*<div className="mt-3 d-flex justify-content-between">*/}
        {/*  <span>Receive</span>*/}
        {/*  <span className="fw-600">5 USDT  <span className="sub-value">~ $10.05</span></span>*/}

        {/*</div>*/}
        <div className="modal-confirm-btn mt-4">
          <PlButton
            disabled={account === null || chainId !== config.CHAIN_ID}
            label={"confirm"}
            onClick={() => {
              handleCancelOrder(cancelOrder);
              onHide();
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CancelOrder;
