// Will mostly have only UI component imports and placement in this file.

import { Col, Row, Tab, Tabs } from "react-bootstrap";

import icon from "../../assets/images/auctionIcon.svg";

import Auction from "./cards/Auction";

const Page = ({ auctions }) => {
  return (
    <>
      <Row>
        <h1 className="page_title d-flex align-items-center justify-content-start">
          <img src={icon} alt="" /> Auction
        </h1>
        <Tabs
          defaultActiveKey="live"
          id="uncontrolled-tab-example"
          className="mb-3 mt-2 swap-details-tabs auction_page_tabs"
        >
          <Tab eventKey="live" title="LIVE">
            <Row md={3} className="gx-5 justify-content-start mt-4">
              {auctions?.liveAuction.map((auction, i) => (
                <Col className="mt-0 mb-5" key={i}>
                  <Auction data={auction} type={"Live"} />
                </Col>
              ))}
            </Row>
            {auctions?.liveAuction?.length === 0 && <p className="no-data">No Active Auctions</p>}
          </Tab>
          <Tab eventKey="upcoming" title="UPCOMING">
            <Row md={3} className="gx-5 justify-content-start mt-4">
              {auctions?.upcomingAuction.map((auction, i) => (
                <Col className="mt-0 mb-5" key={i}>
                  <Auction data={auction} type={"Upcomming"} />
                </Col>
              ))}
            </Row>
            {auctions?.upcomingAuction?.length === 0 && <p className="no-data">No Upcomming Auctions</p>}
          </Tab>
          <Tab eventKey="finished" title="FINISHED">
            <Row md={3} className="gx-5 justify-content-start mt-4">
              {auctions?.finishedAuction.map((auction, i) => (
                <Col className="mt-0 mb-5" key={i}>
                  <Auction data={auction} type={"Finished"} />
                </Col>
              ))}
            </Row>
            {auctions?.finishedAuction?.length === 0 && <p className="no-data">No Auctions</p>}
          </Tab>
        </Tabs>
      </Row>
    </>
  );
};

export default Page;
