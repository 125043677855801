const CustomPagination = ({ pageCount, currentPage, onPageChange }) => {

  return (
    <div className="pagination">
      <button
        className="previous"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 0}
      >
        &#60;&#60;
      </button>
      <span className="selected">Page {currentPage + 1}</span>
      <button
        className="next"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === pageCount - 1}
      >
        &#62;&#62;
      </button>
    </div>
  );
};

export default CustomPagination;