import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const tradeTokenContext = createContext();

function TradeTokenContextProvider({ children }) {
  const { pathname } = useLocation();
  
  //set token in trade page which is selected from trading pairs in dashboard page
  const [tradeToken, setTradeToken] = useState();

  useEffect(() => {
    setTradeToken();
  }, [pathname]);

  return (
    <tradeTokenContext.Provider
      value={{
        tradeToken,
        setTradeToken,
      }}
    >
      {children}
    </tradeTokenContext.Provider>
  );
}

export default TradeTokenContextProvider;

export const useTradeTokenContext = () => useContext(tradeTokenContext);
