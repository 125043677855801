import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import closeIcon from "../../../assets/images/modal-close-icon.svg";
import arrowIcon from "../../../assets/images/arrow-forward-white.svg";
import warningIcon from "../../../assets/images/warning_icon.svg";

import SelectField from "../../inputFields/SelectField";
import { useEffect } from "react";
import DefaultInput from "../../inputFields/DefaultInput";
import PlButton from "../../buttons/Button";
import WithTag from "../../inputFields/WithTag";
import { PercentageButtons } from "../../buttons/Percentage";
import { SlippageRow } from "../../SlippageRow";
import { posSizeTokens, tokenImages } from "../../static/SupportedTokens";
import Select, { components } from "react-select";
import DropdownArrowBlack from "../../../assets/images/arrow-dropdown-black.svg";
import { compareBigNums } from "../../HandleDecimals";

import { useMetaMask } from "metamask-react";
import config from "../../../config.json";

const CloseConfirmation = ({
                             show,
                             onHide,
                             closeOrder,
                             setCloseOrder,
                             orderType,
                             setOrderType,
                             closingSize,
                             setClosingSize,
                             reduceType,
                             setReduceType,
                             handleCloseOrder,
                           }) => {
  //console.log("closeOrder.limitMarketPrice-", closeOrder.limitMarketPrice);
  const { account, chainId } = useMetaMask();

  const closePositionInfo = [
    {
      title: "Entry Price",
      value: `$${parseFloat(Number(closeOrder.priceTrigger).toFixed(2))}`,
    },
    {
      title: "Market Price",
      value: `$${parseFloat(Number(closeOrder.marketPrice).toFixed(2))}`,
    },
    {
      title: "Liquidation Price",
      oldValue: `$${parseFloat(Math.abs(closeOrder.liquidationPrice).toFixed(2))}`,
      value: `$${parseFloat(Math.abs(closeOrder.newLiquidationPrice).toFixed(2))}`,
      style: {
        color: closeOrder.liquidationPrice < 0 && "red",
      },
    },
    {
      title: "Position Size",
      oldValue: `$${parseFloat(Number(closeOrder.sizeChangeValue).toFixed(2))}`,
      value: `$${parseFloat(Number(closeOrder.newPositionSize).toFixed(2))}`,
    },
    {
      title: "Collateral Value",
      oldValue: `$${parseFloat(Number(closeOrder.collateral).toFixed(2))}`,
      value: `$${parseFloat(Number(closeOrder.newCollateralValue).toFixed(2))}`,
    },
    {
      title: "Net Value",
      oldValue: `$${parseFloat(Number(closeOrder.netValue).toFixed(2))}`,
      value: `$${parseFloat(Number(closeOrder.newNetValue).toFixed(2))}`,
    },
    {
      title: "Leverage",
      oldValue: `${parseFloat(Number(closeOrder.leverage).toFixed(2))}x`,
      value: `${closeOrder.newLeverage <= 0 ? "-" : `${closeOrder.newLeverage < 0.01 ? "<0.01"
        : parseFloat(Number(closeOrder.newLeverage).toFixed(2))}x`}`,
    },
    {
      title: "Minimum PnL",
      value: `${Number(closeOrder.minPnL) < 0 ? "-" : ""}$${
        (Math.abs(Number(closeOrder.minPnL)) < 0.01 && Math.abs(Number(closeOrder.minPnL)) !== 0) ? "<0.01" :
          parseFloat(Math.abs(Number(closeOrder.minPnL)).toFixed(2))}`,
      style: {
        color: Number(closeOrder.minPnL) < 0 ? "#FF0000" : Number(closeOrder.minPnL) > 0 ? "#42FF00" : "#fff",
      },
    },
    {
      title: "Borrow Fee",
      // value: `$${closeOrder.borrowFee.toFixed(2)}`,
      value: closeOrder.borrowFeeValue === 0
        ? "-" : `$${closeOrder.borrowFeeValue < 0.01 ? "<0.01" : closeOrder.borrowFeeValue?.toFixed(2)}`,
    },
    {
      title: "Close Fee",
      value: closeOrder.closeFeeValue === 0 ? "-" : `$${closeOrder.closeFeeValue < 0.01 ? "<0.01" : closeOrder.closeFeeValue?.toFixed(2)}`,
    },
    {
      title: "Execution Fee",
      value: `${closeOrder?.executionFee} KAVA`,
    },
    {
      title: "Minimum Receive",
      value:
        closeOrder?.minPayoutAmount > 0
          // ? `${parseFloat((Number(closeOrder.minPayoutAmount) / Number(closeOrder.marketPrice)).toFixed(5))} ${closeOrder?.indexAsset}`
          ? `${Number(closeOrder.minPayoutAmount) < 0.00001
          ? "<0.00001"
          : parseFloat((Number(closeOrder.minPayoutAmount)).toFixed(5))} ${closeOrder?.collateralAsset}`
          : "-",
    },
  ];

  useEffect(() => {
    //console.log("CANCEL DATA", closeOrder);
  }, [closeOrder]);

  return (
    <Modal size="lg" dialogClassName="lamp-modal" centered show={show}>
      <Modal.Body>
        <div className="close-icon" onClick={onHide}>
          <img src={closeIcon} />
        </div>

        <div className="modal-title">
          <h3>Close Position</h3>
        </div>

        <div className="token-header">
          <div className="position d-flex align-items-center gap-1">
            {/*<span className="me-2">*/}
            <img src={tokenImages[closeOrder.indexAsset].image} height={20} width={20} />
            {/*<img className="trade_head_img" height={22} width={22} src={tokenImages.USD.image} />*/}
            {/*</span>*/}
            <span className="fw-600">{closeOrder.indexAsset}/USD</span>
            <WithTag
              isInput={false}
              tag={closeOrder.side === 0 ? "long" : "short"}
              className={closeOrder.side === 0 ? "long" : "short"}
            />
          </div>
          <p className="collateral">
            Collateral Asset:
            <span>
              <img src={tokenImages[closeOrder.collateralAsset].image} height={20} width={20} />
              {closeOrder?.collateralAsset}
            </span>
          </p>
        </div>

        <div className="form-container">
          <form>
            <div className="d-flex align-items-center gap-2">
              <div className="tag-select order_select_tag d-flex align-items-center justify-content-center gap-3 m-0">
                <Select
                  value={orderType}
                  onChange={(val) => setOrderType(val)}
                  options={[
                    {
                      label: (
                        <div className="dropdown_label d-flex align-items-center fw-normal order_options">Market
                          Order</div>
                      ),
                      value: "marketOrder",
                    },
                    {
                      label: (
                        <div className="dropdown_label d-flex align-items-center fw-normal order_options">Take
                          Profit</div>
                      ),
                      value: "takeProfit",
                    },
                    {
                      label: (
                        <div className="dropdown_label d-flex align-items-center fw-normal order_options">Stop
                          Loss</div>
                      ),
                      value: "stopLoss",
                    },
                  ]}
                  isClearable={false}
                  hideSelectedOptions
                  classNamePrefix="select select-custom"
                  components={{
                    IndicatorSeparator: null,
                    DropdownIndicator: (props) => (
                      <components.DropdownIndicator {...props}>
                        <img src={DropdownArrowBlack} alt="icon" className="icon" />
                      </components.DropdownIndicator>
                    ),
                  }}
                />
              </div>
              <DefaultInput
                placeholder={orderType.value !== "marketOrder" ? "0.0" : closeOrder.marketPrice}
                inputValue={orderType.value !== "marketOrder" ? closeOrder.limitMarketPrice : ""}
                onChange={(e) =>
                  setCloseOrder({
                    ...closeOrder,
                    limitMarketPrice: e.target.value.length > 0 ? e.target.value : closeOrder.marketPrice,
                  })
                }
                inputClass={`${orderType.value === "marketOrder" ? "cursor_not_allowed" : undefined}`}
              />
            </div>
            <div className="d-flex align-items-center justify-content-end mb-4 trigger_div">
              Trigger : Mark
              Price {closeOrder.side === 0
              ? orderType.value === "stopLoss"
                ? "≤" : "≥"
              : orderType.value === "stopLoss"
                ? "≥" : "≤"} {parseFloat(Number(orderType.value !== "marketOrder"
              ? closeOrder.limitMarketPrice
              : closeOrder.side === 0
                ? Number(closeOrder.marketPrice) * (1 - Number(closeOrder.slippage.value) / 100)
                : Number(closeOrder.marketPrice) * (1 + Number(closeOrder.slippage.value) / 100)).toFixed(5))}
            </div>

            <WithTag
              isInput
              label={<span>Closing Size</span>}
              placeholder={"0.0"}
              tag={"USD"}
              inputValue={closingSize}
              onChange={(e) => setClosingSize(e.target.value)}
            />
            <div className="btn-container mt-2 mb-4">
              <p>Max Closing Size: {Number(closeOrder.sizeChangeValue).toFixed(2)} USD</p>
              <PercentageButtons balance={closeOrder.sizeChangeValue} setAmountIn={setClosingSize} />
            </div>
            {orderType.value === "marketOrder" &&
            <SlippageRow allDetails={closeOrder} setAllDetails={setCloseOrder} />
            }
            <PlButton
              className="mb-4 mt-2"
              disabled={
                account === null || chainId !== config.CHAIN_ID ||
                Number(closingSize) === 0 ||
                (Number(closeOrder.newPositionSize) !== 0 && Number(closeOrder?.newCollateralValue) < 5) ||
                (Number(closeOrder.newPositionSize) !== 0 && Number(closeOrder?.newLeverage) < 2) ||
                compareBigNums(closingSize, closeOrder.sizeChangeValue)
              }
              onClick={() => {
                handleCloseOrder();
                onHide();
              }}
              label={
                (account === null || chainId !== config.CHAIN_ID)
                  ? "CLOSE"
                  : Number(closingSize) === 0
                  ? "enter an amount"
                  : Number(closeOrder.newPositionSize) !== 0 && Number(closeOrder?.newCollateralValue) < 5
                    ? "min collateral : $5"
                    : Number(closeOrder.newPositionSize) !== 0 && Number(closeOrder?.newLeverage) < 2
                      ? "min leverage : 2x"
                      : compareBigNums(closingSize, closeOrder.sizeChangeValue)
                        ? "insufficient funds"
                        : "CLOSE"
              }
            />
            <div className="size-input">
              <Form.Check
                type="radio"
                value="reduceCollateral"
                label={`Reduce size and keep leverage at ${parseFloat(Number(closeOrder.leverage).toFixed(2))}x`}
                name="size"
                checked={reduceType === "reduceCollateral"}
                onChange={(e) => setReduceType(e.target.value)}
              />
              <Form.Check
                type="radio"
                value="reduceLeverage"
                label="Reduce size and leverage without withdrawing collateral"
                name="size"
                checked={reduceType === "reduceLeverage"}
                onChange={(e) => setReduceType(e.target.value)}
              />
            </div>
          </form>

          <div className="info-section">
            {closePositionInfo.map((data, i) => (
              <span key={i}>
                <p>{data.title}</p>
                <p style={data.style} className="d-flex align-items-center gap-12">
                  {data?.oldValue && Number(closingSize) > 0 &&
                  <span className="opacity-50 d-flex align-items-center gap-12">{data.oldValue}
                    <img src={arrowIcon} height={10} width={14} /></span>
                  }
                  {data.value}
                </p>
              </span>
            ))}
          </div>
        </div>

        {
          Number(closingSize) < Number(closeOrder.sizeChangeValue) ?
            (closeOrder.side === 0 && Number(closeOrder?.newLiquidationPrice) > Number(closeOrder?.marketPrice)) ||
            (closeOrder.side === 1 && Number(closeOrder?.newLiquidationPrice) < Number(closeOrder?.marketPrice)) &&
            <div className="mt-3 d-flex justify-content-center align-items-center modal_span gap-1 loss">
              <img src={warningIcon} alt="" /><span>Your position will immediately get liquidated.</span>
            </div>
            : null
        }
      </Modal.Body>
    </Modal>
  );
};

export default CloseConfirmation;