import Modal from "react-bootstrap/Modal";

import closeIcon from "../../../assets/images/modal-close-icon.svg";

import PlButton from "../../../components/buttons/Button";
import DefaultInput from "../../../components/inputFields/DefaultInput";

const AddReferral = (props) => {
  const { show, onHide } = props;

  return (
    <Modal {...props} size="lg" centered dialogClassName="claim-modal" show={show}>
      <Modal.Body>
        <div className="close-icon" onClick={onHide}>
          <img src={closeIcon} />
        </div>

        <div className="modal-title mb-3">
          <h3>ADD REFERRAL ADDRESS</h3>
        </div>
        <div className="mt-4">
          <DefaultInput label={"Enter Address"} placeholder={"0.0"} />
        </div>

        <div className="modal-confirm-btn mt-4">
          <PlButton label={"Confirm"} onClick={onHide} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddReferral;
