// Will mostly have only UI component imports and placement in this file.

import { Col, Row } from "react-bootstrap";
import downArrow from "../../../assets/images/arrow_down.svg";

const TraderInfo = ({ tradeData, tableData }) => {
  return (
    <>
      <div className="trading-section">
        <Row className="leaderboard_heading w-100 mx-0">
          {tradeData.columnTitle.map((item, i) => (
            <Col key={i} className={item.className} md={item.md}>
              <p className="d-flex align-items-center gap-2">
                {item.title}{" "}
                {item.title !== "S.No." && item.title !== "Wallet" && <img height={12} width={12} src={downArrow} />}
              </p>
            </Col>
          ))}
        </Row>
        {tableData.map((item, i) => (
          <Row className="data leaderboard_data shadowed-box p-0 w-100 mx-0" key={i}>
            <Col className="d-flex align-items-center ps-0" md={1}>
              <span className="rank_span">{i + 1}</span>
            </Col>
            <Col className="d-flex align-items-center" md={7}>
              <span className="fw-600">{item.account}</span>
            </Col>
            <Col className="d-flex align-items-center" md={2}>
              <span>${item.volume.toFixed(3)}</span>
            </Col>
            <Col className="d-flex align-items-center" md={2}>
              <span className={`${item.netProfit > 0 ? "profit" : item.netProfit < 0 ? "loss" : ""}`}>
                {item.netProfit > 0 ? "+" : item.netProfit < 0 ? "-" : ""}${Math.abs(item.netProfit).toFixed(2)}
              </span>
            </Col>
          </Row>
        ))}
      </div>
    </>
  );
};

export default TraderInfo;
