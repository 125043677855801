import PlButton from "./Button";

export const PercentageButtons = ({ setAmountIn, balance }) => {
  return (
    <div className="d-flex gap-2">
      <PlButton onClick={() => setAmountIn(balance ? (balance * 25) / 100 : "")} type={"percentageButton"}
                label={"25%"} />
      <PlButton onClick={() => setAmountIn(balance ? (balance * 50) / 100 : "")} type={"percentageButton"}
                label={"50%"} />
      <PlButton onClick={() => setAmountIn(balance ? (balance * 75) / 100 : "")} type={"percentageButton"}
                label={"75%"} />
      <PlButton onClick={() => setAmountIn(balance ? balance : "")} type={"percentageButton"} label={"100%"} />
    </div>
  );
};
