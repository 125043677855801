import { toast } from "react-toastify";
import { toastOptions } from "./ToastOptions";

export const switchNetworkToast = (chainID,isSwitch) => {
  toast(<p className='mb-0 network_toast'>{`${isSwitch ? "Connect to" : "Add"} Goerli testnet`}</p>, {
    ...toastOptions,
    toastId: chainID,
    autoClose: false,
    style: { backgroundColor: "#272320", color: "#E6BB00", textAlign: "center" },
  });
};