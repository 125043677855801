import Modal from "react-bootstrap/Modal";
import { useCommonStateContext } from "../../hooks/commonStateContext";

import closeIcon from "../../assets/images/modal-close-icon.svg";
import DownArrow from "../../assets/images/down-circle-arrow.svg";
import arrow from "../../assets/images/arrow-forward-yellow.svg";

import PlButton from "../buttons/Button";
import WithTag from "../inputFields/WithTag";
import { tokenImages } from "../static/SupportedTokens";
import { useMetaMask } from "metamask-react";
import config from "../../config.json";

const Confirm = ({
                   show,
                   onHide,
                   modalDetails,
                   amountIn,
                   amountOut,
                   minimumReceived,
                   title,
                   data,
                   orderType,
                   selectedOrder,
                   setShowModal,
                   trancheType,
                   handleCancelOrder,
                   handleSwap,
                   positionFeeValue,
                   handleBuySell,
                   handleTrade,
                 }) => {
  const { usdValue } = useCommonStateContext();
  const { account, chainId } = useMetaMask();

  let _from = modalDetails?.selectedFrom;
  let _to = modalDetails?.selectedTo;

  return (
    <Modal size="lg" centered dialogClassName="lamp-modal my-modal" animation={true} show={show}>
      <Modal.Body>
        <div className="close-icon" onClick={onHide}>
          <img src={closeIcon} />
        </div>

        <div className="modal-title mb-3">
          {title ? (
            <h3> {title}</h3>
          ) : (
            <h3 className="d-flex align-items-center gap-1">
              Swap {modalDetails.selectedFrom?.value} <img src={arrow} height={9}
                                                           width={16} /> {modalDetails.selectedTo?.value}
            </h3>
          )}
        </div>
        <div className="modal-body-content">
          <div className="mt-4">
            <WithTag
              className="modal_tag"
              inputClass={"cursor_not_allowed"}
              placeholder={"0.0"}
              inputValue={selectedOrder ? selectedOrder.priceIn : amountIn}
              readOnly={true}
              tag={
                trancheType && modalDetails.selectedTrancheTab === "sell" ? (
                  <div className="d-flex align-items-center gap-1">
                    <div className="lamp_tag eth_lamp"></div>
                    {trancheType.toUpperCase()} LLP
                  </div>
                ) : (
                  <div className="d-flex align-items-center gap-1">
                    <img
                      src={
                        trancheType && modalDetails.selectedTrancheTab === "buy"
                          ? tokenImages[modalDetails.selectedToken?.value]?.image
                          : selectedOrder
                          ? tokenImages[selectedOrder.assetIn]?.image
                          : tokenImages[modalDetails.selectedFrom?.value]?.image
                      }
                      height={16}
                      width={16}
                    />{" "}
                    {trancheType && modalDetails.selectedTrancheTab === "buy"
                      ? modalDetails.selectedToken?.value
                      : selectedOrder
                        ? selectedOrder.assetIn
                        : modalDetails.selectedFrom?.value}
                  </div>
                )
              }
              subValue={usdValue?.fromUsdValue}
            />
          </div>
          <div className="my-2 d-flex justify-content-center">
            <img src={DownArrow} />
          </div>
          <div>
            <WithTag
              className="modal_tag"
              inputClass={"cursor_not_allowed"}
              placeholder={"0.0"}
              inputValue={Number(selectedOrder ? selectedOrder.priceOut : amountOut).toFixed(12).replace(/\.?0+$/, "")}
              readOnly={true}
              tag={
                trancheType && modalDetails.selectedTrancheTab === "buy" ? (
                  <div className="d-flex align-items-center gap-1">
                    <div className="lamp_tag eth_lamp"></div>
                    {trancheType.toUpperCase()} LLP
                  </div>
                ) : (
                  <div className="d-flex align-items-center gap-1">
                    <img
                      src={
                        trancheType && modalDetails.selectedTrancheTab === "sell"
                          ? tokenImages[modalDetails.selectedToken?.value]?.image
                          : selectedOrder
                          ? tokenImages[selectedOrder.assetOut]?.image
                          : tokenImages[modalDetails.selectedTo?.value]?.image
                      }
                      height={16}
                      width={16}
                    />{" "}
                    {trancheType && modalDetails.selectedTrancheTab === "sell"
                      ? modalDetails.selectedToken?.value
                      : selectedOrder
                        ? selectedOrder.assetOut
                        : modalDetails.selectedTo?.value}
                  </div>
                )
              }
              subValue={usdValue?.toUsdValue}
            />
          </div>
          <div className="mt-3 d-flex justify-content-between">
            <span>{trancheType ? "Fee" : data ? "Position Fee" : selectedOrder ? "Trigger Condition" : "Price"}</span>
            <span className="fw-600">
              {trancheType
                ? `${(Number(modalDetails.trancheFee) > 0 && Number(modalDetails.trancheFee) < 0.01)
                  ? "<0.01" : Number(modalDetails.trancheFee).toFixed(2)}%`
                : data
                  ? `$${(positionFeeValue > 0 && positionFeeValue < 0.01) ? "<0.01" : parseFloat(positionFeeValue.toFixed(2))}`
                  : selectedOrder
                    ? `1 ${selectedOrder?.assetIn} ≥ ${Number(selectedOrder.priceTriggered).toFixed(7).replace(/\.?0+$/, "")} ${
                      selectedOrder?.assetOut
                    }`
                    : `1 ${modalDetails.selectedFrom?.value} = ${
                      modalDetails.selectedSwapTab === "market"
                        ? Number(modalDetails.price).toFixed(7).replace(/\.?0+$/, "")
                        : Number(modalDetails.swapLimitPrice).toFixed(7).replace(/\.?0+$/, "")
                    } ${modalDetails.selectedTo?.value}`}{" "}
            </span>
          </div>
          <div className="mt-1 d-flex justify-content-between">
            <span>
              {trancheType ? "Slippage" : data ? "Execution Fee" : selectedOrder ? "Market Price" : "Minimum Received"}
            </span>
            <span className="fw-600">
              {data
                ? `${modalDetails.txExecutionFee} KAVA`
                : trancheType
                  ? modalDetails.slippage.label
                  : selectedOrder
                    ? `1 ${selectedOrder?.assetIn} = ${Number(selectedOrder.marketPrice).toFixed(7).replace(/\.?0+$/, "")} ${
                      selectedOrder?.assetOut
                    }`
                    : minimumReceived > 0
                      ? `${minimumReceived < 0.00001 ? "<0.00001" : parseFloat(minimumReceived?.toFixed(5))} ${modalDetails.selectedTo?.value}`
                      : "-"}
            </span>
          </div>
          <div className={`mt-1 ${selectedOrder ? "d-none" : "d-flex"} justify-content-between`}>
            <span>{trancheType ? "Minimum Received" : data ? "Trigger Condition" : "Swap Fee"}</span>
            <span className="fw-600" style={data && { color: "#FFD000" }}>
              {trancheType
                ? (minimumReceived > 0 && minimumReceived < 0.00001) ? "<0.00001" : parseFloat(minimumReceived?.toFixed(5))
                : data
                  ? `Mark Price ${modalDetails.selectedTradeTab === "long" ? "≤" : "≥"} 
                        $${
                    orderType.value === "marketOrder"
                      ? modalDetails.selectedTradeTab === "long"
                      ? parseFloat((Number(modalDetails.entryPrice) * (1 + Number(modalDetails.slippage.value) / 100))?.toFixed(3),
                      )
                      : parseFloat((Number(modalDetails.entryPrice) * (1 - Number(modalDetails.slippage.value) / 100))?.toFixed(3))
                      : parseFloat(Number(modalDetails.newEntryPrice)?.toFixed(3))
                  }`
                  : `${(Number(modalDetails?.swapFee) > 0 && Number(modalDetails?.swapFee) < 0.01)
                    ? "<0.01" : Number(modalDetails?.swapFee).toFixed(2)}%`}
            </span>
          </div>
        </div>
        <div className="modal-confirm-btn mt-4">
          <PlButton
            disabled={account === null || chainId !== config.CHAIN_ID}
            label={"Confirm"}
            onClick={() => {
              data
                ? handleTrade()
                : trancheType
                ? handleBuySell()
                : selectedOrder
                  ? handleCancelOrder(selectedOrder)
                  : handleSwap();
              selectedOrder ? setShowModal(false) : onHide();
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Confirm;
